import { classNames } from "@opensea/ui-kit"
import React, { forwardRef } from "react"
import { entries } from "../../lib/helpers/object"
import loaderClassNames from "./Loader.module.css"

export const sizes = {
  xsmall: 18,
  small: 24,
  medium: 32,
  large: 64,
} as const

export const getClosestLoaderSize = (size: number) =>
  entries(sizes).reduce<keyof typeof sizes>((closestSize, [curSize, value]) => {
    const difference = Math.abs(value - size)
    if (difference < Math.abs(sizes[closestSize] - size)) {
      return curSize
    }
    return closestSize
  }, "large")

export type LoaderProps = Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
  size?: keyof typeof sizes
}

// Reference taken from https://dribbble.com/shots/1918018-Circle-Loading-Animation as well as
// the styling implementation
export const Loader = forwardRef<SVGSVGElement, LoaderProps>(function Loader(
  { size = "medium", ...rest },
  ref,
) {
  return (
    <svg
      ref={ref}
      style={{ width: sizes[size], height: sizes[size] }}
      viewBox="0 0 120 120"
      {...rest}
      className={classNames(loaderClassNames.loader, rest.className)}
    >
      <circle className={loaderClassNames.innerCircle} cx="60" cy="60" r="30" />
      <circle className={loaderClassNames.outerCircle} cx="60" cy="60" r="50" />
    </svg>
  )
})
