// By default allow all origins for sending and receiving messages
const DEFAULT_PARENT_FRAME_TARGET_ORIGIN = "*"

export const PARENT_FRAME_TARGET_ORIGIN =
  process.env.NEXT_PUBLIC_PARENT_FRAME_TARGET_ORIGIN ??
  DEFAULT_PARENT_FRAME_TARGET_ORIGIN

export const DEFAULT_VESSEL_ACTION_TIMEOUT = 5_000
/**
 * Long 1 minute timeout for vessel actions that prompt user to take action
 * in their wallet.
 */
export const WALLET_PROMPT_VESSEL_ACTION_TIMEOUT = 60_000
