import type { MessagePayloadType } from "@opensea/vessel-0.0"
import { useCallback } from "react"
import { useVessel } from "@/providers/Vessel00/VesselProvider.react"
import type { RPCMessageHandlerFn } from "../RPCMessageHandler.react"

export const useSwitchChainHandler = (): RPCMessageHandlerFn => {
  const { emitProviderEvent } = useVessel()

  const handler: RPCMessageHandlerFn = useCallback(
    async (message, reply, wallet) => {
      const newChainId = message.payload.params[0].chainId
      const ethereumProvider = await wallet.getEthereumProvider()

      try {
        await ethereumProvider.request(message.payload)
        emitProviderEvent({ event: "chainChanged", data: newChainId })
        reply(undefined)
      } catch (error) {
        reply({ error } as MessagePayloadType)
      }
    },
    [emitProviderEvent],
  )
  return handler
}
