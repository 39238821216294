import type { ChildFrame, MessagePayloadType } from "@opensea/vessel-0.0"
import type {
  ActionMessagePayload,
  ErrorPayload,
  EventMessagePayload,
  ProviderEventMessagePayload,
} from "@opensea/wallet-messages"
import { captureException } from "@sentry/nextjs"
import React, { createContext, useCallback, useContext } from "react"
import { noop } from "@/lib/helpers/noop"
import type { MessageHandler } from "./messages/types"
import { useInitializeVessel } from "./useInitializeVessel"
import { useVesselLogger } from "./useVesselLogger"

type VesselContextType = {
  isConnected: boolean
  vesselRef: React.MutableRefObject<ChildFrame | null>
  triggerAction: (
    data: ActionMessagePayload,
    timeout?: number,
  ) => Promise<MessagePayloadType>
  emitError: (message: string) => void
  emitEvent: (data: EventMessagePayload) => void
  emitProviderEvent: (
    message: Omit<ProviderEventMessagePayload, "type">,
  ) => void
  addMessageHandler: (handler: MessageHandler) => () => void
}

const DEFAULT_VESSEL_CONTEXT: VesselContextType = {
  isConnected: false,
  vesselRef: { current: null },
  triggerAction: () => Promise.resolve({}),
  emitError: noop,
  emitEvent: noop,
  emitProviderEvent: noop,
  addMessageHandler: () => noop,
}

const VesselContext = createContext<VesselContextType>(DEFAULT_VESSEL_CONTEXT)

type VesselProviderProps = {
  children: React.ReactNode
}

export function VesselProvider({ children }: VesselProviderProps) {
  const vesselLog = useVesselLogger()

  const { vesselRef, addMessageHandler, isConnected } = useInitializeVessel()

  const triggerAction = useCallback(
    async (data: ActionMessagePayload, timeout?: number) => {
      if (!vesselRef.current) {
        throw new Error("Failed to trigger action. Vessel not initialized.")
      }

      vesselLog("Triggering action", data)
      return vesselRef.current.send(data, timeout)
    },
    [vesselLog, vesselRef],
  )

  const emitError = useCallback(
    (message: string) => {
      if (!vesselRef.current) {
        // Capture exception and fail silently
        captureException(
          new Error("Failed to emit error. Vessel not initialized."),
        )
        return
      }

      const data: ErrorPayload = {
        type: "event",
        event: "Error",
        message,
      }

      vesselLog("Emitting error", data)
      vesselRef.current.emit(data)
    },
    [vesselLog, vesselRef],
  )

  const emitEvent = useCallback(
    (data: EventMessagePayload) => {
      if (!vesselRef.current) {
        throw new Error("Failed to emit event. Vessel not initialized.")
      }

      vesselLog("Emitted event", data)
      vesselRef.current.emit(data)
    },
    [vesselLog, vesselRef],
  )

  const emitProviderEvent = useCallback(
    (message: Omit<ProviderEventMessagePayload, "type">) => {
      if (!vesselRef.current) {
        throw new Error(
          "Failed to emit provider event. Vessel not initialized.",
        )
      }

      const event: ProviderEventMessagePayload = {
        type: "event",
        event: message.event,
        data: message.data,
      }

      vesselLog("Emitting event", event)
      vesselRef.current.emit<ProviderEventMessagePayload>(event)
    },
    [vesselLog, vesselRef],
  )

  return (
    <VesselContext.Provider
      value={{
        vesselRef,
        isConnected,
        triggerAction,
        emitError,
        emitEvent,
        emitProviderEvent,
        addMessageHandler,
      }}
    >
      {children}
    </VesselContext.Provider>
  )
}

export const useVessel = () => useContext(VesselContext)
