import type { MfaMethod } from "@privy-io/react-auth"
import { useTrackingFn } from "@/lib/analytics/useTrackingFn"

export const useMfaEnrollmentTracking = () => {
  return {
    trackInitMfaEnrollment: useTrackingFn<{
      method: MfaMethod
    }>("init mfa enrollment"),
    trackInitMfaEnrollmentError: useTrackingFn<{
      method: MfaMethod
      error: string
    }>("init mfa enrollment error"),
    trackSubmitMfaEnrollment: useTrackingFn<{
      method: MfaMethod
    }>("submit mfa enrollment"),
    trackSubmitMfaEnrollmentError: useTrackingFn<{
      method: MfaMethod
      error: string
      reason: string
    }>("submit mfa enrollment error"),
  }
}
