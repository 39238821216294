import {
  Button as DsButton,
  type ButtonProps,
  classNames,
} from "@opensea/ui-kit"
import React, { forwardRef } from "react"

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button({ children, className, ...rest }: ButtonProps, ref) {
    return (
      <DsButton
        className={classNames("h-12 rounded-xl text-md", className)}
        ref={ref}
        {...rest}
      >
        {children}
      </DsButton>
    )
  },
)
