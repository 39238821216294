import type { BigNumber } from "./numberUtils"
import { bn } from "./numberUtils"

export const GAS_ESTIMATION_BUFFER_PERCENTAGE = 50

export const calculateMaxWithGas = (
  balance: string,
  gasEstimate: string,
): BigNumber => {
  const estimatedGasWithBuffer = bn(gasEstimate)
    .times(100 + GAS_ESTIMATION_BUFFER_PERCENTAGE)
    .div(100)

  return bn(balance).minus(estimatedGasWithBuffer)
}
