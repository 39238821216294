import { useTranslate } from "@opensea/next-translate"
import { Flex, FlexColumn } from "@opensea/ui-kit"
import { Separator } from "@opensea/ui-kit/client"
import React from "react"
import { graphql, useFragment } from "react-relay"
import { CollectionMedia } from "@/components/common/media/CollectionMedia"
import { Text } from "@/design-system/Text"
import { useChains } from "@/hooks/useChains"
import type { ApproveCollectionTransactionDetails_metadata$key } from "@/lib/graphql/__generated__/ApproveCollectionTransactionDetails_metadata.graphql"
import { TransactionDetailsTokenAmount } from "../components/TransactionDetailsTokenAmount.react"
import type { TransactionDetailsProps } from "../TransactionDetails.react"

type Props = Omit<TransactionDetailsProps, "metadata"> & {
  metadata: ApproveCollectionTransactionDetails_metadata$key
}

export function ApproveCollectionTransactionDetails({
  metadata: metadataDataKey,
  gasFee,
  isLoadingGasFee,
  chain,
}: Props) {
  const t = useTranslate("transactions")
  const { getNativeCurrencyUsdPrice, getNativeCurrencySymbol } = useChains()

  const { collections } = useFragment(
    graphql`
      fragment ApproveCollectionTransactionDetails_metadata on TransactionMetadataType {
        collections {
          name
          ...CollectionMedia_collection
        }
      }
    `,
    metadataDataKey,
  )

  const nativeCurrencySymbol = getNativeCurrencySymbol(chain)
  const nativeCurrencyUsdPrice = getNativeCurrencyUsdPrice(chain)

  // Only one collection is approved per transaction
  const collection = collections[0]

  return (
    <FlexColumn className="gap-6 p-4">
      <div>
        <Flex className="items-center gap-4">
          <CollectionMedia collection={collection} size="sm" />
          <Text weight="semibold">{collection.name}</Text>
        </Flex>
        <Separator className="mt-4" />
      </div>

      <TransactionDetailsTokenAmount
        isLoading={isLoadingGasFee}
        label={t("transactionConfirmation.gas", "Gas")}
        quantity={gasFee}
        symbol={nativeCurrencySymbol}
        usdPrice={nativeCurrencyUsdPrice}
      />
    </FlexColumn>
  )
}
