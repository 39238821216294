import React from "react"

type Props = {
  className?: string
  width?: number
}

export function SolanaLogo({ className, width = 20 }: Props) {
  return (
    <svg
      className={className}
      fill="none"
      height="24"
      style={{ width, height: width }}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.8513 16.615L17.898 19.7817C17.8341 19.8505 17.7568 19.9054 17.6707 19.9429C17.5847 19.9805 17.4919 19.9999 17.398 20H3.39807C3.3313 20 3.26599 19.9805 3.21013 19.9439C3.15427 19.9073 3.1103 19.8552 3.08359 19.794C3.05688 19.7328 3.0486 19.6652 3.05976 19.5993C3.07091 19.5335 3.10102 19.4723 3.1464 19.4233L6.09639 16.2567C6.16028 16.1879 6.23763 16.133 6.32366 16.0954C6.40967 16.0579 6.50252 16.0384 6.59639 16.0383H20.5963C20.6637 16.037 20.73 16.0555 20.7868 16.0917C20.8437 16.1279 20.8887 16.1801 20.9158 16.2418C20.9432 16.3034 20.9515 16.3717 20.94 16.4382C20.9285 16.5046 20.8977 16.5661 20.8513 16.615ZM17.898 10.2367C17.8339 10.1682 17.7565 10.1136 17.6705 10.076C17.5845 10.0385 17.4918 10.0189 17.398 10.0184H3.39807C3.3313 10.0184 3.26599 10.0379 3.21013 10.0745C3.15427 10.1111 3.1103 10.1632 3.08359 10.2244C3.05688 10.2856 3.0486 10.3532 3.05976 10.4191C3.07091 10.4849 3.10102 10.5461 3.1464 10.5951L6.09639 13.7634C6.16051 13.8318 6.23793 13.8865 6.32389 13.9241C6.40986 13.9616 6.50259 13.9812 6.59639 13.9817H20.5963C20.663 13.9813 20.728 13.9616 20.7837 13.9249C20.8393 13.8882 20.883 13.8361 20.9095 13.7749C20.936 13.7138 20.9442 13.6463 20.9328 13.5806C20.9217 13.5149 20.8917 13.4539 20.8463 13.405L17.898 10.2367ZM3.39807 7.96171H17.398C17.4919 7.96163 17.5847 7.94218 17.6707 7.90462C17.7568 7.86707 17.8341 7.81217 17.898 7.74338L20.8513 4.57674C20.8977 4.52782 20.9285 4.46629 20.94 4.39987C20.9515 4.33346 20.9432 4.26513 20.9158 4.20349C20.8887 4.14185 20.8437 4.08964 20.7868 4.05345C20.73 4.01725 20.6637 3.99868 20.5963 4.00007H6.59639C6.50252 4.00017 6.40967 4.01961 6.32366 4.05717C6.23763 4.09474 6.16028 4.14962 6.09639 4.21841L3.1464 7.38505C3.10102 7.43403 3.07091 7.4952 3.05976 7.56103C3.0486 7.62687 3.05688 7.69452 3.08359 7.75572C3.1103 7.81692 3.15427 7.869 3.21013 7.90558C3.26599 7.94218 3.3313 7.96168 3.39807 7.96171Z"
        fill="currentColor"
      />
    </svg>
  )
}
