/**
 * @generated SignedSource<<07a0c459b908127244628bf643fddf00>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TransactionTypeEnum = "ACCEPT_OFFER" | "ACCEPT_SWAP" | "APPROVE_COLLECTION" | "ASSETS_RECEIVED" | "ASSETS_SENT" | "BRIDGE_DEPOSIT" | "BRIDGE_WITHDRAW" | "BULK_ACCEPT_OFFER" | "BULK_OFFER_ACCEPTED" | "BULK_PURCHASE" | "BULK_RECEIVED" | "BULK_SALE" | "BULK_SENT" | "BUY_NOW" | "CANCEL_ALL_ORDERS" | "CANCEL_ASK" | "CANCEL_BID" | "CANCEL_COLLECTION_OFFFER" | "CANCEL_ORDERS" | "CANCEL_SWAP" | "CONFIGURE_DROP" | "CREATE_SEADROP_CONTRACT" | "ERC20_APPROVE" | "ERC20_RECEIVED" | "ERC20_SENT" | "ERC20_SWAP_DEPOSIT" | "ERC20_SWAP_WITHDRAW" | "MINT" | "MINT_ALLOWLIST" | "NATIVE_RECEIVED" | "NATIVE_SENT" | "NFT_RECEIVED" | "NFT_SENT" | "OFFER_ACCEPTED" | "OPENSEA_PRO_TRADE" | "REDEEM" | "SALE" | "SWAP_COMPLETE" | "UNKNOWN" | "UNWRAP" | "WRAP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TransactionDetails_metadata$data = {
  readonly type: TransactionTypeEnum;
  readonly " $fragmentSpreads": FragmentRefs<"AcceptDealTransactionDetails_metadata" | "AcceptOfferTransactionDetails_metadata" | "ApproveCollectionTransactionDetails_metadata" | "ApproveCurrencyTransactionDetails_metadata" | "CancelDealTransactionDetails_metadata" | "CancelListingTransactionDetails_metadata" | "CancelOfferTransactionDetails_metadata" | "ConfigureDropTransactionDetails_metadata" | "MintTransactionDetails_metadata" | "NftTransferTransactionDetails_metadata" | "PurchaseTransactionDetails_metadata" | "RedeemTransactionDetails_metadata" | "UnwrapTransactionDetails_metadata" | "WrapTransactionDetails_metadata">;
  readonly " $fragmentType": "TransactionDetails_metadata";
};
export type TransactionDetails_metadata$key = {
  readonly " $data"?: TransactionDetails_metadata$data;
  readonly " $fragmentSpreads": FragmentRefs<"TransactionDetails_metadata">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TransactionDetails_metadata",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PurchaseTransactionDetails_metadata"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CancelListingTransactionDetails_metadata"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CancelOfferTransactionDetails_metadata"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CancelDealTransactionDetails_metadata"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AcceptOfferTransactionDetails_metadata"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApproveCollectionTransactionDetails_metadata"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApproveCurrencyTransactionDetails_metadata"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NftTransferTransactionDetails_metadata"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MintTransactionDetails_metadata"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AcceptDealTransactionDetails_metadata"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WrapTransactionDetails_metadata"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UnwrapTransactionDetails_metadata"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RedeemTransactionDetails_metadata"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConfigureDropTransactionDetails_metadata"
    }
  ],
  "type": "TransactionMetadataType",
  "abstractKey": null
};

(node as any).hash = "deb67da4b778670f719e37a20cc0a94e";

export default node;
