const selectorOptions = [
  {
    label: "English",
    locale: "en-US",
  },
  {
    label: "Español",
    locale: "es",
  },
  {
    label: "Deutsch",
    locale: "de-DE",
  },
  {
    label: "Français",
    locale: "fr",
  },
  {
    label: "日本語",
    locale: "ja",
  },
  {
    label: "한국어",
    locale: "kr",
  },
  {
    label: "中文 (简体)",
    locale: "zh-CN",
  },
  {
    label: "中文 (繁体)",
    locale: "zh-TW",
  },
]

const locales = selectorOptions.map(option => option.locale)

/** @type {import('next-translate').LoaderConfig} */
module.exports = {
  locales,
  defaultLocale: locales[0],
  pages: {
    "*": ["common"],
    "rgx:^/wallet": ["assets", "transactions", "settings"],
  },
  selectorOptions,
  logBuild: process.env.NODE_ENV === "development",
}
