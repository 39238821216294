import React, { useCallback, useMemo, useState } from "react"
import { createContext } from "use-context-selector"
import { noop } from "@/lib/helpers/noop"
import type { GlobalModalProps } from "./GlobalModal.react"

type GlobalModalContextType = {
  content: React.ReactNode
  props: GlobalModalProps | undefined
  openModal: (content: React.ReactNode, props?: GlobalModalProps) => void
  closeModal: () => void
  onPrevious: (() => void) | undefined
}

export const GlobalModalContext = createContext<GlobalModalContextType>({
  content: null,
  props: undefined,
  openModal: noop,
  closeModal: noop,
  onPrevious: undefined,
})

type Props = {
  children: React.ReactNode
}

export function GlobalModalContextProvider({ children }: Props) {
  const [contentStack, setContentStack] = useState<
    [content: React.ReactNode, props?: GlobalModalProps][]
  >([])

  const openModal = useCallback(
    (content: React.ReactNode, props?: GlobalModalProps) => {
      setContentStack(prev => [...prev, [content, props]])
    },
    [],
  )

  const closeModal = useCallback(() => {
    setContentStack([])
  }, [])

  const onPrevious = useMemo(() => {
    if (contentStack.length <= 1) {
      return undefined
    }
    return () => {
      setContentStack(prev => prev.slice(0, prev.length - 1))
    }
  }, [contentStack.length])

  return (
    <GlobalModalContext.Provider
      value={{
        content: contentStack[contentStack.length - 1]?.[0],
        props: contentStack[contentStack.length - 1]?.[1],
        openModal,
        closeModal,
        onPrevious,
      }}
    >
      {children}
    </GlobalModalContext.Provider>
  )
}
