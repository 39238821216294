/**
 * @generated SignedSource<<43f691c60721cee8af3c482db40970a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BannedAccountGateQuery$variables = {
  address: string;
};
export type BannedAccountGateQuery$data = {
  readonly getAccount: {
    readonly metadata: {
      readonly isBanned: boolean;
    } | null | undefined;
  };
};
export type BannedAccountGateQuery = {
  response: BannedAccountGateQuery$data;
  variables: BannedAccountGateQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "address"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "address",
    "variableName": "address"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "AccountMetadataType",
  "kind": "LinkedField",
  "name": "metadata",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isBanned",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BannedAccountGateQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "getAccount",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BannedAccountGateQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "getAccount",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a941e74209713cdbe306011c4e2a0244",
    "id": null,
    "metadata": {},
    "name": "BannedAccountGateQuery",
    "operationKind": "query",
    "text": "query BannedAccountGateQuery(\n  $address: AddressScalar!\n) {\n  getAccount(address: $address) {\n    metadata {\n      isBanned\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "cfc967844f5008119f32acd2e11b14aa";

export default node;
