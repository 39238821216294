import type { CheckMfaEnrollmentPayload } from "@opensea/wallet-messages"
import { usePrivy } from "@privy-io/react-auth"
import React, { useCallback } from "react"
import type { MessageHandler } from "@/providers/Vessel00/messages"
import { VesselMessageHandler } from "@/providers/Vessel00/messages"

export function CheckMfaEnrollmentHandler() {
  const { ready, authenticated, user } = usePrivy()

  const handler: MessageHandler<CheckMfaEnrollmentPayload> = useCallback(
    (_, reply) => {
      const isReady = ready && authenticated && Boolean(user)
      reply({
        isReady,
        isEnrolled: isReady && user?.mfaMethods.length,
      })
    },
    [authenticated, ready, user],
  )

  return (
    <VesselMessageHandler
      action="CheckMfaEnrollment"
      handler={handler}
      type="action"
    />
  )
}
