import { graphql, useFragment } from "react-relay"
import { useChains } from "@/hooks/useChains"
import type { useTransactionNativeCurrencyTotal_metadata$key } from "@/lib/graphql/__generated__/useTransactionNativeCurrencyTotal_metadata.graphql"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"
import { useConnectedChain } from "@/providers/Wallet/selectors"

/**
 * @returns Total unit quantity of native currency on the connected chain that is
 * sent in the transaction
 */
export const useTransactionNativeCurrencyTotal = (
  dataKey: useTransactionNativeCurrencyTotal_metadata$key | null | undefined,
) => {
  const connectedChain = useConnectedChain() ?? "ETHEREUM"
  const { getNativeCurrencyDecimals, getNativeCurrencySymbol } = useChains()

  const data = useFragment(
    graphql`
      fragment useTransactionNativeCurrencyTotal_metadata on TransactionMetadataType {
        sentAssetQuantities {
          asset {
            tokenStandard
            symbol
          }
          quantity
        }
      }
    `,
    dataKey,
  )

  const nativeCurrencyAssetQuantities =
    data?.sentAssetQuantities.filter(
      aq =>
        aq.asset.tokenStandard === "ERC20" &&
        aq.asset.symbol === getNativeCurrencySymbol(connectedChain),
    ) ?? []
  const paymentQuantity = bn(
    BigNumber.sum(...nativeCurrencyAssetQuantities.map(aq => aq.quantity), 0),
    getNativeCurrencyDecimals(connectedChain),
  )

  return paymentQuantity
}
