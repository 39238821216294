import { classNames } from "@opensea/ui-kit"
import React from "react"

export function Skeleton({
  width,
  height,
  className,
}: {
  width?: number
  height?: number
  className?: string
}) {
  return (
    <div
      className={classNames(
        "animate-pulse rounded-medium bg-component-gray-2",
        className,
      )}
      style={{ width, height }}
    />
  )
}
