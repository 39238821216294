import { useCallback } from "react"
import type { Hex } from "viem"
import { createPublicClient, custom, fromHex, hexToBigInt, toHex } from "viem"
import { captureException } from "@sentry/nextjs"
import { GAS_ESTIMATION_BUFFER_PERCENTAGE } from "@/lib/helpers/gas"
import { SUPPORTED_CHAINS } from "@/providers/Privy/chains"
import { useWaitForPrivyWallet } from "@/providers/Privy/useWaitForPrivyWallet"
import { useVessel } from "@/providers/Vessel00/VesselProvider.react"
import { useChains } from "@/hooks/useChains"
import { useTrackingFn } from "@/lib/analytics/useTrackingFn"
import { bn } from "@/lib/helpers/numberUtils"
import type { TransactionParams } from "./types"

type EIP1559GasParams = {
  type: "eip1559"
  maxFeePerGas: Hex
  maxPriorityFeePerGas: Hex
}
type LegacyGasParams = {
  type: "legacy"
  gasPrice: Hex
}
export type GasParams = EIP1559GasParams | LegacyGasParams
export type GasPriceParams =
  | Omit<EIP1559GasParams, "type">
  | Omit<LegacyGasParams, "type">
export const ETH_DEFAULT_PRIORITY_FEE_WEI = 1_500_000_000

type TransactionArgs = {
  isPriority?: boolean
  shouldWaitForReceipt?: boolean
  useBuffer?: boolean
}

// TODO(jihok): instead of using these as default priority params, we may want a "flush" action that uses extreme params like this
// e.g. if user has been waiting for a long time
const getPriorityFee = (value: bigint, networkId: number) => {
  switch (networkId) {
    case 137: // polygon
      return value * BigInt(2)
    default:
      return (value * BigInt(120)) / BigInt(100)
  }
}

export const useSubmitBlockchainTransaction = () => {
  const waitForPrivyWallet = useWaitForPrivyWallet()
  const { emitEvent } = useVessel()
  const { getChainByNetworkId } = useChains()
  const { trackSubmitBlockchainTransaction } = useTracking()

  /**
   * estimate the gas units required and add a buffer to it to prevent failed transactions
   */
  const getGasLimit = useCallback(
    async ({
      useBuffer = true,
      ...params
    }: TransactionParams & { useBuffer?: boolean }) => {
      try {
        const { from, data, to, value } = params[0]
        const privyWallet = await waitForPrivyWallet()
        const ethereumProvider = await privyWallet.getEthereumProvider()
        const networkId = Number(privyWallet.chainId.split(":")[1])
        const viemChain = SUPPORTED_CHAINS.find(chain => chain.id === networkId)
        const publicClient = createPublicClient({
          chain: viemChain,
          transport: custom(ethereumProvider),
        })

        const [latestBlock, estimatedGasUnits] = await Promise.all([
          ethereumProvider.request({
            method: "eth_getBlockByNumber",
            // the params give us the latest mined block + only the hashes of the transactions instead of full tx objects
            // https://ethereum.org/en/developers/docs/apis/json-rpc/#eth_getblockbynumber
            params: ["latest", false],
          }),
          publicClient.estimateGas({
            account: from,
            to,
            value: value ? fromHex(value, "bigint") : undefined,
            data,
          }),
        ])
        const blockGasLimit = hexToBigInt(latestBlock.gasLimit)

        // since gas estimates are non-deterministic, we add a 50% buffer to the estimate unless
        // the buffered estimate is higher than the block gas limit
        const buffer = useBuffer ? GAS_ESTIMATION_BUFFER_PERCENTAGE : 0
        const gasEstimateWithBuffer =
          (estimatedGasUnits * BigInt(100 + buffer)) / BigInt(100)
        const maxGas = toHex(
          gasEstimateWithBuffer > blockGasLimit
            ? estimatedGasUnits
            : gasEstimateWithBuffer,
        )

        return maxGas
      } catch (e) {
        captureException(e, { extra: { useBuffer, ...params } })
        throw e
      }
    },
    [waitForPrivyWallet],
  )

  /**
   * get current network gas fees per gas unit
   *
   * @param isPriority - atm we use this for cancellations
   */
  const getGasPriceParams = useCallback(
    async (isPriority = false) => {
      try {
        const privyWallet = await waitForPrivyWallet()
        const ethereumProvider = await privyWallet.getEthereumProvider()

        // create a public viem client to get the current network gas fees
        // viem will first try `eth_maxPriorityFeePerGas` and fallback to estimating via gasPrice - baseFeePerGas if it's not supported
        const networkId = Number(privyWallet.chainId.split(":")[1])
        const viemChain = SUPPORTED_CHAINS.find(chain => chain.id === networkId)
        const publicClient = createPublicClient({
          chain: viemChain,
          transport: custom(ethereumProvider),
        })
        const { maxFeePerGas, maxPriorityFeePerGas } =
          await publicClient.estimateFeesPerGas()

        let gasParams: GasParams
        if (maxFeePerGas !== undefined) {
          const isEth = networkId === 1
          // TODO: investigate why ethereum eth_maxPriorityFeePerGas undershoots
          // since eth_maxPriorityFeePerGas undershoots on ethereum, we use a default 1.5 gwei (same as provided by privy)
          const currentMaxPriorityFeePerGas = isEth
            ? BigInt(ETH_DEFAULT_PRIORITY_FEE_WEI)
            : maxPriorityFeePerGas

          const maxFeePerGasWithFallback =
            maxFeePerGas === BigInt(0)
              ? await publicClient.getGasPrice()
              : maxFeePerGas

          // priority params offer us the best chance of a successful cancellation, but the total gas paid may be higher than necessary.
          // if we want to reduce the gas paid, we should calculate a maxFeePerGas from the block's baseFee
          // https://docs.alchemy.com/docs/maxpriorityfeepergas-vs-maxfeepergas
          gasParams = {
            type: "eip1559",
            maxFeePerGas: toHex(
              isPriority
                ? getPriorityFee(maxFeePerGasWithFallback, networkId)
                : maxFeePerGasWithFallback,
            ),
            maxPriorityFeePerGas: toHex(
              isPriority
                ? getPriorityFee(currentMaxPriorityFeePerGas, networkId)
                : currentMaxPriorityFeePerGas,
            ),
          }
        } else {
          // while unlikely, maxFeePerGas can return undefined if the network only supports legacy (i.e. not eip-1559 style) transactions
          const { gasPrice } = await publicClient.estimateFeesPerGas({
            type: "legacy",
          })
          gasParams = { type: "legacy", gasPrice: toHex(gasPrice || 0) }
        }
        return gasParams
      } catch (e) {
        captureException(e, { extra: { isPriority } })
        throw e
      }
    },
    [waitForPrivyWallet],
  )

  /**
   * wait for the transaction to be mined and return the receipt
   */
  const getTransactionReceipt = useCallback(
    async (transactionHash: Hex) => {
      try {
        const privyWallet = await waitForPrivyWallet()
        const ethereumProvider = await privyWallet.getEthereumProvider()
        const networkId = Number(privyWallet.chainId.split(":")[1])
        const viemChain = SUPPORTED_CHAINS.find(chain => chain.id === networkId)
        const publicClient = createPublicClient({
          chain: viemChain,
          transport: custom(ethereumProvider),
        })
        const receipt = await publicClient.waitForTransactionReceipt({
          hash: transactionHash,
        })
        return receipt
      } catch (e) {
        captureException(e, { extra: { transactionHash } })
        throw e
      }
    },
    [waitForPrivyWallet],
  )

  const _trackSubmitBlockchainTransaction = useCallback(
    ({
      params,
      gas,
      gasParams,
      args,
    }: {
      params: TransactionParams
      gas: string
      gasParams: GasPriceParams
      args?: TransactionArgs
    }) => {
      const convertToReadable = (gasPriceParams: GasPriceParams) => {
        return Object.fromEntries(
          Object.entries(gasPriceParams).map(([k, v]) => {
            if (k !== "type") {
              return [k, bn(v).toString()]
            }
            return [k, v]
          }),
        )
      }
      trackSubmitBlockchainTransaction({
        params: {
          ...params[0],
          gas: bn(gas).toString(),
          ...convertToReadable(gasParams),
        },
        args,
      })
    },
    [trackSubmitBlockchainTransaction],
  )

  /**
   * sign transaction with privy provider and send it to the network. unless specified, the gas limit and gas price will be calculated on the fly
   */
  const submitBlockchainTransaction = useCallback(
    async (params: TransactionParams, args?: TransactionArgs) => {
      try {
        const { from, data, to, value, nonce } = params[0]
        const privyWallet = await waitForPrivyWallet()
        const ethereumProvider = await privyWallet.getEthereumProvider()
        const networkId = privyWallet.chainId.split(":")[1]
        const chain = getChainByNetworkId(networkId)

        const gas: Hex =
          params[0].gas ||
          (await getGasLimit({ ...params, useBuffer: args?.useBuffer }))

        let gasParams: GasPriceParams
        if (params[0].maxFeePerGas && params[0].maxPriorityFeePerGas) {
          gasParams = {
            maxFeePerGas: params[0].maxFeePerGas,
            maxPriorityFeePerGas: params[0].maxPriorityFeePerGas,
          }
        } else if (params[0].gasPrice) {
          gasParams = { gasPrice: params[0].gasPrice }
        } else {
          const { type: _, ...rest } = await getGasPriceParams(args?.isPriority)
          gasParams = rest
        }

        const transactionHash: Hex = await ethereumProvider.request({
          method: "eth_sendTransaction",
          params: [
            {
              to,
              from,
              data,
              value,
              nonce,
              gas,
              ...gasParams,
            },
          ],
        })
        emitEvent({
          type: "event",
          event: "PollTransaction",
          transactionHash,
          chain: chain.identifier,
        })

        _trackSubmitBlockchainTransaction({
          params,
          gas,
          gasParams,
          args,
        })

        if (args?.shouldWaitForReceipt) {
          const receipt = await getTransactionReceipt(transactionHash)
          return receipt.transactionHash
        }

        return transactionHash
      } catch (e) {
        captureException(e, {
          extra: { params, args },
          tags: { userFlow: "blockchainTx" },
        })
        throw e
      }
    },
    [
      waitForPrivyWallet,
      getChainByNetworkId,
      getGasLimit,
      emitEvent,
      _trackSubmitBlockchainTransaction,
      getGasPriceParams,
      getTransactionReceipt,
    ],
  )

  return { getGasLimit, getGasPriceParams, submitBlockchainTransaction }
}

type ConvertHexToReadable<T> = {
  [P in keyof T]: T[P] extends Hex | undefined ? string | undefined : T[P]
}
type TrackingProps = {
  params: ConvertHexToReadable<TransactionParams[0]>
  args?: TransactionArgs
}
const useTracking = () => {
  return {
    trackSubmitBlockchainTransaction: useTrackingFn<TrackingProps>(
      "submit blockchain transaction",
    ),
  }
}
