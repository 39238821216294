import ServerCookies from "cookies"
import type { CookieAttributes } from "js-cookie"
import Cookies from "js-cookie"
import type { GetServerSidePropsContext, NextPageContext } from "next"

export class Cookie<T> {
  key: string

  constructor(key: string) {
    this.key = key
  }

  get(context?: NextPageContext | GetServerSidePropsContext): T | undefined {
    if (context?.req && context.res) {
      const cookie = new ServerCookies(context.req, context.res).get(this.key)
      if (!cookie) {
        return undefined
      }
      try {
        return JSON.parse(decodeURIComponent(cookie))
      } catch (_) {
        return undefined
      }
    }

    const cookie = Cookies.get(this.key)
    if (!cookie) {
      return undefined
    }

    try {
      return JSON.parse(cookie)
    } catch (_) {
      return undefined
    }
  }

  /**
   * Set cookie value
   * @param value - cookie value
   * @param options - cookie options
   * @returns stringified cookie value
   */
  set = (value: T, options?: CookieAttributes) => {
    return Cookies.set(this.key, JSON.stringify(value), options)
  }

  /**
   * Remove cookie
   * @param options - cookie options
   */
  remove = (options?: CookieAttributes) => {
    Cookies.remove(this.key, options)
  }
}
