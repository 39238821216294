import type { ConnectedWallet } from "@privy-io/react-auth"
import { useCallback, useEffect, useRef } from "react"
import { useDeferredPromise } from "../../hooks/useDeferredPromise"
import { usePrivyWallet } from "./usePrivyWallet"

const TIMEOUT = 5_000

/**
 * Rejects if no connected privy wallet is found after timeout.
 * @returns async function that resolves once a connected privy wallet is found.
 */
export const useWaitForPrivyWallet = (): (() => Promise<ConnectedWallet>) => {
  const privyWallet = usePrivyWallet()

  const deferredPromise = useDeferredPromise<ConnectedWallet>()
  const timeoutRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (privyWallet) {
      deferredPromise.resolve(privyWallet)
    }
  }, [deferredPromise, privyWallet])

  return useCallback(async () => {
    if (privyWallet) {
      return privyWallet
    }

    // set a timeout to reject after TIMEOUT ms if promise is not already settled
    if (!deferredPromise.isSettled && timeoutRef.current === undefined) {
      timeoutRef.current = setTimeout(() => {
        deferredPromise.reject(
          new Error("Timed out waiting for connected privy wallet"),
        )
      }, TIMEOUT)
    }

    return deferredPromise.promise.finally(() => {
      // clear reject timeout once settled
      clearInterval(timeoutRef.current)
      timeoutRef.current = undefined
    })
  }, [deferredPromise, privyWallet])
}
