import { ChildFrame, MessageType } from "@opensea/vessel-0.0"
import { useRef, useCallback, useState } from "react"
import { useMountEffect } from "@/hooks/useMountEffect"
import { PARENT_FRAME_TARGET_ORIGIN } from "./constants"
import type { MessageHandler } from "./messages/types"
import { useVesselLogger } from "./useVesselLogger"
import { isValidMessagePayload } from "./utils"

export const useInitializeVessel = () => {
  const vesselLog = useVesselLogger()

  const messageHandlersRef = useRef<MessageHandler[]>([])
  const addMessageHandler = useCallback((handler: MessageHandler) => {
    messageHandlersRef.current = [...messageHandlersRef.current, handler]
    return () => {
      messageHandlersRef.current = messageHandlersRef.current.filter(
        fn => fn !== handler,
      )
    }
  }, [])

  const vesselRef = useRef<ChildFrame | null>(null)
  const vesselUnsubRef = useRef<() => void>()

  const [isConnected, setIsConnected] = useState(false)

  if (vesselRef.current === null && typeof window !== "undefined") {
    vesselRef.current = new ChildFrame({
      parentOrigin: PARENT_FRAME_TARGET_ORIGIN,
    })

    vesselUnsubRef.current = vesselRef.current.on(
      MessageType.Message,
      (payload, reply) => {
        vesselLog("Received message", payload, messageHandlersRef.current)
        if (!isConnected) {
          setIsConnected(true)
        }
        messageHandlersRef.current.forEach(handler => {
          if (isValidMessagePayload(payload)) {
            handler(payload, reply)
          }
        })
      },
    )
  }

  useMountEffect(() => {
    return () => {
      vesselUnsubRef.current?.()
    }
  })

  return { addMessageHandler, vesselRef, isConnected }
}
