import React, { createContext, useContext } from "react"
import type { Chain } from "./types"

type ChainContextType = {
  chains: Chain[]
}

const ChainContext = createContext<ChainContextType>({
  chains: [],
})

export const useChainContext = () => useContext(ChainContext)

type ChainContextProviderProps = {
  children: React.ReactNode
  chains: Chain[]
}

export function ChainContextProvider({
  children,
  chains,
}: ChainContextProviderProps) {
  return (
    <ChainContext.Provider value={{ chains }}>{children}</ChainContext.Provider>
  )
}
