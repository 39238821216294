import { truncateTextInMiddle } from "@/lib/helpers/stringUtils"

type TruncateAddressOptions = {
  before?: number
  after?: number
}

export const truncateAddress = (
  address: string,
  { before = 6, after = 4 }: TruncateAddressOptions = {},
) => {
  return truncateTextInMiddle(address, { before, after })
}
