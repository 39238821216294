import { ThemeProvider } from "@opensea/next-themes"
import { ErrorBoundary } from "@sentry/nextjs"
import type { IMutableContext, IToggle } from "@unleash/nextjs/client"
import React from "react"
import type { Environment } from "react-relay"
import { RelayEnvironmentProvider } from "react-relay"
import { GlobalOopsPage } from "@/components/common/GlobalOopsPage"
import { ChainContextProvider } from "@/hooks/useChains/context"
import type { Chain } from "@/hooks/useChains/types"
import type { WalletData } from "@/lib/wallet/types"
import { FlagProvider } from "./FlagProvider.react"
import { Fonts } from "./Fonts.react"
import { BannedAccountGate } from "./gates/BannedAccountGate.react"
import { IncompatibleNetworkGate } from "./gates/IncompatibleNetworkGate.react"
import { GlobalModalContextProvider } from "./GlobalModal"
import { KeyboardNavigationProvider } from "./KeyboardNavigation"
import { OSNextDataProvider } from "./OSNextData/OSNextDataProvider.react"
import { AuthHandlers } from "./Privy/auth/AuthHandlers"
import { PrivyProvider } from "./Privy/PrivyProvider.react"
import { VesselProvider as Vessel00Provider } from "./Vessel00/VesselProvider.react"
import { VesselProvider } from "./Vessel/VesselProvider"
import { WalletProvider } from "./Wallet/WalletProvider.react"

type AppProvidersProps = {
  children: React.ReactNode
  disableWalletLayout?: boolean
  relayEnvironment: Environment
  toggles?: IToggle[]
  unleashContext?: IMutableContext
  wallet?: WalletData
  chains: Chain[]
}

export function AppProviders({
  children,
  relayEnvironment,
  toggles,
  unleashContext,
  wallet,
  chains,
}: AppProvidersProps) {
  return (
    <>
      <Fonts />
      <ErrorBoundary fallback={GlobalOopsPage}>
        <ThemeProvider>
          <KeyboardNavigationProvider>
            <RelayEnvironmentProvider environment={relayEnvironment}>
              <FlagProvider bootstrap={toggles} context={unleashContext}>
                <GlobalModalContextProvider>
                  <ChainContextProvider chains={chains}>
                    <VesselProvider>
                      <Vessel00Provider>
                        <PrivyProvider>
                          <OSNextDataProvider>
                            <WalletProvider wallet={wallet}>
                              {process.env.NODE_ENV !== "test" && (
                                <AuthHandlers />
                              )}
                              <BannedAccountGate>
                                <IncompatibleNetworkGate>
                                  {children}
                                </IncompatibleNetworkGate>
                              </BannedAccountGate>
                            </WalletProvider>
                          </OSNextDataProvider>
                        </PrivyProvider>
                      </Vessel00Provider>
                    </VesselProvider>
                  </ChainContextProvider>
                </GlobalModalContextProvider>
              </FlagProvider>
            </RelayEnvironmentProvider>
          </KeyboardNavigationProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </>
  )
}
