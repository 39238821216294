import { UAParser } from "ua-parser-js"

type BrowserContext = {
  userAgent: string
  cookiesEnabled: boolean
  /**
   * The undefined value is used when device type is not indicated in the user agent.
   * Desktops and laptops do not indicate device type.
   */
  device: string | undefined
  os: string | undefined
  connectionSpeed: string | undefined
  screenSize: {
    width: number
    height: number
  }
  windowSize: {
    width: number
    height: number
  }
}

export type BrowserTrackingContext = {
  Browser: BrowserContext
}

// Extend with type definition for experimental navigator.connection
// https://developer.mozilla.org/en-US/docs/Web/API/Navigator/connection
type ExtendedNavigator = Navigator & {
  connection?: {
    effectiveType?: string
  }
}

export const getBrowserContext = (): BrowserContext | undefined => {
  if (typeof window === "undefined") {
    return undefined
  }

  const userAgent = window.navigator.userAgent
  const parsedUserAgent = UAParser(userAgent)

  const connectionSpeed = (window.navigator as ExtendedNavigator).connection
    ?.effectiveType

  return {
    userAgent,
    device: parsedUserAgent.device.type,
    os: parsedUserAgent.os.name,
    connectionSpeed,
    cookiesEnabled: window.navigator.cookieEnabled,
    screenSize: {
      width: window.screen.width,
      height: window.screen.height,
    },
    windowSize: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
  }
}
