export function truncateText(str: string, maxLength = 50): string {
  if (!str || str.length <= maxLength) {
    return str
  }
  return `${str.substr(0, maxLength)}...`
}

export function truncateTextInMiddle(
  str: string,
  { before, after }: { before: number; after: number },
): string {
  if (str.length <= before) {
    return str
  }

  return (
    truncateText(str, before) + str.substring(str.length - after, str.length)
  )
}
