import { useTranslate } from "@opensea/next-translate"
import { Flex, FlexColumn } from "@opensea/ui-kit"
import { Separator } from "@opensea/ui-kit/client"
import React from "react"
import { graphql, useFragment } from "react-relay"
import { PaymentAssetMedia } from "@/components/common/media/PaymentAssetMedia"
import { Text } from "@/design-system/Text"
import { useChains } from "@/hooks/useChains"
import type { WrapTransactionDetails_metadata$key } from "@/lib/graphql/__generated__/WrapTransactionDetails_metadata.graphql"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"
import { TransactionDetailsTokenAmount } from "../components/TransactionDetailsTokenAmount.react"
import type { TransactionDetailsProps } from "../TransactionDetails.react"

type Props = Omit<TransactionDetailsProps, "metadata"> & {
  metadata: WrapTransactionDetails_metadata$key
}

export function WrapTransactionDetails({
  metadata: metadataDataKey,
  gasFee,
  isLoadingGasFee,
  chain,
}: Props) {
  const t = useTranslate("transactions")
  const { getNativeCurrencyUsdPrice, getNativeCurrencySymbol } = useChains()

  const { sentAssetQuantities } = useFragment(
    graphql`
      fragment WrapTransactionDetails_metadata on TransactionMetadataType {
        sentAssetQuantities {
          asset {
            usdSpotPrice
            symbol
            decimals
            ...PaymentAssetMedia_asset
          }
          quantity
        }
      }
    `,
    metadataDataKey,
  )

  const nativeCurrencySymbol = getNativeCurrencySymbol(chain)
  const nativeCurrencyUsdPrice = getNativeCurrencyUsdPrice(chain)

  // All payment asset quantities should have same symbol
  const paymentAsset = sentAssetQuantities[0].asset
  const paymentSymbol = paymentAsset.symbol ?? "ETH"
  const paymentUsdSpotPrice = paymentAsset.usdSpotPrice ?? 1
  const paymentDecimals = paymentAsset.decimals
  const paymentQuantity = bn(
    BigNumber.sum(...sentAssetQuantities.map(aq => aq.quantity)),
    paymentDecimals,
  )

  return (
    <FlexColumn className="gap-6 p-4">
      <div>
        <Flex className="items-center gap-4">
          <PaymentAssetMedia asset={paymentAsset} size="sm" />
          <Text weight="semibold">{paymentAsset.symbol}</Text>
        </Flex>
        <Separator className="mt-4" />
      </div>

      <TransactionDetailsTokenAmount
        label={t("transactionConfirmation.amount", "Amount")}
        quantity={paymentQuantity}
        symbol={paymentSymbol}
        usdPrice={paymentUsdSpotPrice}
      />

      <TransactionDetailsTokenAmount
        isLoading={isLoadingGasFee}
        label={t("transactionConfirmation.gas", "Gas")}
        quantity={gasFee}
        symbol={nativeCurrencySymbol}
        usdPrice={nativeCurrencyUsdPrice}
      />
    </FlexColumn>
  )
}
