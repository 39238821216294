import { useTranslate } from "@opensea/next-translate"
import { FlexColumn } from "@opensea/ui-kit"
import React from "react"
import type { TypedDataDefinition } from "viem"
import { Text } from "@/design-system/Text"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTrackingFn } from "@/lib/analytics/useTrackingFn"
import { useGlobalModal } from "@/providers/GlobalModal"
import { useWaitForPrivyWallet } from "@/providers/Privy/useWaitForPrivyWallet"
import { ConfirmationModalContent } from "../ConfirmationModal"
import { ConfirmationModalTitle } from "../ConfirmationModal/ConfirmationModalTitle.react"
import { USER_REJECTED_REQUEST_ERROR } from "../constants"
import type { SignTypedDataParams } from "../types"
import { isMfaCancelledError } from "../utils"
import { TypedDataDisplay } from "./TypedDataDisplay.react"

type SignTypedDataConfirmationModalProps = {
  params: SignTypedDataParams
  onSuccess: (result: unknown) => void
  onError: (error: unknown) => void
}

export function SignTypedDataConfirmationModal({
  params,
  onSuccess,
  onError,
}: SignTypedDataConfirmationModalProps) {
  const t = useTranslate("transactions")
  const { closeModal } = useGlobalModal()
  const waitForPrivyWallet = useWaitForPrivyWallet()

  const {
    trackOpenSignTypedDataConfirmationModal,
    trackConfirmSignTypedData,
    trackRejectSignTypedData,
    trackSignTypedDataSuccessful,
    trackSignTypedDataFailed,
  } = useTracking()
  useMountEffect(() => {
    trackOpenSignTypedDataConfirmationModal()
  })

  const onConfirm = async () => {
    trackConfirmSignTypedData()
    // Sign using Privy ethereum provider
    const privyWallet = await waitForPrivyWallet()
    const ethereumProvider = await privyWallet.getEthereumProvider()
    try {
      const result = await ethereumProvider.request({
        method: "eth_signTypedData_v4",
        params,
      })
      trackSignTypedDataSuccessful()
      onSuccess(result)
    } catch (error) {
      if (isMfaCancelledError(error)) {
        return // Do not handle MFA cancellation as an error
      }
      trackSignTypedDataFailed()
      onError(error)
    }
    closeModal()
  }

  const onReject = () => {
    trackRejectSignTypedData()
    onError(USER_REJECTED_REQUEST_ERROR)
    closeModal()
  }

  const data: TypedDataDefinition =
    typeof params[1] === "string" ? JSON.parse(params[1]) : params[1]

  return (
    <ConfirmationModalContent onConfirm={onConfirm} onReject={onReject}>
      <ConfirmationModalTitle>
        {t("signatureConfirmation.title", "Signature request")}
      </ConfirmationModalTitle>

      <FlexColumn className="gap-4 overflow-scroll rounded-xl bg-component-gray-1 p-4">
        <Text weight="semibold">
          {t("signatureConfirmation.message.heading", "Message")}
        </Text>
        <TypedDataDisplay data={data} />
      </FlexColumn>
    </ConfirmationModalContent>
  )
}

const useTracking = () => {
  return {
    trackOpenSignTypedDataConfirmationModal: useTrackingFn(
      "open sign typed data confirmation modal",
    ),
    trackRejectSignTypedData: useTrackingFn("reject sign typed data"),
    trackConfirmSignTypedData: useTrackingFn("confirm sign typed data"),
    trackSignTypedDataSuccessful: useTrackingFn("sign typed data successful"),
    trackSignTypedDataFailed: useTrackingFn("sign typed data failed"),
  }
}
