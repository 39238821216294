import { SpaceBetween, FlexColumn, Flex, CenterAligned } from "@opensea/ui-kit"
import { Tooltip } from "@opensea/ui-kit/client"
import { InfoFilled } from "@opensea/ui-kit/icons"
import React from "react"
import { TokenAmount } from "@/components/common/TokenAmount"
import { Skeleton } from "@/design-system/Skeleton.react"
import { Text } from "@/design-system/Text"
import type { NumberInput } from "@/lib/helpers/numberUtils"
import { displayFiat, bn } from "@/lib/helpers/numberUtils"

type TransactionDetailsTokenAmount = {
  label: string
  quantity: NumberInput
  symbol: string
  /**
   * If usdPrice is included it will be displayed under the symbol total
   */
  usdPrice?: NumberInput
  isLoading?: boolean
  labelTooltip?: string
}

export function TransactionDetailsTokenAmount({
  label,
  symbol,
  quantity,
  usdPrice,
  isLoading,
  labelTooltip,
}: TransactionDetailsTokenAmount) {
  const renderDetails = () => {
    if (usdPrice) {
      return isLoading ? (
        <Skeleton className="my-1 h-4 w-12" />
      ) : (
        <Text>{displayFiat(bn(quantity).times(usdPrice))}</Text>
      )
    }
    return null
  }

  return (
    <SpaceBetween>
      <div>
        <Flex className="items-center">
          <Text weight="semibold">{label}</Text>
          {labelTooltip ? (
            <Tooltip content={labelTooltip}>
              <CenterAligned className="ml-1">
                <InfoFilled size={16} />
              </CenterAligned>
            </Tooltip>
          ) : null}
        </Flex>
      </div>
      <FlexColumn className="items-end gap-0.5">
        {isLoading ? (
          <Skeleton className="my-0.5 h-5 w-24" />
        ) : (
          <Text weight={usdPrice ? "semibold" : undefined}>
            <TokenAmount amount={quantity} symbol={symbol} />
          </Text>
        )}
        {renderDetails()}
      </FlexColumn>
    </SpaceBetween>
  )
}
