import { ENVIRONMENT } from "@/constants/env"
import type { Environment } from "@/constants/env/environment/schema"
import * as sidecar from "@/lib/sidecar/config"

export const AMPLITUDE_API_KEY = sidecar.getValue("amplitudeApiKey")

const LOG_TO_CONSOLE_ENVIRONMENTS: Environment[] = ["development", "preview"]

export const IS_LOG_TO_CONSOLE_ENVIRONMENT =
  LOG_TO_CONSOLE_ENVIRONMENTS.includes(ENVIRONMENT)
