import { noop } from "lodash"

export const createDeferredPromise = <T>() => {
  let isSettled = false
  let resolve: (arg: T) => void = noop
  let reject: (reason: unknown) => void = noop
  const promise = new Promise<T>((innerResolve, innerReject) => {
    resolve = (arg: T) => {
      innerResolve(arg)
      isSettled = true
    }
    reject = (reason: unknown) => {
      // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
      innerReject(reason)
      isSettled = true
    }
  })

  return { promise, resolve, reject, isSettled }
}
