import type { SheetProps } from "@opensea/ui-kit/client"

export const PREVENT_CLOSE_MODAL_PROPS: Pick<
  SheetProps,
  "onEscapeKeyDown" | "onPointerDownOutside" | "onInteractOutside"
> = {
  onEscapeKeyDown: e => {
    e.preventDefault()
  },
  onPointerDownOutside: e => {
    e.preventDefault()
  },
  onInteractOutside: e => {
    e.preventDefault()
  },
}
