import { useCallback } from "react"
import { graphql } from "react-relay"
import type {
  useUpdateTransactionStatusPendingMutation,
  useUpdateTransactionStatusPendingMutation$variables,
} from "@/lib/graphql/__generated__/useUpdateTransactionStatusPendingMutation.graphql"
import { useMutation } from "@/lib/graphql/useMutation"

export const useUpdateUserTransactionState = () => {
  const [commitFn] = useMutation<useUpdateTransactionStatusPendingMutation>(
    graphql`
      mutation useUpdateTransactionStatusPendingMutation(
        $userTransactionId: UserTransactionRelayID!
        $state: State!
        $transactionHash: String
      ) {
        userTransaction {
          updateStatus(
            state: $state
            userTransactionId: $userTransactionId
            transactionHash: $transactionHash
          ) {
            relayId
            state
          }
        }
      }
    `,
  )

  const updateUserTransactionState = useCallback(
    async (variables: useUpdateTransactionStatusPendingMutation$variables) => {
      await commitFn({
        variables,
      })
    },
    [commitFn],
  )

  return updateUserTransactionState
}
