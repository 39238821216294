import type { Client, Sink, ExecutionResult } from "graphql-ws"
import { createClient } from "graphql-ws"
import type { ComponentType } from "react"
import type { RelayProps, RelayOptions } from "relay-nextjs"
import { withRelay as withRelayBase } from "relay-nextjs"
import { useRelayNextjs } from "relay-nextjs/app"
import type { UseRelayNextJsProps } from "relay-nextjs/component"
import type {
  FetchFunction,
  SubscribeFunction,
  RequestParameters,
  GraphQLResponse,
  GraphQLTaggedNode,
} from "relay-runtime"
import {
  _RefType,
  Network,
  RecordSource,
  Store,
  Observable,
  Environment,
} from "relay-runtime"
import type { RecordMap } from "relay-runtime/lib/store/RelayStoreTypes"
import { GRAPHQL_API_URL, WS_GRAPHQL_API_URL } from "@/constants/api"
import { APP_NAME } from "@/constants/env"
import { trackAction } from "../integrations/datadog"
import { getSignedQuery } from "./sign"

const NUM_RETRIES = 3

const fetchFunction: FetchFunction = (
  operation,
  variables,
  _cacheConfig,
  _uploadables,
) => {
  const headers: HeadersInit = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "x-signed-query": getSignedQuery(operation.name),
    "x-app-id": APP_NAME,
    "x-build-id": process.env.BUILD_ID ?? "development",
  }

  if (typeof window === "undefined") {
    headers["User-Agent"] = "OpenSea/Next/SSR"
    headers["x-api-key"] = process.env.SSR_API_KEY ?? ""
  }

  const fetchWithRetry = async (attempt = 0): Promise<Response> => {
    const response = await fetch(GRAPHQL_API_URL, {
      method: "POST",
      headers,
      body: JSON.stringify({ query: operation.text, variables }),
    })

    if (response.status === 429) {
      // Check if blocked by Cloudflare (will have a rule id)
      const data = await response.json().catch(() => undefined)
      const ruleId = data?.id
      void trackAction("ratelimited", { ruleId })

      if (attempt < NUM_RETRIES) {
        const retryAfter = parseInt(response.headers.get("retry-after") ?? "1")
        // If we can retry in less than 3 seconds. Setting an upper limit to something that might result
        // into acceptable user experience. If we were told to wait for e.g 10s, its better to error out.
        if (retryAfter <= 3) {
          await new Promise(resolve => {
            setTimeout(resolve, retryAfter * 1000)
          })
          return fetchWithRetry(attempt + 1)
        }
        return response
      }
    }

    return response
  }

  return fetchWithRetry().then(response => response.json())
}

type OperationWithCacheId = RequestParameters & {
  cacheID: string | null
}

const createSubscribeFn =
  (client: Client): SubscribeFunction =>
  (operation, variables) => {
    const op = operation as OperationWithCacheId
    return Observable.create<GraphQLResponse>(sink => {
      return client.subscribe(
        {
          operationName: op.name,
          query: "",
          variables,
          extensions: {
            "x-signed-query": getSignedQuery(op.name),
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            "x-query-id": op.cacheID || op.id,
          },
        },
        sink as Sink<ExecutionResult>,
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }).catch((err: any) => {
      if (err[0]?.extensions && err[0].extensions.code === "UNKNOWN_QUERY_ID") {
        return Observable.create(sink => {
          if (!op.text) {
            sink.error(new Error("Operation text cannot be empty"))
            return
          }
          return client.subscribe(
            {
              operationName: op.name,
              query: op.text,
              variables,
              extensions: {
                "x-signed-query": getSignedQuery(op.name),
                "x-query-id": op.cacheID || op.id,
              },
            },
            sink as Sink<ExecutionResult>,
          )
        })
      }
      throw err
    })
  }

// eslint-disable-next-line import/no-unused-modules
export function createClientSideEnvironment(
  initialRecords?: RecordMap,
): Environment {
  const wsClient = createClient({
    url: WS_GRAPHQL_API_URL,
    retryAttempts: 100,
    connectionAckWaitTimeout: 12_000,
    lazyCloseTimeout: 3_000,
  })

  const store = new Store(new RecordSource(initialRecords))
  return new Environment({
    network: Network.create(fetchFunction, createSubscribeFn(wsClient)),
    store,
    isServer: typeof window === "undefined",
  })
}

function createServerSideEnvironment(initialRecords?: RecordMap): Environment {
  return new Environment({
    network: Network.create(fetchFunction),
    store: new Store(new RecordSource(initialRecords)),
  })
}

let clientEnvironment: Environment | undefined

export const getClientSideEnvironment = (initialRecords?: RecordMap) => {
  if (!clientEnvironment) {
    clientEnvironment = createClientSideEnvironment(initialRecords)
  }
  return clientEnvironment
}

export const getRelayEnvironment = (initialRecords?: RecordMap) => {
  return typeof window === "undefined"
    ? createServerSideEnvironment(initialRecords)
    : getClientSideEnvironment(initialRecords)
}

// eslint-disable-next-line import/no-unused-modules
export const withRelay = <
  Props extends RelayProps,
  ServerSideProps extends object,
>(
  Component: ComponentType<Props>,
  query: GraphQLTaggedNode,
  opts: Omit<
    RelayOptions<Props, ServerSideProps>,
    "createClientEnvironment" | "createServerEnvironment"
  > = {},
) => {
  return withRelayBase(Component, query, {
    createClientEnvironment: () => getClientSideEnvironment(),
    createServerEnvironment: () =>
      Promise.resolve(createServerSideEnvironment()),
    ...opts,
  })
}

export type UseRelayProps = UseRelayNextJsProps

export const useRelay = <PageProps extends UseRelayProps>(
  pageProps: PageProps,
) => {
  return useRelayNextjs(pageProps, {
    createClientEnvironment: () => getClientSideEnvironment(),
  })
}

export const toRelayId = (type: string, id: number) => {
  return Buffer.from(`${type}:${id.toString()}`).toString("base64")
}
