/**
 * @generated SignedSource<<2ad3c087a1e7ecbebc4acb56f017df8e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CancelDealTransactionDetails_metadata$data = {
  readonly receivedAssetQuantities: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"DealSideItemsDisplay_assetQuantities">;
  }>;
  readonly sentAssetQuantities: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"DealSideItemsDisplay_assetQuantities">;
  }>;
  readonly " $fragmentType": "CancelDealTransactionDetails_metadata";
};
export type CancelDealTransactionDetails_metadata$key = {
  readonly " $data"?: CancelDealTransactionDetails_metadata$data;
  readonly " $fragmentSpreads": FragmentRefs<"CancelDealTransactionDetails_metadata">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "DealSideItemsDisplay_assetQuantities"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CancelDealTransactionDetails_metadata",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetQuantityType",
      "kind": "LinkedField",
      "name": "sentAssetQuantities",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetQuantityType",
      "kind": "LinkedField",
      "name": "receivedAssetQuantities",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "TransactionMetadataType",
  "abstractKey": null
};
})();

(node as any).hash = "2afc94d8fa67eab218ae8a05e16eae1a";

export default node;
