import { useTranslate } from "@opensea/next-translate"
import type { TransactionTypeEnum } from "@/lib/graphql/__generated__/Transaction_metadata.graphql"

export const useTransactionConfirmationTitle = (type?: TransactionTypeEnum) => {
  const t = useTranslate("transactions")

  switch (type) {
    // Purchase
    case "BULK_PURCHASE":
    case "BUY_NOW":
      return t("transactionDetails.title.purchase", "Approve purchase")

    // Cancel
    case "CANCEL_ASK":
      return t("transactionDetails.title.cancelListing", "Cancel")
    case "CANCEL_BID":
      return t("transactionDetails.title.cancelOffer", "Cancel")
    case "CANCEL_COLLECTION_OFFFER":
      return t(
        "transactionDetails.title.cancelCollectionOffer",
        "Cancel collection offer",
      )
    case "CANCEL_SWAP":
      return t("transactionDetails.title.cancelCancelDeal", "Cancel deal")
    case "CANCEL_ORDERS":
      return t("transactionDetails.title.cancelOrders", "Cancel orders")
    case "CANCEL_ALL_ORDERS":
      return t(
        "transactionDetails.title.cancelAll",
        "Cancel all listings and offers",
      )

    // Approval
    case "ERC20_APPROVE":
      return t("transactionDetails.title.approveErc20", "Approve currency")
    case "APPROVE_COLLECTION":
      return t(
        "transactionDetails.title.approveCollection",
        "Approve collection",
      )

    // NFT Transfer
    case "NFT_SENT":
    case "BULK_SENT":
      return t("transactionDetails.title.transfer", "Approve transfer")

    // Mint
    case "MINT":
    case "MINT_ALLOWLIST":
      return t("transactionDetails.title.mint", "Approve mint")

    // Accept offer
    case "ACCEPT_OFFER":
    case "BULK_ACCEPT_OFFER":
      return t("transactionDetails.title.acceptOffer", "Accept offer")

    // Accept deal
    case "ACCEPT_SWAP":
      return t("transactionDetails.title.acceptDeal", "Accept deal")

    // Wrap/Unwrap
    case "WRAP":
      return t("transactionDetails.title.wrap", "Add to offer balance")
    case "UNWRAP":
      return t("transactionDetails.title.unwrap", "Withdraw from offer balance")

    // Create Collection
    case "CREATE_SEADROP_CONTRACT":
      return t(
        "transactionDetails.title.createSeadropContract",
        "Create SeaDrop contract",
      )
    // Create Drop
    case "CONFIGURE_DROP":
      return t("transactionDetails.title.configureDrop", "Configure Drop")

    // Redeem
    case "REDEEM":
      return t("transactionDetails.title.redeem", "Redeem")

    // Unimplemented
    case "ERC20_SENT":
    case "NATIVE_SENT":
    case "NFT_RECEIVED":
    case "ERC20_RECEIVED":
    case "NATIVE_RECEIVED":
    case "BULK_RECEIVED":
      return t("transactionDetails.title.unimplemented", "Approve transaction")

    // Unrecognized
    case "UNKNOWN":
    default:
      return t("transactionDetails.title.default", "Approve transaction")
  }
}
