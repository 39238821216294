import { IS_TESTNET } from "@/constants/testnet"
import type { Chain, ChainByIdentifier, ChainIdentifier } from "./types"

export const createChainByIdentifierLookup = (chains: readonly Chain[]) => {
  return chains.reduce<ChainByIdentifier>((acc, chain) => {
    acc[chain.identifier] = chain
    return acc
    // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
  }, {} as ChainByIdentifier)
}

export const isChainOnMatchingNetwork = (chain: Chain) => {
  return IS_TESTNET ? chain.network === "TESTNET" : chain.network === "MAINNET"
}

// TODO: source this from BE to share with next?
export const getCounterpartNetworkChain = (chain: ChainIdentifier) => {
  switch (chain) {
    case "ETHEREUM":
      return "SEPOLIA"
    case "ARBITRUM":
      return "ARBITRUM_SEPOLIA"
    case "ARBITRUM_SEPOLIA":
      return "ARBITRUM"
    case "AVALANCHE":
      return "AVALANCHE_FUJI"
    case "AVALANCHE_FUJI":
      return "AVALANCHE"
    case "BASE":
      return "BASE_SEPOLIA"
    case "BASE_SEPOLIA":
      return "BASE"
    case "BLAST":
      return "BLAST_SEPOLIA"
    case "BLAST_SEPOLIA":
      return "BLAST"
    case "SEI":
      return "SEI_TESTNET"
    case "SEI_TESTNET":
      return "SEI"
    case "MATIC":
      return "AMOY"
    case "AMOY":
      return "MATIC"
    case "OPTIMISM":
      return "OPTIMISM_SEPOLIA"
    case "OPTIMISM_SEPOLIA":
      return "OPTIMISM"
    case "SOLANA":
      return "SOLDEV"
    case "SOLDEV":
      return "SOLANA"
    case "ZORA":
      return "ZORA_TESTNET"
    case "ZORA_TESTNET":
      return "ZORA"
    case "SEPOLIA":
    default:
      return "ETHEREUM"
  }
}
