import { useTranslate } from "@opensea/next-translate"
import { CenterAligned, IconButton } from "@opensea/ui-kit"
import { Close, ChevronLeft } from "@opensea/ui-kit/icons"
import type { MfaMethod } from "@privy-io/react-auth"
import React, { useState } from "react"
import { Text } from "@/design-system/Text"
import { useGlobalModal } from "@/providers/GlobalModal"
import { MfaRequestCodeInput } from "./MfaRequestCodeInput.react"
import { MfaRequestMethodSelection } from "./MfaRequestMethodSelection.react"
import { useMfaRequestTracking } from "./useMfaRequestTracking"

export type MfaRequestModalProps = {
  mfaMethods: MfaMethod[]
  initMfa: (mfaMethod: MfaMethod) => Promise<unknown>
  submitMfa: (mfaMethod: MfaMethod, code: string) => Promise<void>
  cancelMfa: () => void
}

export function MfaRequestModal({
  mfaMethods,
  initMfa,
  submitMfa,
  cancelMfa,
}: MfaRequestModalProps) {
  const t = useTranslate("common")
  const { onPrevious, closeModal } = useGlobalModal()

  const { trackMfaCancelled } = useMfaRequestTracking()

  const [selectedMfaMethod, setSelectedMfaMethod] = useState<
    MfaMethod | undefined
  >(mfaMethods.length === 1 ? mfaMethods[0] : undefined)

  const determineBackAction = () => {
    if (selectedMfaMethod && mfaMethods.length > 1) {
      // Navigate to MFA method selection
      return () => {
        setSelectedMfaMethod(undefined)
        cancelMfa()
        trackMfaCancelled({ mfaMethod: selectedMfaMethod })
      }
    } else if (onPrevious) {
      // Navigate to previous modal
      return () => {
        cancelMfa()
        onPrevious()
        if (selectedMfaMethod) {
          trackMfaCancelled({ mfaMethod: selectedMfaMethod })
        }
      }
    }
    return undefined
  }
  const handleBackButton = determineBackAction()

  const handleCloseButton = () => {
    if (selectedMfaMethod) {
      cancelMfa()
      trackMfaCancelled({ mfaMethod: selectedMfaMethod })
    }
    closeModal()
  }

  return (
    <>
      {handleBackButton ? (
        <IconButton
          aria-label={t("modalHeader.back", "Back")}
          className="absolute left-3 top-3"
          onClick={handleBackButton}
        >
          <ChevronLeft />
        </IconButton>
      ) : (
        <IconButton
          aria-label={t("close", "Close")}
          className="absolute right-3 top-3"
          onClick={handleCloseButton}
        >
          <Close />
        </IconButton>
      )}
      <CenterAligned className="p-10 pt-16">
        <Text.Heading className="text-center" size="medium">
          {t("mfaRequest.mfaRequired", "Authentication required to proceed")}
        </Text.Heading>
      </CenterAligned>
      {!selectedMfaMethod ? (
        <MfaRequestMethodSelection
          mfaMethods={mfaMethods}
          onContinue={setSelectedMfaMethod}
        />
      ) : (
        <MfaRequestCodeInput
          initMfa={initMfa}
          mfaMethod={selectedMfaMethod}
          onComplete={onPrevious ?? closeModal}
          submitMfa={submitMfa}
        />
      )}
    </>
  )
}
