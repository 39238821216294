import React from "react"

type Props = {
  className?: string
  width?: number
}

export function KlaytnLogo({ className, width = 20 }: Props) {
  return (
    <svg
      className={className}
      fill="none"
      height="24"
      style={{ width, height: width }}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9724 5.95801L12.9745 11.9133L18.9724 17.8658C21.9328 14.4592 21.9328 9.36464 18.9724 5.95801Z"
        fill="currentColor"
      />
      <path
        d="M11.985 21L17.7743 18.5992L11.985 12.8525L6.19666 18.5992L11.985 21Z"
        fill="currentColor"
      />
      <path
        d="M12.4666 3L7.20337 15.7014L11.4907 11.4168L17.7725 5.17857L12.4666 3Z"
        fill="currentColor"
      />
      <path
        d="M10.3815 4.54102L2.81995 12.0635C2.85078 14.146 3.58816 16.1286 4.91689 17.7177L10.3815 4.54102Z"
        fill="currentColor"
      />
    </svg>
  )
}
