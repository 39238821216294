import { useEffect, useState } from "react"
import type { Hex } from "viem"
import type { TransactionParams } from "@/features/rpc/types"
import { useChains } from "@/hooks/useChains"
import { bn } from "@/lib/helpers/numberUtils"
import type { BigNumber } from "@/lib/helpers/numberUtils"
import type { GasPriceParams } from "@/features/rpc/useSubmitBlockchainTransaction"
import { useSubmitBlockchainTransaction } from "@/features/rpc/useSubmitBlockchainTransaction"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useConnectedChain } from "../Wallet/selectors"

export type GasFeeEstimate = {
  fee: BigNumber
  gas?: Hex
  price?: GasPriceParams
}

const REFRESH_FEE_INTERVAL = 15_000 // 15 seconds

export const useGasFeeEstimate = (params: TransactionParams) => {
  const { getGasLimit, getGasPriceParams } = useSubmitBlockchainTransaction()
  const chain = useConnectedChain() ?? "ETHEREUM"
  const { getNativeCurrencyDecimals } = useChains()
  const [gas, setGas] = useState<Hex>()
  const [fee, setFee] = useState<BigNumber>(bn(0))
  const [price, setPrice] = useState<GasPriceParams>()
  const [isLoading, setIsLoading] = useState(false)

  useMountEffect(() => {
    const getGas = async () => {
      setGas(await getGasLimit(params))
    }
    void getGas()
  })

  useEffect(() => {
    const refreshFee = async () => {
      setIsLoading(true)
      const gasPriceParams = await getGasPriceParams()
      const currentPrice = bn(
        gasPriceParams.type === "eip1559"
          ? gasPriceParams.maxFeePerGas
          : gasPriceParams.gasPrice,
      )
      const { type: _, ...rest } = gasPriceParams
      setPrice(rest)
      const gasFee = bn(gas || 0).times(currentPrice)
      const decimals = getNativeCurrencyDecimals(chain)
      setFee(gasFee.shiftedBy(-decimals))
      setIsLoading(false)
    }
    void refreshFee()

    const refreshFeeIntervalId = setInterval(refreshFee, REFRESH_FEE_INTERVAL)

    return () => {
      clearInterval(refreshFeeIntervalId)
    }
  }, [chain, gas, getGasPriceParams, getNativeCurrencyDecimals, params])

  return {
    gasFee: {
      gas,
      fee,
      price,
    },
    isLoading,
  } as const
}
