import { useTranslate } from "@opensea/next-translate"
import { classNames } from "@opensea/ui-kit"
import { Error } from "@opensea/ui-kit/icons"
import React from "react"
import { IS_TESTNET } from "@/constants/testnet"
import { Button } from "@/design-system/Button"
import { Text } from "@/design-system/Text"
import { useChains } from "@/hooks/useChains"
import {
  getCounterpartNetworkChain,
  isChainOnMatchingNetwork,
} from "@/hooks/useChains/utils"
import { useConnectedChain } from "../Wallet/selectors"
import { useWalletActions } from "../Wallet/useWalletActions"

type Props = {
  children: React.ReactNode
}

/**
 * Conditional wrapper to protect children from making requests to BE with incompatible (testnet/mainnet) networks
 * @returns an error display if networks are incompatible (e.g. opensea.io + testnet connection) or children as normal
 */
export function IncompatibleNetworkGate({ children }: Props) {
  const t = useTranslate("common")
  const { getChain, getChainName } = useChains()
  const chain = useConnectedChain()
  const isIncompatibleNetwork =
    chain && !isChainOnMatchingNetwork(getChain(chain))
  const { switchChain } = useWalletActions()
  const contentVars = {
    page: IS_TESTNET ? t("test", "test") : t("main", "main"),
    connected: IS_TESTNET ? t("main", "main") : t("test", "test"),
  }

  if (isIncompatibleNetwork) {
    // TODO: should reuse global error component?
    return (
      <div
        className={classNames(
          "flex flex-col items-center justify-center text-center",
          "h-screen w-screen gap-4 px-10",
          "bg-white dark:bg-gray-3",
        )}
      >
        <Error size={48} />
        <Text>
          {t(
            "incompatibleNetwork",
            "You're viewing data from the {{page}} network, but your wallet is connected to the {{wallet}} network ({{chain}}). To use OpenSea, please switch networks.",
            {
              page: contentVars.page,
              wallet: contentVars.connected,
              chain: getChainName(chain),
            },
          )}
        </Text>
        <Button
          onClick={async () => {
            await switchChain(getCounterpartNetworkChain(chain))
          }}
        >
          {t("switchNetworks", "Switch networks")}
        </Button>
      </div>
    )
  }

  return children
}
