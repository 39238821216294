import { useTranslate } from "@opensea/next-translate"
import { FlexColumn, Flex } from "@opensea/ui-kit"
import { RadioButtonGroup } from "@opensea/ui-kit/client"
import { PhoneAndroid, VerifiedUser } from "@opensea/ui-kit/icons"
import type { MfaMethod } from "@privy-io/react-auth"
import React, { useState } from "react"
import { Button } from "@/design-system/Button"
import { Text } from "@/design-system/Text"

type Props = {
  mfaMethods: MfaMethod[]
  onContinue: (mfaMethod: MfaMethod) => void
}

export function MfaRequestMethodSelection({ mfaMethods, onContinue }: Props) {
  const t = useTranslate("common")

  const [selectedMfaMethod, setSelectedMfaMethod] = useState<MfaMethod>(
    mfaMethods[0],
  )

  return (
    <>
      <FlexColumn className="flex-1 items-center gap-4 px-4 text-center">
        <Text.Body>
          {t(
            "mfaRequest.selectMfaMethodDescription",
            "Select a method to receive a one-time authentication code",
          )}
        </Text.Body>
        <RadioButtonGroup
          className="w-full"
          onValueChange={value => {
            setSelectedMfaMethod(value as MfaMethod)
          }}
          value={selectedMfaMethod}
        >
          <RadioButtonGroup.Item id="sms" value="sms">
            <RadioButtonGroup.Item.Icon value={PhoneAndroid} />
            <RadioButtonGroup.Item.Content>
              <RadioButtonGroup.Item.Title>
                {t("mfaRequest.sms.label", "SMS")}
              </RadioButtonGroup.Item.Title>
              <RadioButtonGroup.Item.Description>
                {t(
                  "mfaRequest.smsDescription",
                  "Receive 6 digit code via text",
                )}
              </RadioButtonGroup.Item.Description>
            </RadioButtonGroup.Item.Content>
          </RadioButtonGroup.Item>
          <RadioButtonGroup.Item id="totp" value="totp">
            <RadioButtonGroup.Item.Icon value={VerifiedUser} />
            <RadioButtonGroup.Item.Content>
              <RadioButtonGroup.Item.Title>
                {t("mfaRequest.totp.label", "Authenticator")}
              </RadioButtonGroup.Item.Title>
              <RadioButtonGroup.Item.Description>
                {t("mfaRequest.totpDescription", "Most secure 2FA option")}
              </RadioButtonGroup.Item.Description>
            </RadioButtonGroup.Item.Content>
          </RadioButtonGroup.Item>
        </RadioButtonGroup>
      </FlexColumn>
      <Flex className="p-4">
        <Button
          className="w-full"
          onClick={() => {
            onContinue(selectedMfaMethod)
          }}
        >
          {t("mfaRequest.continue", "Continue")}
        </Button>
      </Flex>
    </>
  )
}
