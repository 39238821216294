import { useCallback } from "react"
import { trackAmplitudeEvent } from "./amplitude"
import { useTrackingContext } from "./TrackingContext/TrackingContext.react"
import type { EventProperties } from "./types"

/**
 * Get a strongly typed tracking function with event name preset (no properties)
 * This tracking function handles adding contexts to event properties
 * @param eventName - Event name to use for tracking
 * @returns Strongly typed tracking function with event name preset
 */
export function useTrackingFn<_T extends undefined>(
  eventName: string,
): () => void

/**
 * Get a strongly typed tracking function with properties and with event name preset
 * This tracking function handles adding contexts to event properties
 * @param eventName - Event name to use for tracking
 * @returns Strongly typed tracking function with properties and with event name preset
 */
export function useTrackingFn<T extends EventProperties>(
  eventName: string,
): (properties: T) => void

// implementation
export function useTrackingFn(
  eventName: string,
): (properties?: EventProperties) => void {
  const { getMergedTrackingContext } = useTrackingContext()

  const trackingFn = useCallback(
    (properties?: EventProperties) => {
      void trackAmplitudeEvent(eventName, {
        ...properties,
        contexts: getMergedTrackingContext(),
      })
    },
    [eventName, getMergedTrackingContext],
  )
  return trackingFn
}
