/**
 * @generated SignedSource<<72e8c3fccd0a7761cfcd71cc1fcee8d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AssetTokenStandard = "AUCTION_HOUSE" | "BITKEEP" | "BLUR" | "CONSIDERATION" | "CRYPTOKITTIES" | "CRYPTOPUNKS" | "CUSTOM" | "DECENTRALAND" | "ELEMENT" | "ENJIN_LEGACY" | "ERC1155" | "ERC20" | "ERC721" | "ETH_BRIDGE" | "JOEPEGS" | "KNOWNORIGIN" | "LOOKSRARE" | "MAKERSPLACE" | "METAPLEX" | "SEADROP" | "SOLANA" | "SPL" | "SUPERRARE" | "TOFU" | "TRANSFER_HELPER" | "TROVE" | "UNKNOWN" | "WYVERN" | "X2Y2" | "ZERO_EX" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useTransactionNativeCurrencyTotal_metadata$data = {
  readonly sentAssetQuantities: ReadonlyArray<{
    readonly asset: {
      readonly symbol: string | null | undefined;
      readonly tokenStandard: AssetTokenStandard | null | undefined;
    };
    readonly quantity: string;
  }>;
  readonly " $fragmentType": "useTransactionNativeCurrencyTotal_metadata";
};
export type useTransactionNativeCurrencyTotal_metadata$key = {
  readonly " $data"?: useTransactionNativeCurrencyTotal_metadata$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTransactionNativeCurrencyTotal_metadata">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useTransactionNativeCurrencyTotal_metadata",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetQuantityType",
      "kind": "LinkedField",
      "name": "sentAssetQuantities",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetType",
          "kind": "LinkedField",
          "name": "asset",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tokenStandard",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "symbol",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quantity",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TransactionMetadataType",
  "abstractKey": null
};

(node as any).hash = "35a342c27ff72f778f995c73d0de996f";

export default node;
