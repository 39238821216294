import { keyBy } from "lodash"
import { useCallback, useMemo } from "react"
import { isCanonicalChainIdentifier } from "@/lib/helpers/chainUtils"
import { keys } from "@/lib/helpers/object"
import { useChainContext } from "./context"
import type { Chain, ChainIdentifier } from "./types"
import {
  createChainByIdentifierLookup,
  isChainOnMatchingNetwork,
} from "./utils"

export const useChains = () => {
  const { chains: allKnownChains } = useChainContext()

  const identifierToChain = useMemo(
    () => createChainByIdentifierLookup(allKnownChains),
    [allKnownChains],
  )

  const networkIdToChain = useMemo(
    () => keyBy(allKnownChains, "networkId"),
    [allKnownChains],
  )

  const getChain = useCallback(
    (chain: ChainIdentifier) => identifierToChain[chain],
    [identifierToChain],
  )

  const getChainByNetworkId = useCallback(
    (networkId: string) => networkIdToChain[networkId],
    [networkIdToChain],
  )

  const _isChainOnMatchingNetwork = useCallback(
    (chain: ChainIdentifier) => isChainOnMatchingNetwork(getChain(chain)),
    [getChain],
  )

  const _isChainTradingEnabled = useCallback(
    (chain: ChainIdentifier) => getChain(chain).isTradingEnabled,
    [getChain],
  )

  const chainIdentifiers = useMemo(
    () => keys(identifierToChain),
    [identifierToChain],
  )

  const isChainEnabled = useCallback(
    (chain: ChainIdentifier) =>
      _isChainOnMatchingNetwork(chain) && _isChainTradingEnabled(chain),
    [_isChainOnMatchingNetwork, _isChainTradingEnabled],
  )

  const enabledChainIdentifiers = useMemo(
    () => chainIdentifiers.filter(chain => isChainEnabled(chain)),
    [chainIdentifiers, isChainEnabled],
  )

  const isChainSupportingMintingTool = useCallback(
    (chainIdentifier: ChainIdentifier) => {
      return getChain(chainIdentifier).isMintingToolSupported
    },
    [getChain],
  )

  const getChainName = useCallback(
    (chain: ChainIdentifier) => getChain(chain).displayName,
    [getChain],
  )

  const getTransactionUrl = useCallback(
    (chain: ChainIdentifier, hash: string) => {
      return getChain(chain).blockExplorer.transactionUrlTemplate.replace(
        ":hash",
        hash,
      )
    },
    [getChain],
  )

  const getBlockExplorerAddressUrl = useCallback(
    (chain: ChainIdentifier, address: string) => {
      return getChain(chain).blockExplorer.addressUrlTemplate.replace(
        ":address",
        address,
      )
    },
    [getChain],
  )

  const getBlockExplorerName = useCallback(
    (chain: ChainIdentifier) => {
      return getChain(chain).blockExplorer.name
    },
    [getChain],
  )

  const getWebsiteUrl = useCallback(
    (chain: ChainIdentifier) => getChain(chain).websiteUrl,
    [getChain],
  )

  const getChainLogo = useCallback(
    (chain: ChainIdentifier) => getChain(chain).logo,
    [getChain],
  )

  const isEvmChain = useCallback(
    (chain: ChainIdentifier) => getChain(chain).isEvm,
    [getChain],
  )

  const getCanonicalChainIdentifier = useCallback(
    (chain: ChainIdentifier) => getChain(chain).canonicalChain,
    [getChain],
  )

  const isChainFiltersEnabled = useCallback(
    (chainIdentifier: ChainIdentifier) => {
      const chainInfo = getChain(chainIdentifier)
      return chainInfo.showFilters && chainInfo.isEvm
    },
    [getChain],
  )

  const getWrappedCurrencySymbol = useCallback(
    (chain: ChainIdentifier) => getChain(chain).wrappedCurrency.symbol,
    [getChain],
  )

  const getNativeCurrencySymbol = useCallback(
    (chain: ChainIdentifier) => getChain(chain).nativeCurrency.symbol,
    [getChain],
  )

  const getNativeCurrencyDecimals = useCallback(
    (chain: ChainIdentifier) => getChain(chain).nativeCurrency.decimals,
    [getChain],
  )

  const getNativeCurrencyUsdPrice = useCallback(
    (chain: ChainIdentifier) =>
      getChain(chain).nativeCurrencyPaymentAsset?.usdPrice ?? 1,
    [getChain],
  )

  return {
    chains: enabledChainIdentifiers,
    isChainEnabled,
    isChainSupportingMintingTool,
    getChainName,
    getTransactionUrl,
    getBlockExplorerAddressUrl,
    getBlockExplorerName,
    getWebsiteUrl,
    getChainLogo,
    getChain,
    getChainByNetworkId,
    isEvmChain,
    getCanonicalChainIdentifier,
    isChainFiltersEnabled,
    getWrappedCurrencySymbol,
    getNativeCurrencySymbol,
    getNativeCurrencyDecimals,
    getNativeCurrencyUsdPrice,
  }
}

export const useCurrencyDescription = (
  symbol: string,
  chain: ChainIdentifier,
) => {
  const { getChainName } = useChains()
  return symbol
    ? `${symbol}${
        !isCanonicalChainIdentifier(chain) ? ` on ${getChainName(chain)}` : ""
      }`
    : "Unknown currency"
}

export const walletAddEthereumChainParameters = (
  chainId: string,
  chain: Chain,
) => {
  return {
    chainId,
    rpcUrls: [chain.publicRpcUrl],
    chainName: chain.displayName,
    nativeCurrency: {
      name: chain.nativeCurrency.name,
      symbol: chain.nativeCurrency.symbol,
      decimals: chain.nativeCurrency.decimals,
    },
    blockExplorerUrls: [chain.blockExplorer.url],
  }
}
