import React from "react"
import { useBasicHandler } from "./handlers/useBasicHandler.react"
import { usePersonalSignHandler } from "./handlers/usePersonalSignHandler.react"
import { useSendTransactionHandler } from "./handlers/useSendTransactionHandler.react"
import { useSignTypedDataHandler } from "./handlers/useSignTypedDataHandler.react"
import { useSwitchChainHandler } from "./handlers/useSwitchChainHandler.react"
import { RPCMessageHandler } from "./RPCMessageHandler.react"

export const SEND_TRANSACTION_METHODS = ["eth_sendTransaction"]
export const PERSONAL_SIGN_METHODS = ["personal_sign"]
export const SIGN_TYPED_DATA_METHODS = ["eth_signTypedData_v4"]
export const SWITCH_CHAIN_METHODS = [
  "wallet_addEthereumChain",
  "wallet_switchEthereumChain",
]
export const BASIC_METHODS = [
  "eth_accounts",
  "eth_blockNumber",
  "eth_chainId",
  "eth_estimateGas",
  "eth_gasPrice",
  "eth_getBalance",
  "eth_getBlockByNumber",
  "eth_getTransactionByHash",
  "eth_getTransactionCount",
  "eth_getTransactionReceipt",
  "eth_requestAccounts",
]

export const ALL_METHODS = [
  ...SEND_TRANSACTION_METHODS,
  ...PERSONAL_SIGN_METHODS,
  ...SIGN_TYPED_DATA_METHODS,
  ...SWITCH_CHAIN_METHODS,
  ...BASIC_METHODS,
]

export function RPCHandlers() {
  const sendTransactionHandler = useSendTransactionHandler()
  const personalSignHandler = usePersonalSignHandler()
  const signTypedDataHandler = useSignTypedDataHandler()
  const switchChainHandler = useSwitchChainHandler()
  const basicHandler = useBasicHandler()

  return (
    <>
      <RPCMessageHandler
        handler={sendTransactionHandler}
        methods={SEND_TRANSACTION_METHODS}
      />
      <RPCMessageHandler
        handler={personalSignHandler}
        methods={PERSONAL_SIGN_METHODS}
      />
      <RPCMessageHandler
        handler={signTypedDataHandler}
        methods={SIGN_TYPED_DATA_METHODS}
      />
      <RPCMessageHandler
        handler={switchChainHandler}
        methods={SWITCH_CHAIN_METHODS}
      />
      <RPCMessageHandler handler={basicHandler} methods={BASIC_METHODS} />
    </>
  )
}
