import * as sidecar from "@/lib/sidecar/config"
import type { Environment } from "./schema"

const getEnvironmentFromNodeEnv = (): Environment => {
  switch (process.env.NODE_ENV) {
    case "development":
      return "development"
    case "test":
      return "test:unit"
    case "production":
      return "production"
    default:
      return "development"
  }
}

export const getEnvironment = (): Environment => {
  const sidecarEnvironment = sidecar.getValue("environment")
  if (sidecarEnvironment) {
    return sidecarEnvironment
  }
  return getEnvironmentFromNodeEnv()
}
