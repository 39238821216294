import { useCallback } from "react"
import { graphql } from "react-relay"
import { captureException } from "@sentry/nextjs"
import { useMutation } from "@/lib/graphql/useMutation"
import type { useAddConnectedDomainMutation } from "@/lib/graphql/__generated__/useAddConnectedDomainMutation.graphql"
import { PRIVY_APP_ID } from "@/constants/privy"

export const useAddConnectedDomain = () => {
  const [commitFn] = useMutation<useAddConnectedDomainMutation>(graphql`
    mutation useAddConnectedDomainMutation(
      $domain: String!
      $privyAccessToken: String!
      $privyAppId: String!
    ) {
      embeddedWallets {
        addConnectedDomain(
          domain: $domain
          privyAccessToken: $privyAccessToken
          privyAppId: $privyAppId
        )
      }
    }
  `)

  const addConnectedDomain = useCallback(
    async (domain: string, privyAccessToken: string) => {
      await commitFn({
        variables: {
          domain,
          privyAccessToken,
          privyAppId: PRIVY_APP_ID,
        },
      }).catch((error): void => {
        captureException(error, { extra: { domain } })
      })
    },
    [commitFn],
  )

  return addConnectedDomain
}
