import type { MessagePayloadType } from "@opensea/vessel-0.0"
import React, { useCallback } from "react"
import { captureException } from "@sentry/nextjs"
import type { PersonalSignParams } from "@/features/rpc/types"
import { useGlobalModal } from "@/providers/GlobalModal"
import { useVesselActions } from "@/providers/Vessel00/useVesselActions"
import { PersonalSignConfirmationModal } from "../../PersonalSignConfirmationModal/PersonalSignConfirmationModal.react"
import type { RPCMessageHandlerFn } from "../RPCMessageHandler.react"

export const usePersonalSignHandler = (): RPCMessageHandlerFn => {
  const { showWallet, hideWallet, setClickAway } = useVesselActions()
  const { openModal } = useGlobalModal()

  const handler: RPCMessageHandlerFn = useCallback(
    (message, reply) => {
      openModal(
        <PersonalSignConfirmationModal
          onError={error => {
            reply({ error } as MessagePayloadType)
            captureException(error)
            void hideWallet()
            void setClickAway(true)
          }}
          onSuccess={result => {
            reply(result as MessagePayloadType)
            void hideWallet()
            void setClickAway(true)
          }}
          params={message.payload.params as PersonalSignParams}
        />,
        { closeable: false },
      )
      void showWallet()
      void setClickAway(false)
    },
    [hideWallet, openModal, setClickAway, showWallet],
  )
  return handler
}
