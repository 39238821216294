/* eslint-disable relay/unused-fields */
import { useCallback } from "react"
import { useRelayEnvironment } from "react-relay"
import { fetchQuery, graphql } from "relay-runtime"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import type { useResolvePaymentAssetQuery } from "@/lib/graphql/__generated__/useResolvePaymentAssetQuery.graphql"

export const useResolvePaymentAsset = () => {
  const environment = useRelayEnvironment()
  const resolvePaymentAsset = useCallback(
    async (symbol: string, chain?: ChainIdentifier) => {
      const data = await fetchQuery<useResolvePaymentAssetQuery>(
        environment,
        graphql`
          query useResolvePaymentAssetQuery(
            $symbol: String!
            $chain: ChainScalar
          ) {
            paymentAsset(symbol: $symbol, chain: $chain) {
              address
              decimals
            }
          }
        `,
        { symbol, chain },
      ).toPromise()

      if (!data) {
        throw new Error("No payment asset found")
      }

      return data
    },
    [environment],
  )

  return { resolvePaymentAsset }
}
