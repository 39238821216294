import type { MessagePayloadType } from "@opensea/vessel-0.0"
import { useRouter } from "next/router"
import React, { useCallback } from "react"
import { hexToBigInt } from "viem"
import { captureException } from "@sentry/nextjs"
import { useChains } from "@/hooks/useChains"
import { useGlobalModal } from "@/providers/GlobalModal"
import { useVesselActions } from "@/providers/Vessel00/useVesselActions"
import { TransactionConfirmationModal } from "../../TransactionConfirmationModal/TransactionConfirmationModal.react"
import type { TransactionParams } from "../../types"
import { useCreateRequestedTransaction } from "../../useCreateRequestedTransaction"
import { useUpdateUserTransactionState } from "../../useUpdateTransactionStatusPending"
import type { RPCMessageHandlerFn } from "../RPCMessageHandler.react"
import { USER_REJECTED_REQUEST_ERROR } from "../../constants"

export const useSendTransactionHandler = (): RPCMessageHandlerFn => {
  const { showWallet, hideWallet, setClickAway } = useVesselActions()
  const { getChainByNetworkId } = useChains()
  const { openModal } = useGlobalModal()
  const router = useRouter()

  const createRequestedTransaction = useCreateRequestedTransaction()
  const updateUserTransactionState = useUpdateUserTransactionState()

  const handler: RPCMessageHandlerFn = useCallback(
    async (message, reply, wallet) => {
      const chain = getChainByNetworkId(wallet.chainId.split(":")[1]).identifier
      const txParams = message.payload.params as TransactionParams

      const apiParams = {
        calldata: txParams[0].data ?? "",
        chain,
        fromAddress: txParams[0].from,
        toAddress: txParams[0].to,
        value: txParams[0].value
          ? hexToBigInt(txParams[0].value).toString()
          : undefined,
      }
      const userTransactionId = await createRequestedTransaction(apiParams)

      openModal(
        <TransactionConfirmationModal
          chain={chain}
          onError={error => {
            reply({ error } as MessagePayloadType)
            if (
              (error as Error).message !== USER_REJECTED_REQUEST_ERROR.message
            ) {
              captureException(error, {
                extra: apiParams,
              })
            }
            void hideWallet()
            void setClickAway(true)
          }}
          onSuccess={async transactionHash => {
            reply(transactionHash)
            void hideWallet()
            void setClickAway(true)
            await updateUserTransactionState({
              userTransactionId,
              transactionHash,
              state: "PENDING",
            })
            await router.push("/transactions?refresh=true")
          }}
          params={txParams}
        />,
        { closeable: false },
      )
      void showWallet()
      void setClickAway(false)
    },
    [
      createRequestedTransaction,
      getChainByNetworkId,
      hideWallet,
      openModal,
      router,
      setClickAway,
      showWallet,
      updateUserTransactionState,
    ],
  )
  return handler
}
