import { classNames } from "@opensea/ui-kit"
import React from "react"
import { Text } from "@/design-system/Text"

type Props = {
  children: React.ReactNode
}

export function ConfirmationModalTitle({ children }: Props) {
  return (
    <div className={classNames("my-10 flex justify-center")}>
      <Text.Heading size="medium">{children}</Text.Heading>
    </div>
  )
}
