import type { MfaMethod } from "@privy-io/react-auth"
import { useTrackingFn } from "@/lib/analytics/useTrackingFn"

export const useMfaRequestTracking = () => {
  return {
    trackMfaRequested: useTrackingFn<{ mfaMethods: MfaMethod[] }>(
      "mfa requested",
    ),
    trackMfaInitialized: useTrackingFn<{ mfaMethod: MfaMethod }>(
      "mfa initialized",
    ),
    trackMfaFailedInitialize: useTrackingFn<{
      mfaMethod: MfaMethod
      error: string
    }>("mfa failed initialize"),
    trackMfaSubmitted: useTrackingFn<{ mfaMethod: MfaMethod }>("mfa submitted"),
    trackMfaFailed: useTrackingFn<{
      mfaMethod: MfaMethod
      error: string
    }>("mfa failed"),
    trackResendMfaCode: useTrackingFn<{ mfaMethod: MfaMethod }>(
      "mfa resend code",
    ),
    trackMfaCancelled: useTrackingFn<{ mfaMethod: MfaMethod }>("mfa cancelled"),
  }
}
