import { merge } from "lodash"
import React, { createContext, useCallback, useContext, useRef } from "react"
import type { TrackingContextType, TrackingContextName } from "./types"
import { getDefaultTrackingContext } from "./utils"

const TrackingContext = createContext<{
  getMergedTrackingContext: () => TrackingContextType
}>({
  getMergedTrackingContext: getDefaultTrackingContext,
})

type TrackingContextProviderProps<T extends TrackingContextName> = {
  name: T
  properties: TrackingContextType[T]
  children: React.ReactNode
}

export function TrackingContextProvider<T extends TrackingContextName>({
  name,
  properties,
  children,
}: TrackingContextProviderProps<T>) {
  const { getMergedTrackingContext: getCurrentTrackingContext } =
    useTrackingContext()

  // Use refs so that getMergedTrackingContext always keeps the same reference
  const propertiesRef = useRef<TrackingContextType[T]>(properties)
  propertiesRef.current = properties

  const nameRef = useRef<T>(name)
  nameRef.current = name

  const getMergedTrackingContext: () => TrackingContextType =
    useCallback(() => {
      const currentTrackingContext = getCurrentTrackingContext()

      return {
        ...currentTrackingContext,
        [nameRef.current]: merge(
          {},
          currentTrackingContext[nameRef.current],
          propertiesRef.current,
        ),
      }
    }, [getCurrentTrackingContext])

  return (
    <TrackingContext.Provider value={{ getMergedTrackingContext }}>
      {children}
    </TrackingContext.Provider>
  )
}

export const useTrackingContext = () => {
  return useContext(TrackingContext)
}
