import { createContext } from "use-context-selector"
import type { OSNextData } from "@/lib/os-next-data/types"

type OSNextDataContextType = OSNextData

export const DEFAULT_OS_NEXT_DATA: OSNextData = {
  activeAccount: undefined,
  activeChain: undefined,
  installedWallets: [],
  theme: undefined,
  deviceId: undefined,
}

const DEFAULT_OS_NEXT_DATA_CONTEXT: OSNextDataContextType = DEFAULT_OS_NEXT_DATA

export const OSNextDataContext = createContext<OSNextDataContextType>(
  DEFAULT_OS_NEXT_DATA_CONTEXT,
)
