import React, { useMemo } from "react"
import type { WALLET_NAME } from "@/constants/wallet"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import {
  useConnectedAddress,
  useConnectedChain,
  useConnectedWallet,
  useInstalledWallets,
} from "@/providers/Wallet/selectors"
import { TrackingContextProvider } from "../TrackingContext.react"

type LockedWalletContext = {
  connected: false
  walletsPresent: string[]
}

type ConnectedWalletContext = {
  connected: true
  walletsPresent: string[]
  address: string
  chain: ChainIdentifier
  name: WALLET_NAME
}

type WalletContext = LockedWalletContext | ConnectedWalletContext

export type WalletTrackingContext = {
  Wallet: WalletContext | undefined // undefined when no wallet is detected
}

type WalletTrackingContextProviderProps = {
  children: React.ReactNode
}

export function WalletTrackingContextProvider({
  children,
}: WalletTrackingContextProviderProps) {
  const connectedAddress = useConnectedAddress()
  const connectedChain = useConnectedChain()
  const connectedWallet = useConnectedWallet()
  const installedWallets = useInstalledWallets()

  const properties: WalletContext | undefined = useMemo(() => {
    if (!connectedAddress || !connectedChain || !connectedWallet) {
      return {
        connected: false,
        walletsPresent: installedWallets,
      }
    }

    return {
      connected: true,
      walletsPresent: installedWallets,
      address: connectedAddress,
      chain: connectedChain,
      name: connectedWallet,
    }
  }, [connectedAddress, connectedChain, connectedWallet, installedWallets])

  return (
    <TrackingContextProvider name="Wallet" properties={properties}>
      {children}
    </TrackingContextProvider>
  )
}
