import { v4 as uuid } from "uuid"
import { APP_NAME } from "@/constants/env"

type MetaContext = {
  loggedAt: number
  internalEventId: string
  appName: string
}

export type MetaTrackingContext = {
  Meta: MetaContext
}

export const getMetaContext = (): MetaContext => {
  return {
    loggedAt: Date.now(),
    internalEventId: uuid(),
    appName: APP_NAME,
  }
}
