import React, { useState } from "react"
import { createContext } from "use-context-selector"
import { useMountEffect } from "@/hooks/useMountEffect"

type KeyboardNavigationContextType = {
  keyboardNavigationActive: boolean
}

const DEFAULT_KEYBOARD_NAVIGATION_CONTEXT: KeyboardNavigationContextType = {
  keyboardNavigationActive: false,
}

export const KeyboardNavigationContext =
  createContext<KeyboardNavigationContextType>(
    DEFAULT_KEYBOARD_NAVIGATION_CONTEXT,
  )

export function KeyboardNavigationProvider(props: {
  children: React.ReactNode
}) {
  const { children } = props
  const [keyboardNavigationActive, setKeyboardNavigationActive] =
    useState(false)

  useMountEffect(() => {
    const { body } = document

    function handleKeyup(e: KeyboardEvent): void {
      if (e.code === "Tab") {
        setKeyboardNavigationActive(true)
      }
    }

    function handleClick(): void {
      setKeyboardNavigationActive(false)
    }

    body.addEventListener("keydown", handleKeyup)
    body.addEventListener("mousedown", handleClick)

    return () => {
      body.removeEventListener("keydown", handleKeyup)
      body.removeEventListener("mousedown", handleClick)
    }
  })

  return (
    <KeyboardNavigationContext.Provider value={{ keyboardNavigationActive }}>
      {children}
    </KeyboardNavigationContext.Provider>
  )
}
