import { classNames } from "@opensea/ui-kit"
import Image from "next/image"
import React from "react"
import { graphql, useFragment } from "react-relay"
import type { StackedAssetMedia_assets$key } from "@/lib/graphql/__generated__/StackedAssetMedia_assets.graphql"
import { PLACEHOLDER_IMAGE, SIZE_TO_PX } from "../constants"
import { containedMediaClassNames } from "../styles"
import type { Size } from "../types"

export type StackedAssetMediaProps = {
  assets: StackedAssetMedia_assets$key
  size?: Size
}

export function StackedAssetMedia({
  assets: assetsDataKey,
  size = "md",
}: StackedAssetMediaProps) {
  const assets = useFragment(
    graphql`
      fragment StackedAssetMedia_assets on AssetType @relay(plural: true) {
        displayImageUrl
      }
    `,
    assetsDataKey,
  )

  const sizePx = SIZE_TO_PX[size]

  return assets[1] ? (
    <div className="relative" style={{ width: sizePx, height: sizePx }}>
      <Image
        alt=""
        className={classNames(
          containedMediaClassNames,
          "absolute -top-1 left-1",
        )}
        height={sizePx - 8}
        src={assets[1].displayImageUrl ?? PLACEHOLDER_IMAGE}
        width={sizePx - 8}
      />
      <div className="absolute -inset-0.5 rounded-lg bg-elevation-2 p-0.5">
        <Image
          alt=""
          className={classNames(containedMediaClassNames)}
          height={sizePx}
          src={assets[0].displayImageUrl ?? PLACEHOLDER_IMAGE}
          width={sizePx}
        />
      </div>
    </div>
  ) : (
    <Image
      alt=""
      className={classNames(containedMediaClassNames)}
      height={sizePx}
      src={assets[0]?.displayImageUrl ?? PLACEHOLDER_IMAGE}
      width={sizePx}
    />
  )
}
