/**
 * @generated SignedSource<<26a0325c8acbd37fc4d2d69288a8738a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useCreateRequestedTransactionMutation$variables = {
  aggregator?: string | null | undefined;
  calldata: string;
  chain: | "ETHEREUM" | "MATIC" | "KLAYTN" | "BSC" | "SOLANA" | "ARBITRUM" | "ARBITRUM_NOVA" | "AVALANCHE" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "AMOY" | "BAOBAB" | "BSC_TESTNET" | "SOLDEV" | "ARBITRUM_SEPOLIA" | "AVALANCHE_FUJI" | "SEPOLIA" | "BASE" | "BASE_SEPOLIA" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "BLAST_SEPOLIA" | "BLAST" | "GUNZILLA_TESTNET" | "SEI" | "SEI_TESTNET" | "%future added value";
  destinationChain?: | "ETHEREUM" | "MATIC" | "KLAYTN" | "BSC" | "SOLANA" | "ARBITRUM" | "ARBITRUM_NOVA" | "AVALANCHE" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "AMOY" | "BAOBAB" | "BSC_TESTNET" | "SOLDEV" | "ARBITRUM_SEPOLIA" | "AVALANCHE_FUJI" | "SEPOLIA" | "BASE" | "BASE_SEPOLIA" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "BLAST_SEPOLIA" | "BLAST" | "GUNZILLA_TESTNET" | "SEI" | "SEI_TESTNET" | "%future added value" | null | undefined;
  expectedBridgeCompletionTime?: number | null | undefined;
  fromAddress: string;
  nonce: number;
  receivedAssetQuantity?: string | null | undefined;
  receivedAssetSymbol?: string | null | undefined;
  sentAssetQuantity?: string | null | undefined;
  sentAssetSymbol?: string | null | undefined;
  toAddress?: string | null | undefined;
  transactionHash?: string | null | undefined;
  value?: string | null | undefined;
};
export type useCreateRequestedTransactionMutation$data = {
  readonly userTransaction: {
    readonly request: {
      readonly relayId: string;
    };
  };
};
export type useCreateRequestedTransactionMutation = {
  response: useCreateRequestedTransactionMutation$data;
  variables: useCreateRequestedTransactionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "aggregator"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "calldata"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "chain"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "destinationChain"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "expectedBridgeCompletionTime"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fromAddress"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nonce"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "receivedAssetQuantity"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "receivedAssetSymbol"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sentAssetQuantity"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sentAssetSymbol"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "toAddress"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "transactionHash"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "value"
},
v14 = [
  {
    "kind": "Variable",
    "name": "aggregator",
    "variableName": "aggregator"
  },
  {
    "kind": "Variable",
    "name": "calldata",
    "variableName": "calldata"
  },
  {
    "kind": "Variable",
    "name": "chain",
    "variableName": "chain"
  },
  {
    "kind": "Variable",
    "name": "destinationChain",
    "variableName": "destinationChain"
  },
  {
    "kind": "Variable",
    "name": "expectedBridgeCompletionTime",
    "variableName": "expectedBridgeCompletionTime"
  },
  {
    "kind": "Variable",
    "name": "fromAddress",
    "variableName": "fromAddress"
  },
  {
    "kind": "Variable",
    "name": "nonce",
    "variableName": "nonce"
  },
  {
    "kind": "Variable",
    "name": "receivedAssetQuantity",
    "variableName": "receivedAssetQuantity"
  },
  {
    "kind": "Variable",
    "name": "receivedAssetSymbol",
    "variableName": "receivedAssetSymbol"
  },
  {
    "kind": "Variable",
    "name": "sentAssetQuantity",
    "variableName": "sentAssetQuantity"
  },
  {
    "kind": "Variable",
    "name": "sentAssetSymbol",
    "variableName": "sentAssetSymbol"
  },
  {
    "kind": "Variable",
    "name": "toAddress",
    "variableName": "toAddress"
  },
  {
    "kind": "Variable",
    "name": "transactionHash",
    "variableName": "transactionHash"
  },
  {
    "kind": "Variable",
    "name": "value",
    "variableName": "value"
  }
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateRequestedTransactionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserTransactionMutationType",
        "kind": "LinkedField",
        "name": "userTransaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v14/*: any*/),
            "concreteType": "UserTransactionType",
            "kind": "LinkedField",
            "name": "request",
            "plural": false,
            "selections": [
              (v15/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v11/*: any*/),
      (v6/*: any*/),
      (v13/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v12/*: any*/),
      (v4/*: any*/),
      (v8/*: any*/),
      (v7/*: any*/),
      (v10/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Operation",
    "name": "useCreateRequestedTransactionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserTransactionMutationType",
        "kind": "LinkedField",
        "name": "userTransaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v14/*: any*/),
            "concreteType": "UserTransactionType",
            "kind": "LinkedField",
            "name": "request",
            "plural": false,
            "selections": [
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ad540a5c2524e9e697dd99013642b3b0",
    "id": null,
    "metadata": {},
    "name": "useCreateRequestedTransactionMutation",
    "operationKind": "mutation",
    "text": "mutation useCreateRequestedTransactionMutation(\n  $calldata: String!\n  $chain: ChainScalar!\n  $fromAddress: AddressScalar!\n  $toAddress: AddressScalar\n  $nonce: Int!\n  $value: BigIntScalar\n  $destinationChain: ChainScalar\n  $aggregator: String\n  $transactionHash: String\n  $expectedBridgeCompletionTime: Int\n  $receivedAssetSymbol: String\n  $receivedAssetQuantity: BigIntScalar\n  $sentAssetSymbol: String\n  $sentAssetQuantity: BigIntScalar\n) {\n  userTransaction {\n    request(calldata: $calldata, chain: $chain, fromAddress: $fromAddress, toAddress: $toAddress, nonce: $nonce, value: $value, destinationChain: $destinationChain, aggregator: $aggregator, transactionHash: $transactionHash, expectedBridgeCompletionTime: $expectedBridgeCompletionTime, receivedAssetSymbol: $receivedAssetSymbol, receivedAssetQuantity: $receivedAssetQuantity, sentAssetSymbol: $sentAssetSymbol, sentAssetQuantity: $sentAssetQuantity) {\n      relayId\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "970b4ba7ca38ae42517eab891d7cc712";

export default node;
