import {
  errorIndicatesMaxMfaRetries,
  errorIndicatesMfaTimeout,
  errorIndicatesMfaVerificationFailed,
} from "@privy-io/react-auth"

type SubmitMfaErrorReason =
  | "MfaVerificationFailed"
  | "MaxMfaRetries"
  | "MfaTimeout"
  | "Unknown"

export const getSubmitMfaErrorReason = (
  error: unknown,
): SubmitMfaErrorReason => {
  if (errorIndicatesMfaVerificationFailed(error)) {
    return "MfaVerificationFailed"
  }
  if (errorIndicatesMaxMfaRetries(error)) {
    return "MaxMfaRetries"
  }
  if (errorIndicatesMfaTimeout(error)) {
    return "MfaTimeout"
  }
  return "Unknown"
}
