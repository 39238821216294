/**
 * @generated SignedSource<<432ed60215dd3389491a7f5f89089ff7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PaymentAssetMedia_asset$data = {
  readonly displayImageUrl: string | null | undefined;
  readonly " $fragmentType": "PaymentAssetMedia_asset";
};
export type PaymentAssetMedia_asset$key = {
  readonly " $data"?: PaymentAssetMedia_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"PaymentAssetMedia_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PaymentAssetMedia_asset",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayImageUrl",
      "storageKey": null
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "e726443dac1d118009f5eacc4b9114c9";

export default node;
