import { useCallback } from "react"
import type {
  PromptTransactionResponse,
  TransactionParams,
} from "@opensea/wallet-messages"
import type { MfaMethod } from "@privy-io/react-auth"
import type { WALLET_NAME } from "@/constants/wallet"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import type { TransferProps, WrapProps, UnwrapProps } from "../Wallet/types"
import { WALLET_PROMPT_VESSEL_ACTION_TIMEOUT } from "./constants"
import type { EstimateGasProps, EstimateGasResponse } from "./types"
import { isSuccessResponse } from "./utils"
import { useVessel as useVessel00 } from "./VesselProvider.react"
import { useVessel } from "../Vessel/VesselProvider"

export const useVesselActions = () => {
  const { triggerAction } = useVessel00()
  const { sendMessage } = useVessel()

  const switchChain = useCallback(
    async (chain: ChainIdentifier) => {
      await triggerAction(
        {
          type: "action",
          action: "SwitchChain",
          chain,
        },
        WALLET_PROMPT_VESSEL_ACTION_TIMEOUT,
      )
    },
    [triggerAction],
  )

  const switchWallet = useCallback(
    async (walletName: WALLET_NAME) => {
      await triggerAction(
        {
          type: "action",
          action: "SwitchWallet",
          walletName,
        },
        WALLET_PROMPT_VESSEL_ACTION_TIMEOUT,
      )
    },
    [triggerAction],
  )

  const installWallet = useCallback(
    async (walletName: WALLET_NAME) => {
      await triggerAction({
        type: "action",
        action: "InstallWallet",
        walletName,
      })
    },
    [triggerAction],
  )

  const addFunds = useCallback(
    async (chain?: ChainIdentifier, symbol?: string) => {
      await triggerAction({
        type: "action",
        action: "AddFunds",
        chain,
        symbol,
      })
    },
    [triggerAction],
  )

  const depositFunds = useCallback(
    async (chain?: ChainIdentifier, symbol?: string) => {
      await triggerAction({
        type: "action",
        action: "DepositFunds",
        chain,
        symbol,
      })
    },
    [triggerAction],
  )

  const transfer = useCallback(
    async (props: TransferProps) => {
      await triggerAction(
        {
          type: "action",
          action: "Transfer",
          ...props,
        },
        WALLET_PROMPT_VESSEL_ACTION_TIMEOUT,
      )
    },
    [triggerAction],
  )

  const wrap = useCallback(
    async (props: WrapProps) => {
      await triggerAction(
        {
          type: "action",
          action: "Wrap",
          ...props,
        },
        WALLET_PROMPT_VESSEL_ACTION_TIMEOUT,
      )
    },
    [triggerAction],
  )

  const unwrap = useCallback(
    async (props: UnwrapProps) => {
      await triggerAction(
        {
          type: "action",
          action: "Unwrap",
          ...props,
        },
        WALLET_PROMPT_VESSEL_ACTION_TIMEOUT,
      )
    },
    [triggerAction],
  )

  const estimateGas = useCallback(
    async (props: EstimateGasProps): Promise<EstimateGasResponse> => {
      const response = await triggerAction({
        type: "action",
        action: "EstimateGas",
        ...props,
      })
      if (isSuccessResponse(response)) {
        return { gasEstimate: response.gasEstimate as string }
      }
      throw new Error("Failed to estimate gas")
    },
    [triggerAction],
  )

  const refreshFunds = useCallback(async () => {
    const response = await triggerAction(
      {
        type: "action",
        action: "RefreshFunds",
      },
      WALLET_PROMPT_VESSEL_ACTION_TIMEOUT,
    )

    if (isSuccessResponse(response)) {
      return
    }

    throw new Error("Failed to refresh funds")
  }, [triggerAction])

  const setClickAway = useCallback(
    async (allowed: boolean) => {
      await triggerAction({
        type: "action",
        action: "SetClickAway",
        allowed,
      })
    },
    [triggerAction],
  )

  const showWallet = useCallback(async () => {
    await triggerAction({
      type: "action",
      action: "ShowWallet",
    })
  }, [triggerAction])

  const hideWallet = useCallback(async () => {
    await triggerAction({
      type: "action",
      action: "HideWallet",
    })
  }, [triggerAction])

  const requestLogin = useCallback(async () => {
    await triggerAction({
      type: "action",
      action: "RequestLogin",
    })
  }, [triggerAction])

  const logout = useCallback(async () => {
    await triggerAction({
      type: "action",
      action: "Logout",
    })
  }, [triggerAction])

  const promptTransaction = useCallback(
    async (params: TransactionParams) => {
      return (await triggerAction({
        type: "action",
        action: "PromptTransaction",
        params,
      })) as PromptTransactionResponse
    },
    [triggerAction],
  )

  const promptMfa = useCallback(
    async (mfaMethods: MfaMethod[]): Promise<void> => {
      await sendMessage?.(
        {
          type: "action",
          action: "PromptMfa",
          mfaMethods,
        },
        {
          timeout: undefined,
        },
      )
    },
    [sendMessage],
  )

  return {
    switchChain,
    switchWallet,
    installWallet,
    addFunds,
    depositFunds,
    transfer,
    wrap,
    unwrap,
    estimateGas,
    refreshFunds,
    setClickAway,
    showWallet,
    hideWallet,
    requestLogin,
    logout,
    promptTransaction,
    promptMfa,
  }
}
