import { CenterAligned } from "@opensea/ui-kit"
import Image from "next/image"
import React from "react"
import { graphql, useFragment } from "react-relay"
import type { PaymentAssetMedia_asset$key } from "@/lib/graphql/__generated__/PaymentAssetMedia_asset.graphql"
import { PLACEHOLDER_IMAGE, SIZE_TO_PX } from "../constants"
import type { Size } from "../types"

const PAYMENT_ASSET_SIZE_TO_PX: Record<Size, number> = {
  sm: 18,
  md: 32,
} as const

type Props = {
  asset: PaymentAssetMedia_asset$key | undefined
  size?: Size
}

export function PaymentAssetMedia({ asset: assetKey, size = "md" }: Props) {
  const asset = useFragment(
    graphql`
      fragment PaymentAssetMedia_asset on AssetType {
        displayImageUrl
      }
    `,
    assetKey,
  )

  const sizePx = SIZE_TO_PX[size]
  const paymentAssetSizePx = PAYMENT_ASSET_SIZE_TO_PX[size]

  return (
    <CenterAligned
      className="rounded-lg bg-component-gray-1"
      style={{ width: sizePx, height: sizePx }}
    >
      <Image
        alt=""
        height={paymentAssetSizePx}
        src={asset?.displayImageUrl ?? PLACEHOLDER_IMAGE}
        width={paymentAssetSizePx}
      />
    </CenterAligned>
  )
}
