import { useContextSelector } from "use-context-selector"
import type { WALLET_NAME } from "@/constants/wallet"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { WalletContext } from "./WalletContext"

export const useConnectedAddress = (): string | undefined =>
  useContextSelector(WalletContext, ctx => ctx.activeAccount?.address)

export const useConnectedWallet = (): WALLET_NAME | undefined =>
  useContextSelector(WalletContext, ctx => ctx.activeAccount?.walletName)

export const useConnectedChain = (): ChainIdentifier | undefined =>
  useContextSelector(WalletContext, ctx => ctx.activeChain)

export const useInstalledWallets = (): WALLET_NAME[] =>
  useContextSelector(WalletContext, ctx => ctx.installedWallets ?? [])

export const useSwitchWallet = () =>
  useContextSelector(WalletContext, ctx => ctx.switchWallet)

export const useAddFunds = () =>
  useContextSelector(WalletContext, ctx => ctx.addFunds)

export const useDepositFunds = () =>
  useContextSelector(WalletContext, ctx => ctx.depositFunds)
export const useEstimateGas = () =>
  useContextSelector(WalletContext, ctx => ctx.estimateGas)

export const useIsWalletVisible = () =>
  useContextSelector(WalletContext, ctx => ctx.visible)

export const useRefreshFunds = () =>
  useContextSelector(WalletContext, ctx => ctx.refreshFunds)
