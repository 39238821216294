import { createContext } from "use-context-selector"
import type { WALLET_NAME } from "@/constants/wallet"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { asyncNoop } from "@/lib/helpers/noop"
import type { WalletData } from "@/lib/wallet/types"
import type { EstimateGasProps, EstimateGasResponse } from "../Vessel00/types"

const DEFAULT_WALLET_DATA: WalletData = {
  activeAccount: undefined,
  activeChain: undefined,
  installedWallets: [],
}

const DEFAULT_WALLET_CONTEXT: WalletContextType = {
  ...DEFAULT_WALLET_DATA,
  visible: false,
  switchWallet: asyncNoop,
  addFunds: asyncNoop,
  depositFunds: asyncNoop,
  estimateGas: () =>
    Promise.resolve({
      gasEstimate: "",
    }),
  refreshFunds: asyncNoop,
}

type WalletContextType = WalletData & {
  visible: boolean
  switchWallet: (walletName: WALLET_NAME) => Promise<void>
  addFunds: (chain?: ChainIdentifier, symbol?: string) => Promise<void>
  depositFunds: (chain?: ChainIdentifier, symbol?: string) => Promise<void>
  estimateGas: (props: EstimateGasProps) => Promise<EstimateGasResponse>
  refreshFunds: () => Promise<void>
}

export const WalletContext = createContext<WalletContextType>(
  DEFAULT_WALLET_CONTEXT,
)
