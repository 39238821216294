import * as Amplitude from "@amplitude/analytics-browser"
import type { EventProperties, UserProperties } from "../types"
import { AMPLITUDE_API_KEY, IS_LOG_TO_CONSOLE_ENVIRONMENT } from "./constants"

let isInitialized = false

type InitAmplitudeOptions = {
  userId: string | undefined
  deviceId: string | undefined
}

export const initAmplitude = ({ userId, deviceId }: InitAmplitudeOptions) => {
  if (!AMPLITUDE_API_KEY) {
    // No API key means the environment does not support amplitude logging
    return
  }

  Amplitude.init(AMPLITUDE_API_KEY, userId, {
    defaultTracking: {
      pageViews: false,
    },
    deviceId,
  })
  isInitialized = true
}

export const isAmplitudeInitialized = () => isInitialized

export const trackAmplitudeEvent = async (
  eventName: string,
  eventProperties?: EventProperties,
) => {
  // Log events instead of tracking in some environments
  if (IS_LOG_TO_CONSOLE_ENVIRONMENT) {
    console.info(
      `Amplitude: "${eventName}" occurred with properties: `,
      eventProperties,
    )
    return
  }

  if (!AMPLITUDE_API_KEY) {
    return
  }

  await Amplitude.track(eventName, eventProperties).promise
}

export const getAmplitudeSessionId = () => {
  return Amplitude.getSessionId()
}

export const identifyAmplitudeUser = (
  address: string | undefined,
  userProperties: UserProperties = {},
) => {
  // Log instead of identifying user in some environments
  if (IS_LOG_TO_CONSOLE_ENVIRONMENT) {
    console.info(
      `Amplitude: User "${address ?? ""}" identified with properties: `,
      userProperties,
    )
    return
  }

  if (!AMPLITUDE_API_KEY) {
    return
  }

  const identifyEvent = new Amplitude.Identify()
  Object.entries(userProperties).forEach(([key, value]) => {
    identifyEvent.set(key, value)
  })
  // eslint-disable-next-line camelcase
  Amplitude.identify(identifyEvent, { user_id: address })
}

export const setUserProperties = (userProperties: UserProperties) => {
  // Log instead of setting user properties in some environments
  if (IS_LOG_TO_CONSOLE_ENVIRONMENT) {
    console.info(
      `Amplitude: User properties set with properties: `,
      userProperties,
    )
    return
  }

  if (!AMPLITUDE_API_KEY) {
    return
  }

  const identifyEvent = new Amplitude.Identify()
  Object.entries(userProperties).forEach(([key, value]) => {
    identifyEvent.set(key, value)
  })
  Amplitude.identify(identifyEvent)
}
