import type {
  CreateEmbeddedWalletPayload,
  CreateEmbeddedWalletResponse,
  GetUserResponse,
  LoginWithCodePayload,
  LoginWithCodeResponse,
  SendLoginCodePayload,
  SendLoginCodeResponse,
} from "@opensea/wallet-messages"
import type { User } from "@privy-io/react-auth"
import {
  Captcha,
  useLoginWithEmail,
  useLogout,
  usePrivy,
} from "@privy-io/react-auth"
import { captureException } from "@sentry/nextjs"
import { useRouter } from "next/router"
import { useCallback, useEffect, useRef, useState } from "react"
import type { Address } from "viem"
import { useConnectedAddress } from "@/providers/Wallet/selectors"
import { VesselMessageHandler } from "@/providers/Vessel00/messages"
import type { MessageHandler } from "@/providers/Vessel00/messages"
import { useVessel } from "@/providers/Vessel00/VesselProvider.react"
import { getMessageFromError } from "@/lib/helpers/error"
import { useTrackingFn } from "@/lib/analytics/useTrackingFn"
import { useAddConnectedDomain } from "./useAddConnectedDomain"

export function HeadlessLoginWithEmailHandler() {
  const { logout } = useLogout()
  const { sendCode, loginWithCode } = useLoginWithEmail()
  const { authenticated, createWallet, ready, user, getAccessToken } =
    usePrivy()
  const router = useRouter()
  const connectedAddress = useConnectedAddress()
  const { vesselRef } = useVessel()

  const {
    trackSendLoginCode,
    trackSendLoginCodeError,
    trackLoginWithCode,
    trackLoginWithCodeError,
    trackCreateWallet,
    trackCreateWalletError,
  } = useTracking()

  const [captchaKey, setCaptchaKey] = useState(0)
  const resetCaptcha = useCallback(() => {
    setCaptchaKey(key => key + 1)
  }, [])

  const sendLoginCodeHandler: MessageHandler<
    SendLoginCodePayload,
    SendLoginCodeResponse
  > = useCallback(
    async ({ email }, reply) => {
      trackSendLoginCode({ email })
      try {
        // Ensure current account is logged out before sending a login code.
        await logout()
        await sendCode({ email })
        resetCaptcha() // Ensure Captcha is unmounted and remounted for next login
        reply({ success: true })
      } catch (error) {
        trackSendLoginCodeError({ email, error: getMessageFromError(error) })
        reply({ success: false, error: true })
      }
    },
    [
      logout,
      resetCaptcha,
      sendCode,
      trackSendLoginCode,
      trackSendLoginCodeError,
    ],
  )

  const addConnectedDomain = useAddConnectedDomain()
  const onLoginCompleteRef =
    useRef<(privyId: string, isNewUser: boolean) => Promise<void>>()
  const loginWithCodeHandler: MessageHandler<
    LoginWithCodePayload,
    LoginWithCodeResponse
  > = useCallback(
    async ({ code }, reply) => {
      trackLoginWithCode()
      try {
        await loginWithCode({ code })

        const accessToken = await getAccessToken()
        if (!accessToken) {
          reply({ error: true })
          captureException(new Error("No access token after login"))
          return
        }

        if (!vesselRef.current?.parentOrigin) {
          reply({ error: true })
          captureException(new Error("No parent origin"))
          return
        }

        onLoginCompleteRef.current = async (
          privyId: string,
          isNewUser: boolean,
        ) => {
          if (vesselRef.current) {
            await addConnectedDomain(
              vesselRef.current.parentOrigin,
              accessToken,
            )
          }
          reply({ accessToken, privyId, isNewUser })
          await router.push("/")
        }
      } catch (error) {
        trackLoginWithCodeError({ error: getMessageFromError(error) })
        reply({ error: true })
      }
    },
    [
      addConnectedDomain,
      getAccessToken,
      loginWithCode,
      router,
      trackLoginWithCode,
      trackLoginWithCodeError,
      vesselRef,
    ],
  )
  useEffect(
    function triggerOnLoginCompleteCallback() {
      if (user) {
        void onLoginCompleteRef.current?.(user.id, !user.wallet)
        onLoginCompleteRef.current = undefined
      }
    },
    [user],
  )

  const createEmbeddedWalletHandler: MessageHandler<
    CreateEmbeddedWalletPayload,
    CreateEmbeddedWalletResponse
  > = useCallback(
    async (_, reply) => {
      if (!authenticated) {
        reply({ error: new Error("User is not authenticated") })
        return
      }
      if (!ready) {
        reply({ error: new Error("Embedded wallet is not ready") })
        return
      }
      if (!user) {
        reply({ error: new Error("User is not available") })
        return
      }
      if (!user.email) {
        reply({ error: new Error("User does not have an email") })
        return
      }

      try {
        await getAccessToken()
        const { address } = await createWallet()
        reply({ address: address as Address })
        trackCreateWallet({ email: user.email.address })
      } catch (error) {
        reply({
          error: new Error("Failed to create wallet"),
        })
        trackCreateWalletError({
          email: user.email.address,
          error: getMessageFromError(error),
        })
      }
    },
    [
      authenticated,
      createWallet,
      getAccessToken,
      ready,
      trackCreateWallet,
      trackCreateWalletError,
      user,
    ],
  )

  const handleCheckAuthStatus = useRef<(authenticated: boolean) => void>()
  const checkAuthStatusHandler: MessageHandler = useCallback(
    (_, reply) => {
      console.debug("checkAuthStatusHandler", { authenticated, ready })
      if (ready) {
        reply(authenticated)
      } else {
        handleCheckAuthStatus.current = reply
      }
    },
    [authenticated, ready],
  )
  useEffect(() => {
    console.debug("checkAuthStatusHandler useEffect", { ready, authenticated })
    if (ready) {
      handleCheckAuthStatus.current?.(authenticated)
      handleCheckAuthStatus.current = undefined
    }
  }, [ready, authenticated])

  const handleGetUser = useRef<(privyUser: User | null) => void>()
  const getUserHandler: MessageHandler = useCallback(
    async (_, reply) => {
      const handle = async (privyUser: User | null) => {
        const accessToken = await getAccessToken()
        if (!privyUser?.wallet?.address || !accessToken) {
          reply(undefined)
          return
        }
        const userResponse: GetUserResponse = {
          email: privyUser.email?.address,
          addresses: [privyUser.wallet.address as Address],
          privyId: privyUser.id,
          accessToken,
        }
        reply(userResponse)
      }
      if (ready) {
        await handle(user)
      } else {
        handleGetUser.current = handle
      }
    },
    [getAccessToken, ready, user],
  )
  useEffect(() => {
    if (ready) {
      handleGetUser.current?.(user)
      handleGetUser.current = undefined
    }
  }, [ready, user])

  return (
    <>
      <VesselMessageHandler
        action="SendLoginCode"
        handler={sendLoginCodeHandler}
        type="action"
      />
      <VesselMessageHandler
        action="LoginWithCode"
        handler={loginWithCodeHandler}
        type="action"
      />
      <VesselMessageHandler
        action="CreateEmbeddedWallet"
        handler={createEmbeddedWalletHandler}
        type="action"
      />
      <VesselMessageHandler
        action="CheckAuthStatus"
        handler={checkAuthStatusHandler}
        type="action"
      />
      <VesselMessageHandler
        action="GetUser"
        handler={getUserHandler}
        type="action"
      />
      {!connectedAddress && <Captcha key={captchaKey} />}
    </>
  )
}

const useTracking = () => {
  return {
    trackSendLoginCode: useTrackingFn<{ email: string }>("send login code"),
    trackSendLoginCodeError: useTrackingFn<{
      email: string
      error: string
    }>("send login code error"),
    trackLoginWithCode: useTrackingFn("login with code"),
    trackLoginWithCodeError: useTrackingFn<{
      error: string
    }>("login with code error"),
    trackCreateWallet: useTrackingFn<{ email: string }>("create wallet"),
    trackCreateWalletError: useTrackingFn<{ email: string; error: string }>(
      "create wallet error",
    ),
  }
}
