/**
 * @generated SignedSource<<9b62413941af1e10429f89ab111ec604>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WrapTransactionDetails_metadata$data = {
  readonly sentAssetQuantities: ReadonlyArray<{
    readonly asset: {
      readonly decimals: number | null | undefined;
      readonly symbol: string | null | undefined;
      readonly usdSpotPrice: number | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"PaymentAssetMedia_asset">;
    };
    readonly quantity: string;
  }>;
  readonly " $fragmentType": "WrapTransactionDetails_metadata";
};
export type WrapTransactionDetails_metadata$key = {
  readonly " $data"?: WrapTransactionDetails_metadata$data;
  readonly " $fragmentSpreads": FragmentRefs<"WrapTransactionDetails_metadata">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WrapTransactionDetails_metadata",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetQuantityType",
      "kind": "LinkedField",
      "name": "sentAssetQuantities",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetType",
          "kind": "LinkedField",
          "name": "asset",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "usdSpotPrice",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "symbol",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "decimals",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PaymentAssetMedia_asset"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quantity",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TransactionMetadataType",
  "abstractKey": null
};

(node as any).hash = "fcd251f2211d27ad9e7ae3ba88be6ae8";

export default node;
