import type { MfaMethod } from "@privy-io/react-auth"
import { useMfa, useRegisterMfaListener } from "@privy-io/react-auth"
import React from "react"
import { useRouter } from "next/router"
import { useGlobalModal } from "@/providers/GlobalModal"
import { MfaRequestModal } from "./MfaRequestModal.react"
import { useMfaRequestTracking } from "./useMfaRequestTracking"

/** Must be opened with a z-index over `999999` to ensure it appears above the Privy export private key modal. */
const MODAL_Z_INDEX = 1000000

export function MfaRequestHandler() {
  const { openModal } = useGlobalModal()
  const { init, submit, cancel } = useMfa()

  const { trackMfaRequested } = useMfaRequestTracking()

  const { pathname } = useRouter()

  useRegisterMfaListener({
    onMfaRequired: async (mfaMethods: MfaMethod[]) => {
      if (pathname === "/hidden") {
        // Hack to avoid showing MFA modal (and running associated side-effects) within iframe during sdk usage
        return
      }

      trackMfaRequested({ mfaMethods })
      openModal(
        <MfaRequestModal
          cancelMfa={cancel}
          initMfa={init}
          mfaMethods={mfaMethods}
          submitMfa={submit}
        />,
        {
          closeable: false,
          // TODO: Remove custom z-index after privy integrates their export private key modal into their existing modal.
          style: {
            zIndex: MODAL_Z_INDEX,
          },
        },
      )
    },
  })

  return null
}
