import type {
  ActionMessagePayload,
  EventMessagePayload,
  MessagePayload,
} from "@opensea/wallet-messages"
import { useEffect } from "react"
import type { VesselMessageListener } from "@opensea/vessel"
import { useVessel } from "@/providers/Vessel/VesselProvider"
import { useVessel as useVessel00 } from "../VesselProvider.react"
import type { MessageHandler } from "./types"

type VesselMessageHandlerProps<T extends MessagePayload> = {
  handler: MessageHandler<T>
  type: T["type"]
} & (
  | {
      event: T extends EventMessagePayload ? T["event"] : never
      action?: never
    }
  | {
      action: T extends ActionMessagePayload ? T["action"] : never
      event?: never
    }
)

export function VesselMessageHandler<T extends MessagePayload>({
  handler,
  type,
  event,
  action,
}: VesselMessageHandlerProps<T>) {
  const { addMessageHandler: addMessageHandler00 } = useVessel00()
  const { addMessageListener } = useVessel()

  useEffect(() => {
    const messageHandler00: MessageHandler = (payload, reply) => {
      // Ignore messages that do not match the type from props
      if (payload.type !== type) {
        return false
      }
      // Ignore messages that do not match the event or action from props
      if ("event" in payload && payload.event !== event) {
        return false
      }
      if ("action" in payload && payload.action !== action) {
        return false
      }

      return handler(payload as T, reply)
    }

    const messageListener: VesselMessageListener<unknown> = (
      message,
      reply,
    ) => {
      // Ignore messages that do not match the type from props
      if (
        typeof message.payload !== "object" ||
        !message.payload ||
        !("type" in message.payload) ||
        message.payload.type !== type
      ) {
        return false
      }
      // Ignore messages that do not match the event or action from props
      if ("event" in message.payload && message.payload.event !== event) {
        return false
      }
      if ("action" in message.payload && message.payload.action !== action) {
        return false
      }

      const handled = handler(message.payload as T, response => {
        if (typeof response === "object" && "error" in response) {
          reply(response.error, { error: true })
        } else {
          reply(response)
        }
      })

      // Most of our handlers do not support returning boolean for whether handled or not
      // Luckily most of our VesselMessageHandlers are used to always handle one type of message
      // RPCRequests are the exception
      if (
        "action" in message.payload &&
        typeof message.payload.action === "string" &&
        ["HeadlessRPCRequest", "RPCRequest"].includes(message.payload.action)
      ) {
        return Boolean(handled)
      }

      return true
    }

    const removeMessageHandler00 = addMessageHandler00(messageHandler00)
    const removeMessageListener = addMessageListener?.(messageListener)

    return () => {
      removeMessageHandler00()
      removeMessageListener?.()
    }
  }, [handler, type, event, action, addMessageHandler00, addMessageListener])

  return null
}
