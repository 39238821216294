import { useTranslate } from "@opensea/next-translate"
import { FlexColumn } from "@opensea/ui-kit"
import { Separator } from "@opensea/ui-kit/client"
import React from "react"
import { graphql, useFragment } from "react-relay"
import { useChains } from "@/hooks/useChains"
import type { PurchaseTransactionDetails_metadata$key } from "@/lib/graphql/__generated__/PurchaseTransactionDetails_metadata.graphql"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"
import { TransactionDetailsItems } from "../components/TransactionDetailsItems.react"
import { TransactionDetailsTokenAmount } from "../components/TransactionDetailsTokenAmount.react"
import type { TransactionDetailsProps } from "../TransactionDetails.react"

type Props = Omit<TransactionDetailsProps, "metadata"> & {
  metadata: PurchaseTransactionDetails_metadata$key
}

export function PurchaseTransactionDetails({
  metadata: metadataDataKey,
  gasFee,
  isLoadingGasFee,
  chain,
}: Props) {
  const t = useTranslate("transactions")
  const { getNativeCurrencyUsdPrice, getNativeCurrencySymbol } = useChains()

  const {
    sentAssetQuantities: paymentAssetQuantities,
    receivedAssetQuantities: nftAssetQuantities,
  } = useFragment(
    graphql`
      fragment PurchaseTransactionDetails_metadata on TransactionMetadataType {
        sentAssetQuantities {
          asset {
            usdSpotPrice
            symbol
            decimals
          }
          quantity
        }
        receivedAssetQuantities {
          ...TransactionDetailsItems_assetQuantities
        }
      }
    `,
    metadataDataKey,
  )

  const nativeCurrencySymbol = getNativeCurrencySymbol(chain)
  const nativeCurrencyUsdPrice = getNativeCurrencyUsdPrice(chain)

  // All payment asset quantities should have same symbol
  const paymentSymbol = paymentAssetQuantities[0].asset.symbol ?? "ETH"
  const paymentUsdSpotPrice = paymentAssetQuantities[0].asset.usdSpotPrice ?? 1
  const paymentDecimals = paymentAssetQuantities[0].asset.decimals
  const paymentQuantity = bn(
    BigNumber.sum(...paymentAssetQuantities.map(aq => aq.quantity)),
    paymentDecimals,
  )

  const isNativeCurrencyPayment = paymentSymbol === nativeCurrencySymbol

  return (
    <FlexColumn className="gap-6 p-4">
      <div>
        <TransactionDetailsItems items={nftAssetQuantities} />
        <Separator className="mt-4" />
      </div>

      <TransactionDetailsTokenAmount
        label={t("transactionConfirmation.itemPrice", "Item price")}
        quantity={paymentQuantity}
        symbol={paymentSymbol}
        usdPrice={isNativeCurrencyPayment ? undefined : paymentUsdSpotPrice}
      />

      <TransactionDetailsTokenAmount
        isLoading={isLoadingGasFee}
        label={t("transactionConfirmation.gas", "Gas")}
        quantity={gasFee}
        symbol={nativeCurrencySymbol}
        usdPrice={isNativeCurrencyPayment ? undefined : nativeCurrencyUsdPrice}
      />

      {isNativeCurrencyPayment ? (
        <>
          <Separator />
          <TransactionDetailsTokenAmount
            isLoading={isLoadingGasFee}
            label={t("transactionConfirmation.total", "Total")}
            quantity={bn(paymentQuantity).plus(gasFee)}
            symbol={nativeCurrencySymbol}
            usdPrice={nativeCurrencyUsdPrice}
          />
        </>
      ) : null}
    </FlexColumn>
  )
}
