import type { MessagePayloadType } from "@opensea/vessel-0.0"
import type { LoginPayload } from "@opensea/wallet-messages"
import { useLogin, usePrivy } from "@privy-io/react-auth"
import React, { useCallback, useRef } from "react"
import { VesselMessageHandler } from "@/providers/Vessel00/messages/VesselMessageHandler.react"
import { useVesselActions } from "@/providers/Vessel00/useVesselActions"
import type { MessageHandler } from "../../Vessel00/messages/types"

export function LoginHandler() {
  const { setClickAway } = useVesselActions()
  const { authenticated, ready } = usePrivy()

  const replyRef = useRef<(payload: MessagePayloadType) => void>()

  const { login } = useLogin({
    onComplete: async (_user, _isNewUser, _wasAlreadyAuthenticated) => {
      if (replyRef.current) {
        await setClickAway(true)
        replyRef.current({ success: true })
      }
    },
    onError: async error => {
      await setClickAway(true)
      replyRef.current?.({ success: false, error })
    },
  })

  const handler: MessageHandler<LoginPayload> = useCallback(
    async (_, reply) => {
      if (ready && authenticated) {
        reply({ success: true })
        return
      }

      replyRef.current = reply
      await setClickAway(false)
      login()

      // Focus the email input after a short delay to allow the Privy dialog to render
      setTimeout(() => {
        document
          .querySelector<HTMLInputElement>("#privy-dialog #email-input")
          ?.focus()
      })
    },
    [authenticated, login, ready, setClickAway],
  )

  return <VesselMessageHandler action="Login" handler={handler} type="action" />
}
