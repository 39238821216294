import { Text as DsText } from "@opensea/ui-kit"
import type {
  TextDisplayProps,
  TextHeadingProps,
  TextBodyProps as DsTextBodyProps,
} from "@opensea/ui-kit"
import React, { forwardRef } from "react"

export type TextBodyProps = Omit<DsTextBodyProps, "responsive">

export const TextBody = forwardRef<HTMLElement, DsTextBodyProps>(
  function TextBody({ children, ...rest }: DsTextBodyProps, ref) {
    return (
      <DsText ref={ref} responsive={false} {...rest}>
        {children}
      </DsText>
    )
  },
)

export const TextHeading = forwardRef<HTMLElement, TextHeadingProps>(
  function TextHeading({ children, ...rest }: TextHeadingProps, ref) {
    return (
      <DsText.Heading ref={ref} responsive={false} {...rest}>
        {children}
      </DsText.Heading>
    )
  },
)

export const TextDisplay = forwardRef<HTMLElement, TextDisplayProps>(
  function TextDisplay({ children, ...rest }: TextDisplayProps, ref) {
    return (
      <DsText.Display ref={ref} responsive={false} {...rest}>
        {children}
      </DsText.Display>
    )
  },
)

export const Text = Object.assign(TextBody, {
  Body: TextBody,
  Heading: TextHeading,
  Display: TextDisplay,
})
