import { useEffect, useState } from "react"

export const RESEND_CODE_TIMEOUT_SECONDS = 30

export const useResendAuthCode = () => {
  const [canResendCode, setCanResendCode] = useState(true)
  useEffect(() => {
    if (canResendCode) {
      return
    }

    const timeout = setTimeout(() => {
      setCanResendCode(true)
    }, RESEND_CODE_TIMEOUT_SECONDS * 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [canResendCode])

  const resetCanResendCode = () => {
    if (!canResendCode) {
      return
    }
    setCanResendCode(false)
  }

  return {
    canResendCode,
    resetCanResendCode,
  }
}
