import React from "react"
import { Tooltip } from "@/design-system/Tooltip"
import type { NumberInput } from "@/lib/helpers/numberUtils"
import { bn, getMaxDisplayedDecimals } from "@/lib/helpers/numberUtils"

type TokenAmountProps = {
  symbol: string
  amount: NumberInput
}

export function TokenAmount({ symbol, amount: amountInput }: TokenAmountProps) {
  const amount = bn(amountInput)

  const maxDisplayedDecimalPlaces = getMaxDisplayedDecimals(symbol)
  const minDisplayedValue = bn(10).pow(-maxDisplayedDecimalPlaces)
  if (amount.isLessThan(minDisplayedValue)) {
    return (
      <Tooltip content={amount.toString()} variant="card">
        <span>
          &lt; {minDisplayedValue.toString()} {symbol}
        </span>
      </Tooltip>
    )
  }

  const formattedAmount = amount
    .decimalPlaces(maxDisplayedDecimalPlaces)
    .toFormat()
  return (
    <>
      {formattedAmount} {symbol}
    </>
  )
}
