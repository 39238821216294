import React from "react"

type Props = {
  className?: string
  width?: number
}

export function SeiLogo({ className, width = 20 }: Props) {
  return (
    <svg
      className={className}
      fill="none"
      style={{ width, height: width }}
      viewBox="0 0 232 233"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M115.418 232.706C149.402 232.706 179.974 218.055 201.194 194.708L195.548 188.459C188.56 180.726 176.648 180.065 168.848 186.978L156.803 197.653C140.417 212.176 115.511 211.26 100.235 195.573L92.7039 187.839C85.4717 180.412 73.6211 180.154 66.0714 187.258L43.9803 208.044C63.6746 223.496 88.4737 232.706 115.418 232.706ZM211.871 173.709L214.613 176.744C225.294 159.139 231.446 138.467 231.446 116.353C231.446 89.5388 222.401 64.843 207.203 45.1666C204.378 45.8928 201.678 47.2826 199.346 49.3493L187.302 60.0242C170.916 74.5472 146.01 73.6318 130.734 57.9449L123.203 50.2108C115.97 42.784 104.12 42.5251 96.5701 49.629L65.5804 78.7889L50.5042 62.7668L81.4939 33.6068C97.7854 18.2773 123.358 18.836 138.964 34.8624L146.495 42.5965C153.575 49.866 165.116 50.2902 172.71 43.56L184.754 32.8851C186.776 31.0936 188.925 29.5379 191.169 28.2159C170.842 10.6325 144.368 0 115.418 0C57.2113 0 9.01529 42.9808 0.668748 99.0198C16.5519 89.8513 37.1751 92.2483 50.5159 105.948L59.2258 114.892C65.9958 121.845 76.9167 122.578 84.5555 116.593L110.375 96.3654C125.872 84.2244 147.811 84.8117 162.636 97.7643L197.793 128.481L183.319 145.048L148.161 114.332C141.291 108.329 131.124 108.057 123.943 113.684L98.1232 133.912C81.6395 146.826 58.0733 145.243 43.4643 130.241L34.7545 121.297C27.5222 113.87 15.6717 113.611 8.12199 120.715L0 128.357C2.51181 152.933 12.6512 175.243 28.0051 192.868L50.9953 171.235C67.2868 155.906 92.8589 156.464 108.465 172.491L115.997 180.225C123.076 187.494 134.618 187.919 142.211 181.189L154.256 170.514C171.087 155.596 196.793 157.022 211.871 173.709Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
