import type { ChainIdentifier } from "@/hooks/useChains/types"
import { useTrackingFn } from "@/lib/analytics/useTrackingFn"

type SwapTrackingProps = {
  fromChain: ChainIdentifier
  toChain: ChainIdentifier
  fromSymbol: string
  toSymbol: string
  fromAmount: string
  toAmount: string
  usdAmount?: string
}

export const useSwapTracking = () => {
  return {
    trackSwapReview: useTrackingFn<SwapTrackingProps>("wallet swap review"),
    trackSwap: useTrackingFn<SwapTrackingProps & { transactionHash: string }>(
      "wallet swap",
    ),
  }
}
