import { useRouter } from "next/router"
import { useCallback } from "react"
import type { Hex } from "viem"
import {
  createPublicClient,
  encodeFunctionData,
  hexToBigInt,
  hexToNumber,
  http,
  parseUnits,
  toHex,
} from "viem"
import type { TransactionParams } from "@opensea/wallet-messages"
import { useCreateRequestedTransaction } from "@/features/rpc/useCreateRequestedTransaction"
import { useSubmitBlockchainTransaction } from "@/features/rpc/useSubmitBlockchainTransaction"
import { useUpdateUserTransactionState } from "@/features/rpc/useUpdateTransactionStatusPending"
import { useChains } from "@/hooks/useChains"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { ERC20_ABI } from "@/lib/abis/erc20"
import { WETH_ABI } from "@/lib/abis/weth"
import { WALLET_NAME } from "@/constants/wallet"
import { useSwapTracking } from "@/features/swap/tracking"
import { useSwapQuoteStore } from "@/features/swap/swap-store"
import { usePrivyWallet } from "../Privy/usePrivyWallet"
import { useVesselActions } from "../Vessel00/useVesselActions"
import { useVessel } from "../Vessel00/VesselProvider.react"
import { SUPPORTED_CHAINS } from "../Privy/chains"
import { useConnectedAddress, useConnectedWallet } from "./selectors"
import type { SwapProps, TransferProps, UnwrapProps, WrapProps } from "./types"
import { useResolvePaymentAsset } from "./utils/useResolvePaymentAsset"
import type { useCreateRequestedTransactionMutation$variables } from "@/lib/graphql/__generated__/useCreateRequestedTransactionMutation.graphql"

/**
 * This hook is used to abstract away the differences between the Privy and external wallets.
 */
export const useWalletActions = () => {
  const router = useRouter()
  const privyWallet = usePrivyWallet()
  const { getChain, getChainByNetworkId } = useChains()
  const connectedAddress = useConnectedAddress()
  const connectedWallet = useConnectedWallet()
  const vesselActions = useVesselActions()
  const { emitProviderEvent } = useVessel()
  const createRequestedTransaction = useCreateRequestedTransaction()
  const updateUserTransactionState = useUpdateUserTransactionState()
  const { submitBlockchainTransaction } = useSubmitBlockchainTransaction()
  const { resolvePaymentAsset } = useResolvePaymentAsset()
  const isInternalWallet =
    privyWallet && connectedWallet === WALLET_NAME.OpenSeaWallet

  const _makeTransaction = useCallback(
    async ({
      chain,
      calldata,
      from,
      to,
      value,
      gas,
      ...params
    }: {
      chain: ChainIdentifier
      calldata?: Hex
      from: Hex
      to: Hex
      value?: bigint
      gas?: Hex
      useBuffer?: boolean
    } & Omit<
      useCreateRequestedTransactionMutation$variables,
      "nonce" | "calldata" | "value" | "fromAddress" | "toAddress" | "chain"
    >) => {
      const [userTransactionId, transactionHash] = await Promise.all([
        createRequestedTransaction({
          ...params,
          calldata: calldata ?? "",
          chain,
          fromAddress: from,
          toAddress: to,
          value: value?.toString(),
        }),
        submitBlockchainTransaction([
          {
            to,
            from,
            data: calldata,
            value: value ? toHex(value) : undefined,
            gas,
          },
        ]),
      ])

      await updateUserTransactionState({
        userTransactionId,
        transactionHash,
        state: "PENDING",
      })
      await router.push("/transactions?refresh=true")
      return transactionHash
    },
    [
      createRequestedTransaction,
      submitBlockchainTransaction,
      updateUserTransactionState,
      router,
    ],
  )

  const switchChain = useCallback(
    async (destinationChain: ChainIdentifier) => {
      if (isInternalWallet) {
        const destinationChainInfo = getChain(destinationChain)
        const newChainId = toHex(Number(destinationChainInfo.networkId ?? "1"))
        await privyWallet.switchChain(newChainId)
        emitProviderEvent({ event: "chainChanged", data: newChainId })
      } else {
        await vesselActions.switchChain(destinationChain)
      }
    },
    [privyWallet, isInternalWallet, getChain, emitProviderEvent, vesselActions],
  )

  const wrap = useCallback(
    async (props: WrapProps) => {
      if (isInternalWallet) {
        const networkId = privyWallet.chainId.split(":")[1]
        const chain = getChainByNetworkId(networkId)
        await _makeTransaction({
          chain: chain.identifier,
          calldata: encodeFunctionData({
            abi: WETH_ABI,
            functionName: "deposit",
          }),
          from: privyWallet.address as Hex,
          to: chain.wrappedCurrency.address as Hex,
          value: parseUnits(props.quantity, chain.nativeCurrency.decimals),
        })
      } else {
        await vesselActions.wrap(props)
      }
    },
    [
      isInternalWallet,
      privyWallet,
      _makeTransaction,
      getChainByNetworkId,
      vesselActions,
    ],
  )

  const unwrap = useCallback(
    async (props: UnwrapProps) => {
      if (isInternalWallet) {
        const networkId = privyWallet.chainId.split(":")[1]
        const chain = getChainByNetworkId(networkId)
        await _makeTransaction({
          chain: chain.identifier,
          calldata: encodeFunctionData({
            abi: WETH_ABI,
            functionName: "withdraw",
            args: [parseUnits(props.quantity, chain.wrappedCurrency.decimals)],
          }),
          from: privyWallet.address as Hex,
          to: chain.wrappedCurrency.address as Hex,
        })
      } else {
        await vesselActions.unwrap(props)
      }
    },
    [
      isInternalWallet,
      privyWallet,
      getChainByNetworkId,
      _makeTransaction,
      vesselActions,
    ],
  )

  const transfer = useCallback(
    async (props: TransferProps) => {
      if (isInternalWallet) {
        const networkId = privyWallet.chainId.split(":")[1]
        const chain = getChainByNetworkId(networkId)
        const isNativeCurrency = chain.nativeCurrency.symbol === props.symbol

        if (isNativeCurrency) {
          await _makeTransaction({
            chain: chain.identifier,
            from: privyWallet.address as Hex,
            to: props.toAddress as Hex,
            value: parseUnits(props.quantity, chain.nativeCurrency.decimals),
          })
        } else {
          const { paymentAsset } = await resolvePaymentAsset(
            props.symbol,
            chain.identifier,
          )
          await _makeTransaction({
            chain: chain.identifier,
            calldata: encodeFunctionData({
              abi: ERC20_ABI,
              functionName: "transfer",
              args: [
                props.toAddress as Hex,
                parseUnits(props.quantity, paymentAsset.decimals),
              ],
            }),
            from: privyWallet.address as Hex,
            to: paymentAsset.address as Hex,
          })
        }
      } else {
        await vesselActions.transfer(props)
      }
    },
    [
      isInternalWallet,
      privyWallet,
      getChainByNetworkId,
      _makeTransaction,
      resolvePaymentAsset,
      vesselActions,
    ],
  )

  const cancel = useCallback(
    async (nonce: number) => {
      if (privyWallet) {
        await submitBlockchainTransaction(
          [
            {
              to: privyWallet.address as Hex,
              from: privyWallet.address as Hex,
              nonce: toHex(nonce),
            },
          ],
          { isPriority: true, shouldWaitForReceipt: true },
        )

        // TODO jihok: send update to "cancelling" to BE
      }
    },
    [privyWallet, submitBlockchainTransaction],
  )

  const quote = useSwapQuoteStore(state => state.quote)
  const { trackSwap } = useSwapTracking()
  const swap = useCallback(
    async (params: SwapProps) => {
      const {
        transactionParams,
        fromChain,
        destinationChain,
        sentAssetSymbol,
        receivedAssetSymbol,
        sentAssetUsdValue,
      } = params

      let transactionHash: string | undefined
      if (isInternalWallet) {
        transactionHash = await _makeTransaction({
          ...params,
          chain: fromChain,
          calldata: transactionParams.data,
          from: connectedAddress as Hex,
          to: transactionParams.to ?? "0x0",
          value: hexToBigInt(transactionParams.value ?? "0x0"),
          useBuffer: true,
        })
      } else {
        try {
          const response = await vesselActions.promptTransaction({
            ...transactionParams,
            source: transactionParams.from,
          })
          if (response.success) {
            transactionHash = response.transactionHash
            const userTransactionId = await createRequestedTransaction(
              {
                ...params,
                chain: fromChain,
                calldata: transactionParams.data ?? "0x0",
                fromAddress: connectedAddress as Hex,
                toAddress: transactionParams.to ?? "0x0",
                value: transactionParams.value
                  ? hexToNumber(transactionParams.value).toString()
                  : "0",
              },
              true,
            )
            await updateUserTransactionState({
              userTransactionId,
              transactionHash,
              state: "PENDING",
            })
            await router.push("/transactions?refresh=true")
          }
        } catch (e) {
          // if the vessel action fails, we don't need to create a user tx
          console.error(e)
        }
      }

      if (quote && transactionHash && quote.status === "success") {
        trackSwap({
          fromChain,
          toChain: destinationChain ?? fromChain,
          fromSymbol: sentAssetSymbol,
          toSymbol: receivedAssetSymbol,
          fromAmount: quote.fromQuantity,
          toAmount: quote.toQuantity,
          usdAmount: sentAssetUsdValue,
          transactionHash,
        })
      }
    },
    [
      _makeTransaction,
      connectedAddress,
      createRequestedTransaction,
      isInternalWallet,
      quote,
      router,
      trackSwap,
      updateUserTransactionState,
      vesselActions,
    ],
  )

  const approveErc20 = useCallback(
    async (params: TransactionParams, chain: ChainIdentifier) => {
      if (isInternalWallet) {
        await submitBlockchainTransaction(
          [
            {
              from: connectedAddress as Hex,
              data: params.data as Hex,
              to: params.to as Hex,
              value: params.value as Hex,
            },
          ],
          { shouldWaitForReceipt: true },
        )
      } else {
        const { transactionHash } =
          await vesselActions.promptTransaction(params)
        try {
          const chainDetails = getChain(chain)
          const viemChain = SUPPORTED_CHAINS.find(
            c => c.id === (Number(chainDetails.networkId) || 1),
          )
          const publicClient = createPublicClient({
            chain: viemChain,
            transport: http(),
          })
          await publicClient.waitForTransactionReceipt({
            hash: transactionHash as Hex,
          })
        } catch {
          // TODO: waitForTransactionReceipt can fail since we use a public rpc, but we can ignore for now
        }
      }
    },
    [
      connectedAddress,
      getChain,
      isInternalWallet,
      submitBlockchainTransaction,
      vesselActions,
    ],
  )

  return {
    cancel,
    switchChain,
    transfer,
    unwrap,
    wrap,
    swap,
    approveErc20,
  }
}
