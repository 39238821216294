import {
  arbitrum,
  arbitrumNova,
  avalanche,
  avalancheFuji,
  base,
  mainnet,
  optimism,
  polygon,
  sepolia,
  zora,
  zoraTestnet,
} from "@wagmi/chains"
import type { Address, Chain } from "viem"
import {
  blast,
  blastSepolia,
  polygonAmoy,
  klaytn,
  klaytnBaobab,
} from "viem/chains"

export const SUPPORTED_CHAINS = [
  arbitrum,
  arbitrumNova,
  avalanche,
  avalancheFuji,
  // Override blast chain rpc url
  {
    ...blast,
    rpcUrls: {
      default: {
        http: ["https://rpc.ankr.com/blast"],
      },
    },
  },
  {
    id: 1329,
    name: "Sei",
    nativeCurrency: { name: "Sei", symbol: "SEI", decimals: 18 },
    rpcUrls: { default: { http: ["https://evm-rpc.sei-apis.com/"] } },
    blockExplorers: {
      default: { name: "Seitrace", url: "https://seitrace.com" },
    },
    contracts: {
      multicall3: {
        address: "0xcb2436774C3e191c85056d248EF4260ce5f27A9D" as Address,
      },
    },
  } as Chain,
  blastSepolia,
  base,
  mainnet,
  sepolia,
  {
    id: 49_321,
    name: "GUNZ Testnet",
    nativeCurrency: { name: "GUN", symbol: "GUN", decimals: 18 },
    rpcUrls: {
      default: {
        http: [
          "https://rpc.gunz.dev/ext/bc/ryk9vkvNuKtewME2PeCgybo9sdWXGmCkBrrx4VPuZPdVdAak8/rpc",
        ],
      },
    },
    blockExplorers: {
      default: {
        name: "Gunzscan",
        url: "https://testnet.gunzscan.io/",
      },
    },
    testnet: true,
  } as Chain,
  {
    id: 1328,
    name: "Sei Testnet",
    nativeCurrency: { name: "Sei", symbol: "SEI", decimals: 18 },
    rpcUrls: {
      default: {
        http: ["https://evm-rpc-testnet.sei-apis.com"],
      },
    },
    blockExplorers: {
      default: {
        name: "Seitrace",
        url: "'https://seitrace.com",
      },
    },
    testnet: true,
  } as Chain,
  klaytn,
  klaytnBaobab,
  optimism,
  polygon,
  polygonAmoy,
  zora,
  zoraTestnet,
]
