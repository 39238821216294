import { useTranslate } from "@opensea/next-translate"
import { Flex, FlexColumn } from "@opensea/ui-kit"
import { Separator } from "@opensea/ui-kit/client"
import React from "react"
import { graphql, useFragment } from "react-relay"
import { CollectionMedia } from "@/components/common/media/CollectionMedia"
import { Text } from "@/design-system/Text"
import { useChains } from "@/hooks/useChains"
import type { MintTransactionDetails_metadata$key } from "@/lib/graphql/__generated__/MintTransactionDetails_metadata.graphql"
import { ETH_DECIMALS, bn, BigNumber, display } from "@/lib/helpers/numberUtils"
import { TransactionDetailsTokenAmount } from "../components/TransactionDetailsTokenAmount.react"
import type { TransactionDetailsProps } from "../TransactionDetails.react"

type Props = Omit<TransactionDetailsProps, "metadata"> & {
  metadata: MintTransactionDetails_metadata$key
}

export function MintTransactionDetails({
  metadata: metadataDataKey,
  gasFee,
  isLoadingGasFee,
  chain,
}: Props) {
  const t = useTranslate("transactions")
  const { getNativeCurrencyUsdPrice, getNativeCurrencySymbol } = useChains()

  const { sentAssetQuantities, collections, quantity } = useFragment(
    graphql`
      fragment MintTransactionDetails_metadata on TransactionMetadataType {
        quantity
        sentAssetQuantities {
          asset {
            decimals
            symbol
            usdSpotPrice
          }
          quantity
        }
        collections {
          name
          ...CollectionMedia_collection
        }
      }
    `,
    metadataDataKey,
  )

  const nativeCurrencySymbol = getNativeCurrencySymbol(chain)
  const nativeCurrencyUsdPrice = getNativeCurrencyUsdPrice(chain)

  // Only one payment asset is involved in a mint transaction
  const paymentSymbol = sentAssetQuantities.at(0)?.asset.symbol ?? "ETH"
  const paymentUsdSpotPrice = sentAssetQuantities.at(0)?.asset.usdSpotPrice ?? 1
  const paymentDecimals =
    sentAssetQuantities.at(0)?.asset.decimals ?? ETH_DECIMALS
  const paymentQuantity = bn(
    BigNumber.sum(...sentAssetQuantities.map(aq => aq.quantity)),
    paymentDecimals,
  )

  const isNativeCurrencyPayment = paymentSymbol === nativeCurrencySymbol
  const isMultiQuantity = quantity && bn(quantity).gt(1)

  // Only one collection minted from per transaction
  const collection = collections[0]

  return (
    <FlexColumn className="gap-6 p-4">
      <div>
        <Flex className="items-center gap-4">
          <CollectionMedia collection={collection} size="sm" />
          <FlexColumn>
            <Text weight="semibold">{collection.name}</Text>
            {isMultiQuantity ? (
              <Text size="tiny">
                {t(
                  "transactionConfirmation.mintQuantityLabel",
                  "Quantity: {{quantity}}",
                  { quantity: display(quantity) },
                )}
              </Text>
            ) : null}
          </FlexColumn>
        </Flex>
        <Separator className="mt-4" />
      </div>

      <TransactionDetailsTokenAmount
        label={t("transactionConfirmation.mintPrice", "Mint price")}
        quantity={paymentQuantity}
        symbol={paymentSymbol}
        usdPrice={isNativeCurrencyPayment ? undefined : paymentUsdSpotPrice}
      />

      <TransactionDetailsTokenAmount
        isLoading={isLoadingGasFee}
        label={t("transactionConfirmation.gas", "Gas")}
        quantity={gasFee}
        symbol={nativeCurrencySymbol}
        usdPrice={isNativeCurrencyPayment ? undefined : nativeCurrencyUsdPrice}
      />

      {isNativeCurrencyPayment ? (
        <>
          <Separator />
          <TransactionDetailsTokenAmount
            isLoading={isLoadingGasFee}
            label={t("transactionConfirmation.total", "Total")}
            quantity={bn(paymentQuantity).plus(gasFee)}
            symbol={nativeCurrencySymbol}
            usdPrice={nativeCurrencyUsdPrice}
          />
        </>
      ) : null}
    </FlexColumn>
  )
}
