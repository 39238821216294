/**
 * @generated SignedSource<<2aa64b47936723d18a0771cdf52df6aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StackedAssetMedia_assets$data = ReadonlyArray<{
  readonly displayImageUrl: string | null | undefined;
  readonly " $fragmentType": "StackedAssetMedia_assets";
}>;
export type StackedAssetMedia_assets$key = ReadonlyArray<{
  readonly " $data"?: StackedAssetMedia_assets$data;
  readonly " $fragmentSpreads": FragmentRefs<"StackedAssetMedia_assets">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "StackedAssetMedia_assets",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayImageUrl",
      "storageKey": null
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "5f096cf74868d3b26a80d32b402a6688";

export default node;
