import type { WalletTogglePayload } from "@opensea/wallet-messages"
import { useUnleashClient } from "@unleash/nextjs"
import React, { useCallback, useEffect, useState } from "react"
import { useUpdateEffect } from "react-use"
import * as Sentry from "@sentry/nextjs"
import { useMountEffect } from "@/hooks/useMountEffect"
import {
  identifyAmplitudeUser,
  initAmplitude,
  isAmplitudeInitialized,
} from "@/lib/analytics/amplitude"
import { WalletTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/WalletTrackingContext.react"
import type { WalletData } from "@/lib/wallet/types"
import { useOSNextDeviceId, useOSNextWalletData } from "../OSNextData/selectors"
import type { MessageHandler } from "../Vessel00/messages/types"
import { VesselMessageHandler } from "../Vessel00/messages/VesselMessageHandler.react"
import { useVesselActions } from "../Vessel00/useVesselActions"
import { WalletContext } from "./WalletContext"

type WalletProviderProps = {
  children: React.ReactNode
  wallet?: WalletData
}

export function WalletProvider({
  children,
  wallet: walletDataProp,
}: WalletProviderProps) {
  const osNextWalletData = useOSNextWalletData()
  const walletData = walletDataProp ?? osNextWalletData
  const deviceId = useOSNextDeviceId()
  const unleashClient = useUnleashClient()

  useEffect(() => {
    if (walletData.activeAccount?.address) {
      unleashClient.setContextField("userId", walletData.activeAccount.address)
    }
  }, [unleashClient, walletData.activeAccount?.address])
  useEffect(() => {
    if (deviceId) {
      unleashClient.setContextField("deviceId", deviceId)
    }
  }, [deviceId, unleashClient])
  useMountEffect(() => {
    void unleashClient.start()
  })

  useMountEffect(() => {
    if (!isAmplitudeInitialized()) {
      initAmplitude({ userId: walletData.activeAccount?.address, deviceId })
    }
  })

  useUpdateEffect(() => {
    identifyAmplitudeUser(walletData.activeAccount?.address, {})
  }, [walletData.activeAccount?.address])

  useUpdateEffect(() => {
    Sentry.setTag("chain", walletData.activeChain)
    Sentry.setTag("wallet_name", walletData.activeAccount?.walletName)
    Sentry.setUser(
      walletData.activeAccount
        ? { address: walletData.activeAccount.address }
        : null,
    )
  }, [walletData])

  const [visible, setVisible] = useState(false)

  const walletToggleMessageHandler: MessageHandler<WalletTogglePayload> =
    useCallback(payload => {
      setVisible(payload.visible)
    }, [])

  const { switchWallet, addFunds, depositFunds, estimateGas, refreshFunds } =
    useVesselActions()

  return (
    <WalletContext.Provider
      value={{
        ...walletData,
        switchWallet,
        addFunds,
        depositFunds,
        estimateGas,
        refreshFunds,
        visible,
      }}
    >
      <VesselMessageHandler
        event="WalletToggle"
        handler={walletToggleMessageHandler}
        type="event"
      />
      <WalletTrackingContextProvider>{children}</WalletTrackingContextProvider>
    </WalletContext.Provider>
  )
}
