import { useTranslate } from "@opensea/next-translate"
import { Error } from "@opensea/ui-kit/icons"
import { useRouter } from "next/router"
import { Text } from "@/design-system/Text"
import { Button } from "@/design-system/Button"

type FallbackRenderProps = { resetError: () => void }

function GlobalOopsPageBase({ resetError }: FallbackRenderProps) {
  const t = useTranslate("common")
  const router = useRouter()

  const onClickRefresh = async () => {
    await router.push("/")
    resetError()
  }

  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center bg-white dark:bg-gray-3">
      <div className="flex max-w-sm flex-col items-center space-y-5 text-center">
        <Error size={48} />

        <div className="flex flex-col space-y-1">
          <Text color="secondary">
            {t(
              "errorPage.unknownError.description",
              "Looks like something went wrong on our end. Try refreshing below.",
            )}
          </Text>
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-0 flex items-center justify-center p-4">
        <Button className="w-full" onClick={onClickRefresh} variant="secondary">
          {t("errorPage.refresh", "Refresh")}
        </Button>
      </div>
    </div>
  )
}

export function GlobalOopsPage(props: FallbackRenderProps) {
  return <GlobalOopsPageBase {...props} />
}
