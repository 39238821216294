import deepEqual from "fast-deep-equal"

type MemoizeOneOptions = {
  cachePromiseRejection?: boolean
  ttl?: number
  isEqual?: typeof deepEqual
}

export const memoizeOne = <
  TResult,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TFunc extends (this: unknown, ...newArgs: any[]) => TResult,
>(
  fn: TFunc,
  {
    isEqual = deepEqual,
    cachePromiseRejection = false,
    ttl = 0,
  }: MemoizeOneOptions = {},
): TFunc => {
  let calledOnce = false
  let oldArgs: unknown[] | undefined
  let lastResult: unknown

  return ((...newArgs) => {
    if (calledOnce && isEqual(newArgs, oldArgs)) {
      return lastResult
    }

    lastResult = fn(...newArgs)
    const ttlTimeout = ttl
      ? setTimeout(() => {
          calledOnce = false
        }, ttl)
      : undefined

    if (!cachePromiseRejection && lastResult instanceof Promise) {
      lastResult.catch(() => {
        calledOnce = false
        clearTimeout(ttlTimeout as number | undefined)
      })
    }

    calledOnce = true
    oldArgs = newArgs

    return lastResult
  }) as TFunc
}
