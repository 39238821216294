import { create } from "zustand"
import type { BigNumber } from "@/lib/helpers/numberUtils"
import type { SwapQuote } from "./useFetchSwapQuote"

type SwapQuoteWithGas = SwapQuote & {
  gasFees: { usd: BigNumber; native: BigNumber }
}
type SwapQuoteStore = {
  quote: SwapQuoteWithGas | undefined
  fetchingQuote: boolean
  setFetchingQuote: (fetching: boolean) => void
  setQuote: (quote: SwapQuoteWithGas | undefined) => void
}

export const useSwapQuoteStore = create<SwapQuoteStore>(set => ({
  quote: undefined,
  fetchingQuote: false,
  setFetchingQuote: (fetching: boolean) => {
    set({ fetchingQuote: fetching })
  },
  setQuote: (quote: SwapQuoteWithGas | undefined) => {
    set({ quote })
  },
}))
