import { useTranslate } from "@opensea/next-translate"
import {
  FlexColumn,
  Flex,
  classNames,
  Alert,
  AlertIcon,
  AlertContent,
  AlertTitle,
} from "@opensea/ui-kit"
import { AuthCode } from "@opensea/ui-kit/client"
import {
  VerifiedUser,
  SmartphoneFilled,
  InfoFilled,
  VpnKey,
} from "@opensea/ui-kit/icons"
import type { MfaMethod } from "@privy-io/react-auth"
import {
  errorIndicatesMaxMfaRetries,
  errorIndicatesMfaTimeout,
  errorIndicatesMfaVerificationFailed,
} from "@privy-io/react-auth"
import React, { useState } from "react"
import { captureException } from "@sentry/nextjs"
import { Button } from "@/design-system/Button"
import { Text } from "@/design-system/Text"
import { Tooltip } from "@/design-system/Tooltip"
import { useMountEffect } from "@/hooks/useMountEffect"
import { UnreachableCaseError } from "@/lib/helpers/type"
import type { MfaRequestModalProps } from "./MfaRequestModal.react"
import type { MfaError } from "./useMfaErrorText"
import { useMfaErrorText } from "./useMfaErrorText"
import { useMfaRequestTracking } from "./useMfaRequestTracking"
import {
  RESEND_CODE_TIMEOUT_SECONDS,
  useResendAuthCode,
} from "./useResendAuthCode"

type Props = Pick<MfaRequestModalProps, "initMfa" | "submitMfa"> & {
  mfaMethod: MfaMethod
  onComplete: (() => void) | undefined
}

export function MfaRequestCodeInput({
  initMfa,
  submitMfa,
  mfaMethod,
  onComplete,
}: Props) {
  const t = useTranslate("common")

  const {
    trackMfaInitialized,
    trackMfaSubmitted,
    trackResendMfaCode,
    trackMfaFailedInitialize,
    trackMfaFailed,
  } = useMfaRequestTracking()
  const { canResendCode, resetCanResendCode } = useResendAuthCode()

  const [error, setError] = useState<MfaError>()
  const errorText = useMfaErrorText(error)

  const sendMfaCode = async () => {
    try {
      await initMfa(mfaMethod)
      trackMfaInitialized({ mfaMethod })
    } catch (e) {
      // the init request can throw a 429 under the hood
      trackMfaFailedInitialize({ mfaMethod, error: (e as Error).message })
      captureException(e, {
        extra: { mfaMethod, canResendCode, errorType: "init" },
        tags: { userFlow: "privy" },
      })
    }
  }

  const submitMfaCode = async (code: string) => {
    setError(undefined)
    try {
      await submitMfa(mfaMethod, code)
      trackMfaSubmitted({ mfaMethod })
      onComplete?.()
    } catch (e) {
      trackMfaFailed({ mfaMethod, error: (e as Error).message })
      if (errorIndicatesMfaVerificationFailed(e)) {
        setError("verificationFailed")
      } else if (errorIndicatesMaxMfaRetries(e)) {
        setError("maxAttemptsReached")
        resetCanResendCode()
      } else if (errorIndicatesMfaTimeout(e)) {
        if (mfaMethod === "sms") {
          setError("timeout")
        } else {
          await handleResendCode()
        }
      } else {
        setError("unknown")
        // since this shouldn't happen, log the error
        captureException(e, {
          extra: { mfaMethod, canResendCode, errorType: "unknown" },
        })
      }
    }
  }

  useMountEffect(() => {
    void sendMfaCode()
  })

  const handleResendCode = async () => {
    resetCanResendCode()
    setError(undefined)
    await sendMfaCode()
    trackResendMfaCode({ mfaMethod })
  }

  const getIcon = () => {
    switch (mfaMethod) {
      case "sms":
        return <SmartphoneFilled size={48} />
      case "totp":
        return <VerifiedUser size={48} />
      case "passkey":
        return <VpnKey size={48} />
      default:
        throw new UnreachableCaseError(mfaMethod)
    }
  }

  const getText = () => {
    switch (mfaMethod) {
      case "sms":
        return t(
          "mfaRequest.sms.codeSentTo",
          "A one-time authentication code has been sent to your phone number",
        )
      case "totp":
        return t(
          "mfaRequest.totp.codeSentTo",
          "Enter a one-time authentication code from your authenticator app",
        )
      case "passkey":
        return t(
          "mfaRequest.passKey.codeSentTo",
          "Authenticate with your passkey",
        )
      default:
        throw new UnreachableCaseError(mfaMethod)
    }
  }

  const getShowResendButton = () => {
    switch (mfaMethod) {
      case "sms":
        return true
      case "totp":
        return false
      case "passkey":
        return false
      default:
        throw new UnreachableCaseError(mfaMethod)
    }
  }

  return (
    <>
      <FlexColumn
        className={classNames(
          "mx-4 mb-4 flex-1 items-center gap-4 rounded-xl bg-component-gray-1",
          mfaMethod === "sms" ? "pt-10" : "pt-20",
        )}
      >
        <FlexColumn className="w-[280px] items-center gap-4 text-center">
          {getIcon()}
          <Text.Body>{getText()}</Text.Body>
        </FlexColumn>
        <FlexColumn className="w-[280px] items-center gap-2">
          <AuthCode
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            disabled={
              mfaMethod === "totp"
                ? !canResendCode && error === "maxAttemptsReached"
                : error === "maxAttemptsReached" || error === "timeout"
            }
            error={Boolean(error)}
            onComplete={submitMfaCode}
          />
          {error ? (
            <Text className="text-center" color="error" size="small">
              {errorText}
            </Text>
          ) : null}
        </FlexColumn>
        <div className="px-4">
          <Alert>
            <AlertIcon size={18} value={InfoFilled} />
            <AlertContent>
              <AlertTitle size="small">
                {t(
                  "mfaRequest.onComplete",
                  "Action will complete once code is submitted.",
                )}
              </AlertTitle>
            </AlertContent>
          </Alert>
        </div>
      </FlexColumn>
      {getShowResendButton() && (
        <Flex className="p-4">
          <Tooltip
            content={
              !error || error === "verificationFailed"
                ? t(
                    "wallet.opensea.resendTooltip",
                    {
                      one: "If no text message within {{count}} second, click to resend.",
                      other:
                        "If no text message within {{count}} seconds, click to resend.",
                    },
                    { count: RESEND_CODE_TIMEOUT_SECONDS },
                  )
                : undefined
            }
            variant="card"
          >
            <Button
              className="w-full"
              disabled={!canResendCode || error === "unknown"}
              onClick={handleResendCode}
              variant="secondary"
            >
              {t("mfaRequest.resend", "Resend code")}
            </Button>
          </Tooltip>
        </Flex>
      )}
    </>
  )
}
