import { useTranslate } from "@opensea/next-translate"
import { Flex, FlexColumn } from "@opensea/ui-kit"
import React from "react"
import { graphql, useFragment } from "react-relay"
import { StackedAssetMedia } from "@/components/common/media/StackedAssetMedia"
import { Text } from "@/design-system/Text"
import type { TransactionDetailsItems_assetQuantities$key } from "@/lib/graphql/__generated__/TransactionDetailsItems_assetQuantities.graphql"
import { BigNumber, bn, display } from "@/lib/helpers/numberUtils"

type TransactionDetailsItems = {
  items: TransactionDetailsItems_assetQuantities$key
}

export function TransactionDetailsItems({
  items: itemsDataKey,
}: TransactionDetailsItems) {
  const t = useTranslate("transactions")

  const nftAssetQuantities = useFragment(
    graphql`
      fragment TransactionDetailsItems_assetQuantities on AssetQuantityType
      @relay(plural: true) {
        asset {
          displayName
          ...StackedAssetMedia_assets
        }
        quantity
      }
    `,
    itemsDataKey,
  )

  const isSingleItem = nftAssetQuantities.length === 1
  const totalItemQuantity = BigNumber.sum(
    ...nftAssetQuantities.map(aq => aq.quantity),
  )

  const itemsLabel = isSingleItem
    ? nftAssetQuantities[0].asset.displayName
    : t("transactionConfirmation.itemCountLabel", "{{itemCount}} items", {
        itemCount: display(totalItemQuantity),
      })

  const firstItemQuantity = bn(nftAssetQuantities[0].quantity)
  const quantityLabel =
    isSingleItem && !firstItemQuantity.eq(1)
      ? t(
          "transactionConfirmation.itemQuantityLabel",
          "Quantity: {{quantity}}",
          { quantity: display(firstItemQuantity) },
        )
      : undefined

  return (
    <Flex className="items-center gap-4">
      <StackedAssetMedia
        assets={nftAssetQuantities.map(aq => aq.asset)}
        size="sm"
      />
      <FlexColumn>
        <Text weight="semibold">{itemsLabel}</Text>
        {isSingleItem ? <Text size="tiny">{quantityLabel}</Text> : null}
      </FlexColumn>
    </Flex>
  )
}
