/**
 * @generated SignedSource<<7890d2d7b874647d2bde44ccb739c65b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AssetTokenStandard = "AUCTION_HOUSE" | "BITKEEP" | "BLUR" | "CONSIDERATION" | "CRYPTOKITTIES" | "CRYPTOPUNKS" | "CUSTOM" | "DECENTRALAND" | "ELEMENT" | "ENJIN_LEGACY" | "ERC1155" | "ERC20" | "ERC721" | "ETH_BRIDGE" | "JOEPEGS" | "KNOWNORIGIN" | "LOOKSRARE" | "MAKERSPLACE" | "METAPLEX" | "SEADROP" | "SOLANA" | "SPL" | "SUPERRARE" | "TOFU" | "TRANSFER_HELPER" | "TROVE" | "UNKNOWN" | "WYVERN" | "X2Y2" | "ZERO_EX" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DealSideItemsDisplay_assetQuantities$data = ReadonlyArray<{
  readonly asset: {
    readonly decimals: number | null | undefined;
    readonly displayName: string;
    readonly symbol: string | null | undefined;
    readonly tokenStandard: AssetTokenStandard | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"StackedAssetMedia_assets">;
  };
  readonly quantity: string;
  readonly " $fragmentType": "DealSideItemsDisplay_assetQuantities";
}>;
export type DealSideItemsDisplay_assetQuantities$key = ReadonlyArray<{
  readonly " $data"?: DealSideItemsDisplay_assetQuantities$data;
  readonly " $fragmentSpreads": FragmentRefs<"DealSideItemsDisplay_assetQuantities">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "DealSideItemsDisplay_assetQuantities",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetType",
      "kind": "LinkedField",
      "name": "asset",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tokenStandard",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "symbol",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "decimals",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "StackedAssetMedia_assets"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantity",
      "storageKey": null
    }
  ],
  "type": "AssetQuantityType",
  "abstractKey": null
};

(node as any).hash = "79a693fbe27e5b65c543453b7c332194";

export default node;
