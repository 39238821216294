import { useWallets } from "@privy-io/react-auth"

export const usePrivyWallet = () => {
  const { wallets } = useWallets()
  const privyWallet = wallets.find(
    wallet => wallet.walletClientType === "privy",
  )

  return privyWallet
}
