import { useTheme } from "@opensea/next-themes"
import type { CookieDataChangePayload } from "@opensea/wallet-messages"
import React, { useCallback, useEffect, useState } from "react"
import { usePrivy } from "@privy-io/react-auth"
import { getOSNextData } from "@/lib/os-next-data/storage"
import type { OSNextData } from "@/lib/os-next-data/types"
import { WALLET_NAME } from "@/constants/wallet"
import type { MessageHandler } from "../Vessel00/messages/types"
import { VesselMessageHandler } from "../Vessel00/messages/VesselMessageHandler.react"
import { useVesselActions } from "../Vessel00/useVesselActions"
import { DEFAULT_OS_NEXT_DATA, OSNextDataContext } from "./context"

type OSNextDataProviderProps = {
  children: React.ReactNode
  /**
   * Only used in storybooks and tests
   */
  osNextData?: OSNextData
}

export function OSNextDataProvider({
  children,
  osNextData: osNextDataProp,
}: OSNextDataProviderProps) {
  const [osNextData, setOSNextData] = useState<OSNextData>(
    osNextDataProp ?? getOSNextData() ?? DEFAULT_OS_NEXT_DATA,
  )

  // Handle theme change when opensea-next theme changes
  const { setTheme } = useTheme()
  useEffect(() => {
    setTheme(osNextData.theme ?? "light")
  }, [osNextData.theme, setTheme])

  // Set wallet data from cookie when a CookieDataChange event is received
  const resetWalletData: MessageHandler<CookieDataChangePayload> =
    useCallback(() => {
      const nextData = osNextDataProp ?? getOSNextData() ?? DEFAULT_OS_NEXT_DATA
      setOSNextData(nextData)
    }, [osNextDataProp])

  const { ready, authenticated } = usePrivy()
  const { logout } = useVesselActions()
  const isEmbeddedWalletConnected =
    osNextData.activeAccount?.walletName === WALLET_NAME.OpenSeaWallet
  useEffect(() => {
    if (isEmbeddedWalletConnected && ready && !authenticated) {
      void logout()
    }
  }, [authenticated, isEmbeddedWalletConnected, logout, ready])

  return (
    <OSNextDataContext.Provider value={osNextData}>
      <VesselMessageHandler
        event="CookieDataChange"
        handler={resetWalletData}
        type="event"
      />
      {children}
    </OSNextDataContext.Provider>
  )
}
