import type { MessagePayloadType } from "@opensea/vessel-0.0"
import type {
  HeadlessRPCRequestPayload,
  RPCRequestPayload,
} from "@opensea/wallet-messages"
import type { User, ConnectedWallet } from "@privy-io/react-auth"
import React, { useCallback, useEffect, useState } from "react"
import { usePrivyWallet } from "@/providers/Privy/usePrivyWallet"
import type { MessageHandler } from "@/providers/Vessel00/messages/types"
import { VesselMessageHandler } from "@/providers/Vessel00/messages/VesselMessageHandler.react"
import { usePrivyUser } from "@/providers/Privy/usePrivyUser"

type GenericRPCRequestPayload = RPCRequestPayload | HeadlessRPCRequestPayload

export type RPCMessageHandlerFn = (
  payload: GenericRPCRequestPayload,
  reply: (payload: MessagePayloadType) => void,
  wallet: ConnectedWallet,
  user: User,
) => unknown

type QueuedMessage = {
  messagePayload: GenericRPCRequestPayload
  reply: (payload: MessagePayloadType) => void
}

type RPCMessageHandlerProps = {
  methods: string[]
  handler: RPCMessageHandlerFn
  headless?: boolean
}

export function RPCMessageHandler({
  methods,
  handler,
  headless,
}: RPCMessageHandlerProps) {
  const wallet = usePrivyWallet()
  const user = usePrivyUser()
  const [queuedMessages, setQueuedMessages] = useState<QueuedMessage[]>([])

  const internalHandler: MessageHandler<GenericRPCRequestPayload> = useCallback(
    (messagePayload, reply) => {
      // Ignore messages that do not match the methods provided
      if (!methods.includes(messagePayload.payload.method)) {
        return false
      }

      if (wallet && user) {
        handler(messagePayload, reply, wallet, user)
        return true
      }

      // If the wallet is not connected, queue the message
      setQueuedMessages(prev => [...prev, { messagePayload, reply }])
      return true // Did not handle immediately but queued to be handled
    },
    [handler, methods, user, wallet],
  )

  // Handle messages in queue when the wallet connects
  useEffect(() => {
    if (wallet && user && queuedMessages.length) {
      queuedMessages.forEach(({ messagePayload, reply }) => {
        handler(messagePayload, reply, wallet, user)
      })
      setQueuedMessages([])
    }
  }, [handler, queuedMessages, user, wallet])

  return (
    <VesselMessageHandler
      action={headless ? "HeadlessRPCRequest" : "RPCRequest"}
      handler={internalHandler}
      type="action"
    />
  )
}
