import * as sidecar from "@/lib/sidecar/config"

export const GRAPHQL_API_URL =
  typeof window === "undefined"
    ? process.env.GRAPHQL_SERVER_API_URL || sidecar.getValue("graphqlApiUrl")
    : sidecar.getValue("graphqlApiUrl")

export const WS_GRAPHQL_API_URL =
  typeof window === "undefined"
    ? process.env.SUBSCRIPTION_SERVER_API_URL ||
      "wss://opensea.io/__api/subscriptions/graphql/"
    : "wss://opensea.io/__api/subscriptions/graphql/"
