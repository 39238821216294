import React, { useCallback } from "react"
import { useVesselActions } from "@/providers/Vessel00/useVesselActions"
import {
  BASIC_METHODS,
  PERSONAL_SIGN_METHODS,
  SEND_TRANSACTION_METHODS,
  SIGN_TYPED_DATA_METHODS,
  SWITCH_CHAIN_METHODS,
} from "./RPCHandlers.react"
import { useSwitchChainHandler } from "./handlers/useSwitchChainHandler.react"
import type { RPCMessageHandlerFn } from "./RPCMessageHandler.react"
import { RPCMessageHandler } from "./RPCMessageHandler.react"
import { useBasicHandler } from "./handlers/useBasicHandler.react"

const MFA_REQUIRED_METHODS = [
  ...SEND_TRANSACTION_METHODS,
  ...SIGN_TYPED_DATA_METHODS,
  ...PERSONAL_SIGN_METHODS,
]

export function HeadlessRPCMessageHandlers() {
  const { promptMfa } = useVesselActions()

  const switchChainHandler = useSwitchChainHandler()
  const basicHandler = useBasicHandler()

  const mfaRequiredHandler: RPCMessageHandlerFn = useCallback(
    async (messagePayload, reply, wallet, user) => {
      // MFA enrollment is required so prompt MFA and wait for completion
      if (user.mfaMethods.length === 0) {
        await promptMfa(user.mfaMethods)
      }

      // Handle all other RPC methods
      const ethereumProvider = await wallet.getEthereumProvider()
      const result = await ethereumProvider.request(messagePayload.payload)
      reply(result)
    },
    [promptMfa],
  )

  return (
    <>
      <RPCMessageHandler
        handler={switchChainHandler}
        headless
        methods={SWITCH_CHAIN_METHODS}
      />
      <RPCMessageHandler
        handler={mfaRequiredHandler}
        headless
        methods={MFA_REQUIRED_METHODS}
      />
      <RPCMessageHandler
        handler={basicHandler}
        headless
        methods={BASIC_METHODS}
      />
    </>
  )
}
