/**
 * @generated SignedSource<<1de962575eec0183f7a7d6ada1b5d40b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MintTransactionDetails_metadata$data = {
  readonly collections: ReadonlyArray<{
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"CollectionMedia_collection">;
  }>;
  readonly quantity: string | null | undefined;
  readonly sentAssetQuantities: ReadonlyArray<{
    readonly asset: {
      readonly decimals: number | null | undefined;
      readonly symbol: string | null | undefined;
      readonly usdSpotPrice: number | null | undefined;
    };
    readonly quantity: string;
  }>;
  readonly " $fragmentType": "MintTransactionDetails_metadata";
};
export type MintTransactionDetails_metadata$key = {
  readonly " $data"?: MintTransactionDetails_metadata$data;
  readonly " $fragmentSpreads": FragmentRefs<"MintTransactionDetails_metadata">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MintTransactionDetails_metadata",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetQuantityType",
      "kind": "LinkedField",
      "name": "sentAssetQuantities",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetType",
          "kind": "LinkedField",
          "name": "asset",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "decimals",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "symbol",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "usdSpotPrice",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionType",
      "kind": "LinkedField",
      "name": "collections",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CollectionMedia_collection"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TransactionMetadataType",
  "abstractKey": null
};
})();

(node as any).hash = "8ab8ab6c1b55d93f752866f8c8825646";

export default node;
