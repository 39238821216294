import { useTranslate } from "@opensea/next-translate"
import { FlexCenter } from "@opensea/ui-kit"
import { Error } from "@opensea/ui-kit/icons"
import React, { Suspense } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"
import { hexToBigInt } from "viem"
import { Loader } from "@/design-system/Loader"
import { Text } from "@/design-system/Text"
import { useChains } from "@/hooks/useChains"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTrackingFn } from "@/lib/analytics/useTrackingFn"
import type { TransactionTypeEnum } from "@/lib/graphql/__generated__/Transaction_metadata.graphql"
import type { TransactionConfirmationModalQuery } from "@/lib/graphql/__generated__/TransactionConfirmationModalQuery.graphql"
import { useGlobalModal } from "@/providers/GlobalModal"
import type { GasFeeEstimate } from "@/providers/Privy/useGasFeeEstimate"
import { useGasFeeEstimate } from "@/providers/Privy/useGasFeeEstimate"
import { useConnectedChain } from "@/providers/Wallet/selectors"
import { ConfirmationModalContent } from "../ConfirmationModal"
import { ConfirmationModalTitle } from "../ConfirmationModal/ConfirmationModalTitle.react"
import { USER_REJECTED_REQUEST_ERROR } from "../constants"
import type { TransactionParams } from "../types"
import { useSubmitBlockchainTransaction } from "../useSubmitBlockchainTransaction"
import { isMfaCancelledError } from "../utils"
import { TransactionDetails } from "./TransactionDetails/TransactionDetails.react"
import { useTransactionConfirmationTitle } from "./useTransactionConfirmationTitle"
import { useTransactionNativeCurrencyTotal } from "./useTransactionNativeCurrencyTotal"

type TransactionConfirmationModalProps = {
  params: TransactionParams
  chain: ChainIdentifier
  onSuccess: (transactionHash: string) => void
  onError: (error: unknown) => void
}

type LazyTransactionConfirmationModalProps =
  TransactionConfirmationModalProps & {
    gasFee: GasFeeEstimate
    isLoadingGasFee: boolean
  }

export function LazyTransactionConfirmationModal({
  params,
  chain,
  onSuccess,
  onError,
  gasFee,
  isLoadingGasFee,
}: LazyTransactionConfirmationModalProps) {
  const t = useTranslate("transactions")
  const { closeModal } = useGlobalModal()
  const { submitBlockchainTransaction } = useSubmitBlockchainTransaction()
  const { fee, gas, price } = gasFee

  const {
    trackOpenTransactionCofirmationModal,
    trackConfirmTransaction,
    trackRejectTransaction,
    trackTransactionSubmitted,
    trackTransactionFailed,
  } = useTracking()

  const connectedChain = useConnectedChain() ?? "ETHEREUM"
  const { getNativeCurrencySymbol } = useChains()

  const { transactionMetadata, wallet } =
    useLazyLoadQuery<TransactionConfirmationModalQuery>(
      graphql`
        query TransactionConfirmationModalQuery(
          $data: String!
          $to: AddressScalar!
          $from: AddressScalar!
          $value: BigIntScalar!
          $chain: ChainScalar!
          $nativeCurrencySymbol: String!
        ) {
          transactionMetadata(
            inputData: $data
            toAddress: $to
            fromAddress: $from
            value: $value
            chain: $chain
          ) {
            type
            ...TransactionDetails_metadata
            ...useTransactionNativeCurrencyTotal_metadata
          }
          wallet(address: $from) {
            fundsOf(symbol: $nativeCurrencySymbol, chain: $chain) {
              quantity
            }
          }
        }
      `,
      {
        data: params[0].data ?? "",
        to: params[0].to ?? "",
        from: params[0].from,
        value: params[0].value ? hexToBigInt(params[0].value).toString() : "0",
        chain,
        nativeCurrencySymbol: getNativeCurrencySymbol(connectedChain),
      },
    )

  const transactionNativeCurrencyTotal =
    useTransactionNativeCurrencyTotal(transactionMetadata)
  const nativeCurrencyTotalEstimate = transactionNativeCurrencyTotal.plus(fee)
  const nativeCurrencyBalance = wallet.fundsOf.quantity
  const hasEnoughEstimatedFunds = nativeCurrencyTotalEstimate.isLessThan(
    nativeCurrencyBalance,
  )

  const confirmDisabledReason = !hasEnoughEstimatedFunds
    ? t(
        "transactionConfirmation.confirmDisabled.notEnoughFunds",
        "Not enough funds to complete transaction.",
      )
    : undefined

  const onConfirm = async () => {
    trackConfirmTransaction({ type: transactionMetadata?.type })
    // Submit transaction using Privy ethereum provider

    try {
      const result = await submitBlockchainTransaction([
        { ...params[0], gas, ...price },
      ])
      trackTransactionSubmitted({ type: transactionMetadata?.type })
      onSuccess(result)
    } catch (error) {
      if (isMfaCancelledError(error)) {
        return // Do not handle MFA cancellation as an error
      }
      trackTransactionFailed({ type: transactionMetadata?.type })
      onError(error)
    }
    closeModal()
  }

  const onReject = () => {
    trackRejectTransaction({ type: transactionMetadata?.type })
    onError(USER_REJECTED_REQUEST_ERROR)
    closeModal()
  }

  const title = useTransactionConfirmationTitle(transactionMetadata?.type)

  useMountEffect(() => {
    trackOpenTransactionCofirmationModal({ type: transactionMetadata?.type })
  })

  if (!transactionMetadata) {
    return <>Error decoding transaction calldata</>
  }

  return (
    <ConfirmationModalContent
      isConfirmDisabled={Boolean(confirmDisabledReason)}
      onConfirm={onConfirm}
      onReject={onReject}
    >
      <ConfirmationModalTitle>{title}</ConfirmationModalTitle>

      <div className="rounded-xl bg-component-gray-1">
        <TransactionDetails
          chain={chain}
          gasFee={fee}
          isLoadingGasFee={isLoadingGasFee}
          metadata={transactionMetadata}
        />
      </div>

      {confirmDisabledReason ? (
        <FlexCenter className="mt-2 justify-center gap-0.5" color="error">
          <Error size={18} /> <Text size="small">{confirmDisabledReason}</Text>
        </FlexCenter>
      ) : null}
    </ConfirmationModalContent>
  )
}

export function TransactionConfirmationModal(
  props: TransactionConfirmationModalProps,
) {
  const { gasFee, isLoading } = useGasFeeEstimate(props.params)

  return (
    <Suspense fallback={<Loader />}>
      <LazyTransactionConfirmationModal
        {...props}
        gasFee={gasFee}
        isLoadingGasFee={isLoading}
      />
    </Suspense>
  )
}

type TrackingProps = {
  type: TransactionTypeEnum | undefined
}

const useTracking = () => {
  return {
    trackOpenTransactionCofirmationModal: useTrackingFn<TrackingProps>(
      "open transaction confirmation modal",
    ),
    trackRejectTransaction: useTrackingFn<TrackingProps>("reject transaction"),
    trackConfirmTransaction: useTrackingFn<TrackingProps>(
      "confirm transaction",
    ),
    trackTransactionSubmitted: useTrackingFn<TrackingProps>(
      "transaction submitted",
    ),
    trackTransactionFailed: useTrackingFn<TrackingProps>("transaction failed"),
  }
}
