import React from "react"
import { RPCHandlers } from "@/features/rpc/RPCHandlers/RPCHandlers.react"
import { HeadlessRPCMessageHandlers } from "@/features/rpc/RPCHandlers/HeadlessRPCHandlers"
import { HeadlessLoginWithEmailHandler } from "./HeadlessLoginHandler"
import { LoginHandler } from "./LoginHandler.react"
import { LogoutHandler } from "./LogoutHandler.react"
import { MfaEnrollmentHandlers } from "./MfaEnrollmentHandlers"
import { MfaRequestHandler, HeadlessMfaHandler } from "./MfaRequestHandler"

export function AuthHandlers() {
  return (
    <>
      <LoginHandler />
      <HeadlessLoginWithEmailHandler />
      <LogoutHandler />
      <MfaEnrollmentHandlers />
      <MfaRequestHandler />
      <HeadlessMfaHandler />
      <RPCHandlers />
      <HeadlessRPCMessageHandlers />
    </>
  )
}
