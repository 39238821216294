import React from "react"
import { ibmPlexMono, inter } from "@/styles/fonts"

export function Fonts() {
  return (
    <>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style global jsx>{`
        :root {
          --font-family-inter: ${inter.style.fontFamily};
          --font-family-ibm-plex-mono: ${ibmPlexMono.style.fontFamily};
        }
      `}</style>
    </>
  )
}
