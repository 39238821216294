import type { MessagePayload } from "@opensea/wallet-messages"

export const isValidMessagePayload = (
  payload: unknown,
): payload is MessagePayload =>
  !!payload &&
  typeof payload === "object" &&
  "type" in payload &&
  typeof payload.type === "string"

type SuccessResponse = {
  success: true
  [key: string]: unknown
}

export const isSuccessResponse = (
  payload: unknown,
): payload is SuccessResponse =>
  !!payload &&
  typeof payload === "object" &&
  "success" in payload &&
  payload.success === true
