import type { MfaMethod } from "@privy-io/react-auth"
import { useMfa, useRegisterMfaListener } from "@privy-io/react-auth"
import type {
  CancelMfaPayload,
  InitMfaPayload,
  SubmitMfaPayload,
} from "@opensea/wallet-messages"
import { useCallback } from "react"
import { useVesselActions } from "@/providers/Vessel00/useVesselActions"
import type { MessageHandler } from "@/providers/Vessel00/messages"
import { VesselMessageHandler } from "@/providers/Vessel00/messages"
import { getSubmitMfaErrorReason } from "../MfaEnrollmentHandlers/utils"
import { useMfaRequestTracking } from "./useMfaRequestTracking"

export function HeadlessMfaHandler() {
  const {
    trackMfaRequested,
    trackMfaInitialized,
    trackMfaSubmitted,
    trackMfaFailed,
  } = useMfaRequestTracking()
  const { init, submit, cancel } = useMfa()
  const { promptMfa } = useVesselActions()

  useRegisterMfaListener({
    onMfaRequired: async (mfaMethods: MfaMethod[]) => {
      trackMfaRequested({ mfaMethods })
      await promptMfa(mfaMethods)
    },
  })

  const initMfa: MessageHandler<InitMfaPayload> = useCallback(
    async ({ mfaMethod }, reply) => {
      try {
        await init(mfaMethod)
        trackMfaInitialized({ mfaMethod })
        reply({ success: true })
      } catch (error) {
        reply({ error: true })
      }
    },
    [init, trackMfaInitialized],
  )

  const submitMfa: MessageHandler<SubmitMfaPayload> = useCallback(
    async ({ mfaMethod, mfaCode }, reply) => {
      try {
        await submit(mfaMethod, mfaCode)
        trackMfaSubmitted({ mfaMethod })
        reply({ success: true })
      } catch (error) {
        const reason = getSubmitMfaErrorReason(error)
        trackMfaFailed({ mfaMethod, error: reason })
        reply({ error: { reason } })
      }
    },
    [submit, trackMfaSubmitted, trackMfaFailed],
  )

  const cancelMfa: MessageHandler<CancelMfaPayload> = useCallback(
    (_, reply) => {
      try {
        cancel()
        reply({ success: true })
      } catch (error) {
        reply({ error: true })
      }
    },
    [cancel],
  )

  return (
    <>
      <VesselMessageHandler action="InitMfa" handler={initMfa} type="action" />
      <VesselMessageHandler
        action="SubmitMfa"
        handler={submitMfa}
        type="action"
      />
      <VesselMessageHandler
        action="CancelMfa"
        handler={cancelMfa}
        type="action"
      />
    </>
  )
}
