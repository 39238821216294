import { useTranslate } from "@opensea/next-translate"
import { FlexColumn, classNames } from "@opensea/ui-kit"
import { Error } from "@opensea/ui-kit/icons"
import { usePrivy } from "@privy-io/react-auth"
import React, { useEffect, useState } from "react"
import { fetchQuery, graphql, useRelayEnvironment } from "react-relay"
import { Button } from "@/design-system/Button"
import { Text } from "@/design-system/Text"
import type { BannedAccountGateQuery } from "@/lib/graphql/__generated__/BannedAccountGateQuery.graphql"
import { useConnectedAddress } from "../Wallet/selectors"

type BannedAccountGateProps = {
  children: React.ReactNode
}

export function BannedAccountGate({ children }: BannedAccountGateProps) {
  const t = useTranslate("common")
  const environment = useRelayEnvironment()
  const connectedAddress = useConnectedAddress()
  const { exportWallet } = usePrivy()

  const [isAccountWalletBanned, setIsAccountWalletBanned] = useState(false)

  useEffect(() => {
    const checkAccountBanStatus = async () => {
      if (connectedAddress) {
        const data = await fetchQuery<BannedAccountGateQuery>(
          environment,
          graphql`
            query BannedAccountGateQuery($address: AddressScalar!) {
              getAccount(address: $address) {
                metadata {
                  isBanned
                }
              }
            }
          `,
          { address: connectedAddress },
        ).toPromise()
        setIsAccountWalletBanned(Boolean(data?.getAccount.metadata?.isBanned))
      }
    }
    void checkAccountBanStatus()
  }, [connectedAddress, environment])

  if (isAccountWalletBanned) {
    return (
      <FlexColumn
        className={classNames(
          "items-center justify-center text-center",
          "h-screen w-screen gap-4 px-10",
          "bg-base-1",
        )}
      >
        <Error />
        <Text>
          {t("bannedAccountGate.title", "Your account has been disabled.")}
        </Text>
        <Button onClick={exportWallet}>
          {t("bannedAccountGate.exportWallet", "Export private key")}
        </Button>
      </FlexColumn>
    )
  }

  return children
}
