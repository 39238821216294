import Image from "next/image"
import React from "react"
import { graphql, useFragment } from "react-relay"
import {
  PLACEHOLDER_IMAGE,
  SIZE_TO_PX,
} from "@/components/common/media/constants"
import type { CollectionMedia_collection$key } from "@/lib/graphql/__generated__/CollectionMedia_collection.graphql"
import { containedMediaClassNames } from "../styles"
import type { Size } from "../types"

export type CollectionMediaProps = {
  collection: CollectionMedia_collection$key | undefined
  size?: Size
}

export function CollectionMedia({
  collection: collectionDataKey,
  size = "md",
}: CollectionMediaProps) {
  const collection = useFragment(
    graphql`
      fragment CollectionMedia_collection on CollectionType {
        logo
      }
    `,
    collectionDataKey,
  )

  const sizePx = SIZE_TO_PX[size]

  return (
    <Image
      alt=""
      className={containedMediaClassNames}
      height={sizePx}
      src={collection?.logo ?? PLACEHOLDER_IMAGE}
      width={sizePx}
    />
  )
}
