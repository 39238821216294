import type { Environment } from "@/constants/env/environment/schema"
import { envSchema } from "@/constants/env/environment/schema"
import type { UnleashEnvironment } from "@/lib/feature-flags/environment"
import { unleashEnvironmentSchema } from "@/lib/feature-flags/environment"
import { memoizeOne } from "@/lib/helpers/memoization"

export type SidecarConfig = {
  amplitudeApiKey: string | undefined
  openseaNextUrl: string
  graphqlApiUrl: string
  unleashProxyUrl: string
  unleashEnvironment: NonNullable<UnleashEnvironment>
  isTestnet: boolean
  environment: Environment
  siteUrl: URL
  privyAppId: string
  privyClientId: string
}

export const Config: SidecarConfig = {
  amplitudeApiKey: process.env.AMPLITUDE_API_KEY,
  openseaNextUrl: process.env.OPENSEA_NEXT_URL ?? "http://localhost:3000",
  graphqlApiUrl:
    process.env.GRAPHQL_API_URL ?? "https://api.opensea.io/graphql",
  isTestnet: process.env.IS_TESTNET === "true",
  unleashProxyUrl:
    process.env.UNLEASH_PROXY_URL ??
    "https://features-proxy.dev.openseabeta.com/proxy",
  unleashEnvironment:
    unleashEnvironmentSchema.parse(process.env.UNLEASH_ENVIRONMENT) ??
    "development",
  environment: envSchema.parse(process.env.ENVIRONMENT),
  siteUrl: new URL(process.env.SITE_URL ?? "http://localhost:5001"),

  privyAppId:
    process.env.NEXT_PUBLIC_PRIVY_APP_ID ?? "cllms5ugr00r5l308z36wiftw",
  privyClientId:
    process.env.NEXT_PUBLIC_PRIVY_CLIENT_ID ??
    "client-WY2bcoYdtjSgoUqmLqtxkSinLssNqgDn66pkmRkpbkf9K",
}

export const SIDECAR_CONFIG_DOCUMENT_ID = "__OS_CONFIG__"

const getDocumentConfig = memoizeOne((): SidecarConfig => {
  return JSON.parse(
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    document.querySelector(`#${SIDECAR_CONFIG_DOCUMENT_ID}`)?.textContent ||
      "{}",
  )
})

export const getValue = <Key extends keyof SidecarConfig>(
  key: Key,
): SidecarConfig[Key] => {
  if (typeof window === "undefined") {
    return Config[key]
  }
  const documentConfig = getDocumentConfig()
  if (!(key in documentConfig)) {
    return Config[key]
  }
  return documentConfig[key]
}
