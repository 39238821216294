import type { LogoutPayload } from "@opensea/wallet-messages"
import { useLogout } from "@privy-io/react-auth"
import React, { useCallback } from "react"
import type { MessageHandler } from "@/providers/Vessel00/messages/types"
import { VesselMessageHandler } from "@/providers/Vessel00/messages/VesselMessageHandler.react"

export function LogoutHandler() {
  const { logout } = useLogout()

  const handler: MessageHandler<LogoutPayload> = useCallback(
    async (_, reply) => {
      await logout()
      reply({ success: true })
    },
    [logout],
  )

  return (
    <VesselMessageHandler action="Logout" handler={handler} type="action" />
  )
}
