import { useTranslate } from "@opensea/next-translate"
import { Flex, FlexColumn } from "@opensea/ui-kit"
import { Separator } from "@opensea/ui-kit/client"
import React from "react"
import { graphql, useFragment } from "react-relay"
import { PaymentAssetMedia } from "@/components/common/media/PaymentAssetMedia"
import { Text } from "@/design-system/Text"
import { useChains } from "@/hooks/useChains"
import type { ApproveCurrencyTransactionDetails_metadata$key } from "@/lib/graphql/__generated__/ApproveCurrencyTransactionDetails_metadata.graphql"
import { TransactionDetailsTokenAmount } from "../components/TransactionDetailsTokenAmount.react"
import type { TransactionDetailsProps } from "../TransactionDetails.react"

type Props = Omit<TransactionDetailsProps, "metadata"> & {
  metadata: ApproveCurrencyTransactionDetails_metadata$key
}

export function ApproveCurrencyTransactionDetails({
  metadata: metadataDataKey,
  gasFee,
  isLoadingGasFee,
  chain,
}: Props) {
  const t = useTranslate("transactions")
  const { getNativeCurrencyUsdPrice, getNativeCurrencySymbol } = useChains()

  const { sentAssetQuantities } = useFragment(
    graphql`
      fragment ApproveCurrencyTransactionDetails_metadata on TransactionMetadataType {
        sentAssetQuantities {
          asset {
            symbol
            ...PaymentAssetMedia_asset
          }
        }
      }
    `,
    metadataDataKey,
  )

  const paymentAsset = sentAssetQuantities[0].asset

  const nativeCurrencySymbol = getNativeCurrencySymbol(chain)
  const nativeCurrencyUsdPrice = getNativeCurrencyUsdPrice(chain)

  return (
    <FlexColumn className="gap-6 p-4">
      <div>
        <Flex className="items-center gap-4">
          <PaymentAssetMedia asset={paymentAsset} size="sm" />
          <Text weight="semibold">{paymentAsset.symbol}</Text>
        </Flex>
        <Separator className="mt-4" />
      </div>

      <TransactionDetailsTokenAmount
        isLoading={isLoadingGasFee}
        label={t("transactionConfirmation.gas", "Gas")}
        quantity={gasFee}
        symbol={nativeCurrencySymbol}
        usdPrice={nativeCurrencyUsdPrice}
      />
    </FlexColumn>
  )
}
