import { useTranslate } from "@opensea/next-translate"
import { Flex, FlexColumn, FlexCenter } from "@opensea/ui-kit"
import { Error } from "@opensea/ui-kit/icons"
import { useFlag } from "@unleash/nextjs"
import React from "react"
import { useAsyncFn } from "react-use"
import { Button } from "@/design-system/Button"
import { Text } from "@/design-system/Text"

export type ConfirmationModalContentProps = {
  children: React.ReactNode
  isConfirmDisabled?: boolean
  onConfirm: () => Promise<void> | void
  onReject: () => void
}

export function ConfirmationModalContent({
  children,
  isConfirmDisabled,
  onConfirm,
  onReject,
}: ConfirmationModalContentProps) {
  const t = useTranslate("transactions")
  const areWalletActionsDisabled = useFlag("embedded_wallet_actions_disabled")
  const [{ loading: confirmationInProgress }, confirm] = useAsyncFn(
    async () => {
      await onConfirm()
    },
  )

  return (
    <FlexColumn className="h-full p-4">
      <FlexColumn className="flex-1 overflow-hidden">{children}</FlexColumn>
      {areWalletActionsDisabled ? (
        <FlexCenter className="mt-4 justify-center gap-0.5" color="error">
          <Error size={18} />{" "}
          <Text size="small">
            {t(
              "confirmationModal.walletActionsDisabled",
              "Unable to confirm wallet actions at this time.",
            )}
          </Text>
        </FlexCenter>
      ) : null}
      <Flex className="gap-3 pt-4">
        <Button
          className="flex-1"
          disabled={confirmationInProgress}
          onClick={onReject}
          variant="destructive"
        >
          {t("reject", "Reject")}
        </Button>
        <Button
          autoFocus
          className="flex-1"
          disabled={isConfirmDisabled || areWalletActionsDisabled}
          isLoading={confirmationInProgress}
          onClick={() => confirm()}
        >
          {t("confirm", "Confirm")}
        </Button>
      </Flex>
    </FlexColumn>
  )
}
