
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import "@/styles/globals.css";
import "@/design-system/Tooltip/Tooltip.styles.css";
import type { AppProps, NextWebVitalsMetric } from "next/app";
import React from "react";
import type { AppServerSideProps } from "@/lib/getAppServerSideProps";
import type { UseRelayProps } from "@/lib/graphql/relay";
import { useRelay } from "@/lib/graphql/relay";
import { initDatadogRum, trackAction } from "@/lib/integrations/datadog";
import { AppProviders } from "@/providers/AppProviders.react";
void initDatadogRum();
type PageProps = UseRelayProps & Partial<AppServerSideProps>;
function MyApp({ Component, pageProps }: AppProps<PageProps>) {
    const { unleashContext, toggles, chains } = pageProps;
    const { env: relayEnvironment, ...relayProps } = useRelay(pageProps);
    return (<AppProviders chains={chains ?? []} relayEnvironment={relayEnvironment} toggles={toggles} unleashContext={unleashContext}>
      <Component {...pageProps} {...relayProps}/>
    </AppProviders>);
}
export async function reportWebVitals(metric: NextWebVitalsMetric) {
    if (metric.label === "custom") {
        await trackAction(metric.name, { value: metric.value });
    }
}

    export default __appWithI18n(MyApp, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  