import { APP_NAME, BUILD_ID, ENVIRONMENT } from "@/constants/env"

export async function initDatadogRum() {
  const clientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN
  const applicationId = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID
  if (typeof window === "undefined" || !clientToken || !applicationId) {
    return
  }

  const { datadogRum } = await import("@datadog/browser-rum")

  datadogRum.init({
    applicationId,
    clientToken,
    site: "datadoghq.com",
    service: APP_NAME,
    sessionSampleRate: 100,
    env: ENVIRONMENT,
    version: BUILD_ID,
    useSecureSessionCookie: false,
  })
}

export const trackAction = async (name: string, context?: object) => {
  const { datadogRum } = await import("@datadog/browser-rum")
  datadogRum.addAction(name, context)
}
