import { useTranslate } from "@opensea/next-translate"
import { FlexColumn } from "@opensea/ui-kit"
import { Separator } from "@opensea/ui-kit/client"
import React from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { useChains } from "@/hooks/useChains"
import type { CancelListingTransactionDetails_metadata$key } from "@/lib/graphql/__generated__/CancelListingTransactionDetails_metadata.graphql"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"
import { TransactionDetailsItems } from "../components/TransactionDetailsItems.react"
import { TransactionDetailsTokenAmount } from "../components/TransactionDetailsTokenAmount.react"
import type { TransactionDetailsProps } from "../TransactionDetails.react"

type Props = Omit<TransactionDetailsProps, "metadata"> & {
  metadata: CancelListingTransactionDetails_metadata$key
}

export function CancelListingTransactionDetails({
  metadata: metadataDataKey,
  gasFee,
  isLoadingGasFee,
  chain,
}: Props) {
  const t = useTranslate("transactions")
  const { getNativeCurrencyUsdPrice, getNativeCurrencySymbol } = useChains()

  const {
    sentAssetQuantities: nftAssetQuantities,
    receivedAssetQuantities: paymentAssetQuantities,
  } = useFragment(
    graphql`
      fragment CancelListingTransactionDetails_metadata on TransactionMetadataType {
        sentAssetQuantities {
          ...TransactionDetailsItems_assetQuantities
        }
        receivedAssetQuantities {
          asset {
            usdSpotPrice
            symbol
            decimals
          }
          quantity
        }
      }
    `,
    metadataDataKey,
  )

  const nativeCurrencySymbol = getNativeCurrencySymbol(chain)
  const nativeCurrencyUsdPrice = getNativeCurrencyUsdPrice(chain)

  // All payment asset quantities should have same symbol
  const paymentSymbol = paymentAssetQuantities[0].asset.symbol ?? "ETH"
  const paymentUsdSpotPrice = paymentAssetQuantities[0].asset.usdSpotPrice ?? 1
  const paymentDecimals = paymentAssetQuantities[0].asset.decimals
  const paymentQuantity = bn(
    BigNumber.sum(...paymentAssetQuantities.map(aq => aq.quantity)),
    paymentDecimals,
  )

  return (
    <FlexColumn className="gap-6 p-4">
      <div>
        <TransactionDetailsItems items={nftAssetQuantities} />
        <Separator className="mt-4" />
      </div>

      <TransactionDetailsTokenAmount
        label={t(
          "transactionConfirmation.listingPrice",
          { one: "Listing price", other: "Total listing price" },
          { count: nftAssetQuantities.length },
        )}
        quantity={paymentQuantity}
        symbol={paymentSymbol}
        usdPrice={paymentUsdSpotPrice}
      />

      <Separator />

      <TransactionDetailsTokenAmount
        isLoading={isLoadingGasFee}
        label={t("transactionConfirmation.gas", "Gas")}
        quantity={gasFee}
        symbol={nativeCurrencySymbol}
        usdPrice={nativeCurrencyUsdPrice}
      />
    </FlexColumn>
  )
}
