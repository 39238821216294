import signatures from "@/lib/graphql/signatures.json"

const typedSignatures: Record<string, string | undefined> = signatures

export const getSignedQuery = (query: string) => {
  const signedQuery = typedSignatures[query]
  if (!signedQuery) {
    throw new Error(`Signature missing from generated file ${query}`)
  }
  return signedQuery
}
