import { APP_NAME } from "@/constants/env"
import * as sidecar from "@/lib/sidecar/config"

const UNLEASH_APP_NAME = process.env.NEXT_PUBLIC_UNLEASH_APP_NAME ?? APP_NAME
const UNLEASH_CLIENT_KEY =
  process.env.NEXT_PUBLIC_UNLEASH_CLIENT_KEY ?? UNLEASH_APP_NAME
const UNLEASH_ENVIRONMENT = sidecar.getValue("unleashEnvironment")
export const UNLEASH_PROXY_URL = sidecar.getValue("unleashProxyUrl")

export const UNLEASH_PROXY_CONFIG = {
  url: UNLEASH_PROXY_URL,
  appName: UNLEASH_APP_NAME,
  environment: UNLEASH_ENVIRONMENT,
  clientKey: UNLEASH_CLIENT_KEY,
}
