/**
 * @generated SignedSource<<5c49a921bd56336897f92118045df0b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TransactionDetailsItems_assetQuantities$data = ReadonlyArray<{
  readonly asset: {
    readonly displayName: string;
    readonly " $fragmentSpreads": FragmentRefs<"StackedAssetMedia_assets">;
  };
  readonly quantity: string;
  readonly " $fragmentType": "TransactionDetailsItems_assetQuantities";
}>;
export type TransactionDetailsItems_assetQuantities$key = ReadonlyArray<{
  readonly " $data"?: TransactionDetailsItems_assetQuantities$data;
  readonly " $fragmentSpreads": FragmentRefs<"TransactionDetailsItems_assetQuantities">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "TransactionDetailsItems_assetQuantities",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetType",
      "kind": "LinkedField",
      "name": "asset",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "StackedAssetMedia_assets"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantity",
      "storageKey": null
    }
  ],
  "type": "AssetQuantityType",
  "abstractKey": null
};

(node as any).hash = "bc95d13d4785ac9023d33c2988d7022c";

export default node;
