import { useCallback } from "react"
import { graphql } from "react-relay"
import type { Hex } from "viem"
import { createPublicClient, hexToNumber, http } from "viem"
import type {
  useCreateRequestedTransactionMutation,
  useCreateRequestedTransactionMutation$variables,
} from "@/lib/graphql/__generated__/useCreateRequestedTransactionMutation.graphql"
import { useMutation } from "@/lib/graphql/useMutation"
import { useWaitForPrivyWallet } from "@/providers/Privy/useWaitForPrivyWallet"
import { SUPPORTED_CHAINS } from "@/providers/Privy/chains"
import { useChains } from "@/hooks/useChains"

export const useCreateRequestedTransaction = () => {
  const [commitFn] = useMutation<useCreateRequestedTransactionMutation>(graphql`
    mutation useCreateRequestedTransactionMutation(
      $calldata: String!
      $chain: ChainScalar!
      $fromAddress: AddressScalar!
      $toAddress: AddressScalar
      $nonce: Int!
      $value: BigIntScalar
      $destinationChain: ChainScalar
      $aggregator: String
      $transactionHash: String
      $expectedBridgeCompletionTime: Int
      $receivedAssetSymbol: String
      $receivedAssetQuantity: BigIntScalar
      $sentAssetSymbol: String
      $sentAssetQuantity: BigIntScalar
    ) {
      userTransaction {
        request(
          calldata: $calldata
          chain: $chain
          fromAddress: $fromAddress
          toAddress: $toAddress
          nonce: $nonce
          value: $value
          destinationChain: $destinationChain
          aggregator: $aggregator
          transactionHash: $transactionHash
          expectedBridgeCompletionTime: $expectedBridgeCompletionTime
          receivedAssetSymbol: $receivedAssetSymbol
          receivedAssetQuantity: $receivedAssetQuantity
          sentAssetSymbol: $sentAssetSymbol
          sentAssetQuantity: $sentAssetQuantity
        ) {
          relayId
        }
      }
    }
  `)
  const { getChain } = useChains()
  const waitForPrivyWallet = useWaitForPrivyWallet()

  const createRequestedTransaction = useCallback(
    async (
      variables: Omit<useCreateRequestedTransactionMutation$variables, "nonce">,
      isExternalWallet = false,
    ) => {
      let transactionCount = 0
      if (isExternalWallet) {
        try {
          const c = getChain(variables.chain)
          const viemChain = SUPPORTED_CHAINS.find(
            chain => chain.id === (Number(c.networkId) || 1),
          )
          const publicClient = createPublicClient({
            chain: viemChain,
            transport: http(),
          })
          transactionCount = await publicClient.getTransactionCount({
            address: variables.fromAddress as Hex,
          })
        } catch {
          // TODO: we only use nonce for cancellations atm, so we can ignore this for now
        }
      } else {
        const privywallet = await waitForPrivyWallet()
        const ethereumProvider = await privywallet.getEthereumProvider()
        transactionCount = hexToNumber(
          await ethereumProvider.request({
            method: "eth_getTransactionCount",
            params: [privywallet.address, "latest"],
          }),
        )
      }
      const result = await commitFn({
        variables: {
          ...variables,
          nonce: transactionCount,
        },
      })
      return result.userTransaction.request.relayId
    },
    [commitFn, getChain, waitForPrivyWallet],
  )

  return createRequestedTransaction
}
