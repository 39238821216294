import type { init } from "@sentry/nextjs"
import { ENVIRONMENT } from "./constants/env"

type Options = Parameters<typeof init>[0]

const MAX_VALUE_LENGTH = 1024

export const SENTRY_COMMON_CONFIG: Partial<Options> = {
  enabled: process.env.NODE_ENV === "production",
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: ENVIRONMENT,
  maxValueLength: MAX_VALUE_LENGTH,
  ignoreErrors: [
    "Blocked 'connect' from 'opensea.io'",
    "Failed to fetch",
    "NetworkError when attempting to fetch resource",
    "Load failed",
    "No internet connection detected",
    "Error: websocket error 1006:",
    "Error: WebSocket connection failed for host: wss://relay.walletconnect.org",
    "Error: Object captured as exception with keys: code, type",
    "Error: Attempting to use a disconnected port object",
    "[Vessel] Action timed out.",
    'TimeoutError: Failed to receive response for message c0h within 10000ms. Payload: "hand"',
    /TypeError: Cannot read properties of undefined .*/,
    /SecurityError: .*Blocked a frame.*/,
    /SecurityError: .*cross-origin frame.*/,
    /<anonymous>.*/,
    /UnhandledRejection: Non-Error promise rejection captured with value:.*/,
  ],
}
