import { useTranslate } from "@opensea/next-translate"
import { FlexColumn } from "@opensea/ui-kit"
import { Separator } from "@opensea/ui-kit/client"
import React from "react"
import { graphql, useFragment } from "react-relay"
import { useChains } from "@/hooks/useChains"
import type { NftTransferTransactionDetails_metadata$key } from "@/lib/graphql/__generated__/NftTransferTransactionDetails_metadata.graphql"
import { TransactionDetailsItems } from "../components/TransactionDetailsItems.react"
import { TransactionDetailsTokenAmount } from "../components/TransactionDetailsTokenAmount.react"
import type { TransactionDetailsProps } from "../TransactionDetails.react"

type Props = Omit<TransactionDetailsProps, "metadata"> & {
  metadata: NftTransferTransactionDetails_metadata$key
}

export function NftTransferTransactionDetails({
  metadata: metadataDataKey,
  gasFee,
  isLoadingGasFee,
  chain,
}: Props) {
  const t = useTranslate("transactions")
  const { getNativeCurrencyUsdPrice, getNativeCurrencySymbol } = useChains()

  const { sentAssetQuantities: nftAssetQuantities } = useFragment(
    graphql`
      fragment NftTransferTransactionDetails_metadata on TransactionMetadataType {
        sentAssetQuantities {
          ...TransactionDetailsItems_assetQuantities
        }
      }
    `,
    metadataDataKey,
  )

  const nativeCurrencySymbol = getNativeCurrencySymbol(chain)
  const nativeCurrencyUsdPrice = getNativeCurrencyUsdPrice(chain)

  return (
    <FlexColumn className="gap-6 p-4">
      <div>
        <TransactionDetailsItems items={nftAssetQuantities} />
        <Separator className="mt-4" />
      </div>

      <TransactionDetailsTokenAmount
        isLoading={isLoadingGasFee}
        label={t("transactionConfirmation.gas", "Gas")}
        quantity={gasFee}
        symbol={nativeCurrencySymbol}
        usdPrice={nativeCurrencyUsdPrice}
      />
    </FlexColumn>
  )
}
