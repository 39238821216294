import React from "react"

type Props = {
  className?: string
  width?: number
}

export function AvalancheLogo({ className, width = 20 }: Props) {
  return (
    <svg
      className={className}
      fill="none"
      height="20"
      style={{ width, height: width }}
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M6.0688 17H3.41651C2.85918 17 2.58388 17 2.41603 16.8926C2.23472 16.7751 2.12393 16.5804 2.1105 16.3654C2.10042 16.1674 2.23809 15.9256 2.51338 15.4422L9.06224 3.89893C9.3409 3.40875 9.4819 3.16366 9.65983 3.07302C9.8512 2.97566 10.0795 2.97566 10.2709 3.07302C10.4488 3.16366 10.5898 3.40875 10.8685 3.89893L12.2148 6.24907L12.2217 6.26106C12.5226 6.78692 12.6752 7.05359 12.7419 7.33348C12.8157 7.639 12.8157 7.9613 12.7419 8.26683C12.6747 8.54884 12.5237 8.81744 12.2181 9.35125L8.77819 15.4321L8.76929 15.4477C8.46633 15.9779 8.31279 16.2466 8.10001 16.4493C7.86835 16.671 7.58969 16.832 7.28416 16.9228C7.0055 17 6.69328 17 6.0688 17ZM12.7667 17H16.5672C17.1278 17 17.4099 17 17.5779 16.8894C17.7591 16.7718 17.8732 16.5736 17.8834 16.3589C17.8931 16.1673 17.7584 15.9349 17.4945 15.4795C17.4854 15.464 17.4763 15.4482 17.467 15.4322L15.5634 12.1756L15.5417 12.1389C15.2742 11.6865 15.1392 11.4581 14.9658 11.3698C14.7745 11.2724 14.5494 11.2724 14.3581 11.3698C14.1835 11.4605 14.0425 11.6988 13.7639 12.1789L11.867 15.4356L11.8605 15.4468C11.5828 15.9261 11.444 16.1656 11.454 16.3622C11.4674 16.5771 11.5782 16.7751 11.7595 16.8926C11.924 17 12.2061 17 12.7667 17Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
