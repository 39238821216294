import type { GetServerSidePropsContext, NextPageContext } from "next"
import { IS_TESTNET } from "@/constants/testnet"
import { Cookie } from "../cookie"
import type { OSNextData } from "./types"

const COOKIE_KEY = "ext-os-wallet"
const TESTNET_COOKIE_KEY = "ext-os-wallet-testnet"

// Stores list of previously seen accounts & currently active account
// Used in storybook
// eslint-disable-next-line import/no-unused-modules
export const walletDataCookie = new Cookie<OSNextData>(
  IS_TESTNET ? TESTNET_COOKIE_KEY : COOKIE_KEY,
)

export const getOSNextData = (
  context?: NextPageContext | GetServerSidePropsContext,
): OSNextData | undefined => {
  return walletDataCookie.get(context)
}
