import { useContextSelector } from "use-context-selector"
import { OSNextDataContext } from "./context"

export const useOSNextWalletData = () => ({
  activeAccount: useContextSelector(
    OSNextDataContext,
    ctx => ctx.activeAccount,
  ),
  activeChain: useContextSelector(OSNextDataContext, ctx => ctx.activeChain),
  installedWallets: useContextSelector(
    OSNextDataContext,
    ctx => ctx.installedWallets ?? [],
  ),
})

export const useOSNextDeviceId = () =>
  useContextSelector(OSNextDataContext, ctx => ctx.deviceId)
