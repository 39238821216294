import type { EquallySizedChainLogo } from "@/components/svgs/EquallySizedChainLogos/index"
import { EquallySizedChainLogos } from "@/components/svgs/EquallySizedChainLogos/index"
import { IS_TESTNET } from "@/constants/testnet"
import type { ChainIdentifier } from "@/hooks/useChains/types"

export const isEthereum = (
  chain: ChainIdentifier | undefined,
): chain is "ETHEREUM" | "SEPOLIA" => {
  return chain === "ETHEREUM" || chain === "SEPOLIA"
}

export const isPolygon = (
  chain: ChainIdentifier | undefined,
): chain is "MATIC" | "AMOY" => {
  return chain === "MATIC" || chain === "AMOY"
}

export const isSolana = (
  chain: ChainIdentifier | undefined,
): chain is "SOLANA" | "SOLDEV" => {
  return chain === "SOLANA" || chain === "SOLDEV"
}

export const getEthereumChain = (): "SEPOLIA" | "ETHEREUM" => {
  return IS_TESTNET ? "SEPOLIA" : "ETHEREUM"
}

export const getSolanaChain = (): "SOLANA" | "SOLDEV" => {
  return IS_TESTNET ? "SOLDEV" : "SOLANA"
}

export const getChainOrDefault = (
  direction: string,
  chain: ChainIdentifier,
) => {
  return (direction === "from"
    ? CHAINS_SUPPORTED_IN_SWAPS
    : CHAINS_SUPPORTED_IN_SWAPS_DESTINATION
  ).includes(chain)
    ? chain
    : getEthereumChain()
}

export const isCanonicalChainIdentifier = (chain?: ChainIdentifier) => {
  return isEthereum(chain) || isSolana(chain)
}

export const CHAINS_SUPPORTED_IN_SWAPS: ChainIdentifier[] = [
  "ARBITRUM",
  "AVALANCHE",
  "BASE",
  "ETHEREUM",
  "OPTIMISM",
  "MATIC",
]

export const CHAINS_SUPPORTED_IN_SWAPS_DESTINATION: ChainIdentifier[] = [
  "ZORA",
  ...CHAINS_SUPPORTED_IN_SWAPS,
]

const CHAIN_IDENTIFIER_LOGO_MAPPING: Record<
  ChainIdentifier,
  EquallySizedChainLogo | undefined
> = {
  ETHEREUM: EquallySizedChainLogos.Ethereum,
  KLAYTN: EquallySizedChainLogos.Klaytn,
  MATIC: EquallySizedChainLogos.Polygon,
  AMOY: EquallySizedChainLogos.Polygon,
  SEPOLIA: EquallySizedChainLogos.Ethereum,
  SOLANA: EquallySizedChainLogos.Solana,
  SOLDEV: EquallySizedChainLogos.Solana,
  ARBITRUM: EquallySizedChainLogos.Arbitrum,
  ARBITRUM_NOVA: EquallySizedChainLogos.Arbitrum,
  ARBITRUM_SEPOLIA: EquallySizedChainLogos.Arbitrum,
  OPTIMISM: EquallySizedChainLogos.Optimism,
  OPTIMISM_SEPOLIA: EquallySizedChainLogos.Optimism,
  AVALANCHE: EquallySizedChainLogos.Avalanche,
  AVALANCHE_FUJI: EquallySizedChainLogos.Avalanche,
  BAOBAB: EquallySizedChainLogos.Klaytn,
  BSC: EquallySizedChainLogos.Binance,
  BSC_TESTNET: EquallySizedChainLogos.Binance,
  BASE: EquallySizedChainLogos.Base,
  BASE_SEPOLIA: EquallySizedChainLogos.Base,
  ZORA: EquallySizedChainLogos.Zora,
  ZORA_SEPOLIA: EquallySizedChainLogos.Zora,
  ZORA_TESTNET: EquallySizedChainLogos.Zora,
  BLAST_SEPOLIA: EquallySizedChainLogos.Blast,
  BLAST: EquallySizedChainLogos.Blast,
  GUNZILLA_TESTNET: EquallySizedChainLogos.Gunzilla,
  SEI: EquallySizedChainLogos.Sei,
  SEI_TESTNET: EquallySizedChainLogos.Sei,
  // eslint-disable-next-line relay/no-future-added-value
  "%future added value": undefined,
}

// TODO: @mcabrams - this should be implemented in ChainLogo.tsx once design team
// decides how to consolidate across site.
export const getEquallySizedChainLogo = (chain: ChainIdentifier) => {
  return CHAIN_IDENTIFIER_LOGO_MAPPING[chain]
}
