/**
 * @generated SignedSource<<8cc042bdb756c8241979404195d8e6cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useAddConnectedDomainMutation$variables = {
  domain: string;
  privyAccessToken: string;
  privyAppId: string;
};
export type useAddConnectedDomainMutation$data = {
  readonly embeddedWallets: {
    readonly addConnectedDomain: boolean | null | undefined;
  };
};
export type useAddConnectedDomainMutation = {
  response: useAddConnectedDomainMutation$data;
  variables: useAddConnectedDomainMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "domain"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "privyAccessToken"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "privyAppId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EmbeddedWalletsMutationType",
    "kind": "LinkedField",
    "name": "embeddedWallets",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "domain",
            "variableName": "domain"
          },
          {
            "kind": "Variable",
            "name": "privyAccessToken",
            "variableName": "privyAccessToken"
          },
          {
            "kind": "Variable",
            "name": "privyAppId",
            "variableName": "privyAppId"
          }
        ],
        "kind": "ScalarField",
        "name": "addConnectedDomain",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useAddConnectedDomainMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useAddConnectedDomainMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "91b059372a60114c5459da083377511c",
    "id": null,
    "metadata": {},
    "name": "useAddConnectedDomainMutation",
    "operationKind": "mutation",
    "text": "mutation useAddConnectedDomainMutation(\n  $domain: String!\n  $privyAccessToken: String!\n  $privyAppId: String!\n) {\n  embeddedWallets {\n    addConnectedDomain(domain: $domain, privyAccessToken: $privyAccessToken, privyAppId: $privyAppId)\n  }\n}\n"
  }
};
})();

(node as any).hash = "d1bd3e34bf158b9d9fe125f4b5329dc8";

export default node;
