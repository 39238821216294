import type { SendOptions, VesselMessageListener } from "@opensea/vessel"
import { Vessel } from "@opensea/vessel"
import { createContext, useContext, useMemo } from "react"
import { asyncNoop } from "@/lib/helpers/noop"
import { ENVIRONMENT } from "@/constants/env"
import { PARENT_FRAME_TARGET_ORIGIN } from "../Vessel00/constants"

type VesselContextType = {
  addMessageListener?: (listener: VesselMessageListener<unknown>) => () => void
  sendMessage?: (message: unknown, options?: SendOptions) => Promise<unknown>
}

const VesselContext = createContext<VesselContextType>({
  addMessageListener: undefined,
  sendMessage: asyncNoop,
})

type VesselProviderProps = {
  children: React.ReactNode
}

export function VesselProvider({ children }: VesselProviderProps) {
  const value: VesselContextType = useMemo(() => {
    if (typeof window === "undefined") {
      return {}
    }
    const vessel = new Vessel({
      targetOrigin: PARENT_FRAME_TARGET_ORIGIN,
      debug: ENVIRONMENT !== "production",
    })
    return {
      addMessageListener: vessel.addListener.bind(vessel, "message"),
      sendMessage: vessel.send.bind(vessel),
    }
  }, [])

  return (
    <VesselContext.Provider value={value}>{children}</VesselContext.Provider>
  )
}

export function useVessel() {
  return useContext(VesselContext)
}
