import { useTranslate } from "@opensea/next-translate"
import { Flex, FlexColumn } from "@opensea/ui-kit"
import { Separator } from "@opensea/ui-kit/client"
import React from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { CollectionMedia } from "@/components/common/media/CollectionMedia"
import { Text } from "@/design-system/Text"
import { useChains } from "@/hooks/useChains"
import type { CancelOfferTransactionDetails_metadata$key } from "@/lib/graphql/__generated__/CancelOfferTransactionDetails_metadata.graphql"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"
import { TransactionDetailsItems } from "../components/TransactionDetailsItems.react"
import { TransactionDetailsTokenAmount } from "../components/TransactionDetailsTokenAmount.react"
import type { TransactionDetailsProps } from "../TransactionDetails.react"

type Props = Omit<TransactionDetailsProps, "metadata"> & {
  metadata: CancelOfferTransactionDetails_metadata$key
}

export function CancelOfferTransactionDetails({
  metadata: metadataDataKey,
  gasFee,
  isLoadingGasFee,
  chain,
}: Props) {
  const t = useTranslate("transactions")
  const { getNativeCurrencyUsdPrice, getNativeCurrencySymbol } = useChains()

  const {
    sentAssetQuantities: paymentAssetQuantities,
    receivedAssetQuantities: nftAssetQuantities,
    collections,
  } = useFragment(
    graphql`
      fragment CancelOfferTransactionDetails_metadata on TransactionMetadataType {
        receivedAssetQuantities {
          ...TransactionDetailsItems_assetQuantities
        }
        sentAssetQuantities {
          asset {
            usdSpotPrice
            symbol
            decimals
          }
          quantity
        }
        collections {
          name
          ...CollectionMedia_collection
        }
      }
    `,
    metadataDataKey,
  )

  const nativeCurrencySymbol = getNativeCurrencySymbol(chain)
  const nativeCurrencyUsdPrice = getNativeCurrencyUsdPrice(chain)

  // All payment asset quantities should have same symbol
  const paymentSymbol = paymentAssetQuantities[0].asset.symbol ?? "ETH"
  const paymentUsdSpotPrice = paymentAssetQuantities[0].asset.usdSpotPrice ?? 1
  const paymentDecimals = paymentAssetQuantities[0].asset.decimals
  const paymentQuantity = bn(
    BigNumber.sum(...paymentAssetQuantities.map(aq => aq.quantity)),
    paymentDecimals,
  )

  const collection = collections[0]

  return (
    <FlexColumn className="gap-6 p-4">
      {nftAssetQuantities.length ? (
        <div>
          <TransactionDetailsItems items={nftAssetQuantities} />
          <Separator className="mt-4" />
        </div>
      ) : (
        <div>
          <Flex className="items-center gap-4">
            <CollectionMedia collection={collection} size="sm" />
            <Text weight="semibold">{collection.name}</Text>
          </Flex>
          <Separator className="mt-4" />
        </div>
      )}

      <TransactionDetailsTokenAmount
        label={t(
          "transactionConfirmation.offerValue",
          { one: "Offer value", other: "Total offer value" },
          { count: nftAssetQuantities.length },
        )}
        quantity={paymentQuantity}
        symbol={paymentSymbol}
        usdPrice={paymentUsdSpotPrice}
      />

      <Separator />

      <TransactionDetailsTokenAmount
        isLoading={isLoadingGasFee}
        label={t("transactionConfirmation.gas", "Gas")}
        quantity={gasFee}
        symbol={nativeCurrencySymbol}
        usdPrice={nativeCurrencyUsdPrice}
      />
    </FlexColumn>
  )
}
