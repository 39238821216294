import { useTranslate } from "@opensea/next-translate"
import { Flex, FlexColumn } from "@opensea/ui-kit"
import { Tooltip } from "@opensea/ui-kit/client"
import { ArrowRight, ArrowLeft } from "@opensea/ui-kit/icons"
import React from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { StackedAssetMedia } from "@/components/common/media/StackedAssetMedia"
import { Text } from "@/design-system/Text"
import type { DealSideItemsDisplay_assetQuantities$key } from "@/lib/graphql/__generated__/DealSideItemsDisplay_assetQuantities.graphql"
import { BigNumber, bn, display } from "@/lib/helpers/numberUtils"

type DealSideItemsDisplayProps = {
  dataKey: DealSideItemsDisplay_assetQuantities$key
  direction: "send" | "receive"
}

export function DealSideItemsDisplay({
  dataKey,
  direction,
}: DealSideItemsDisplayProps) {
  const t = useTranslate("transactions")

  const assetQuantities = useFragment(
    graphql`
      fragment DealSideItemsDisplay_assetQuantities on AssetQuantityType
      @relay(plural: true) {
        asset {
          displayName
          tokenStandard
          symbol
          decimals
          ...StackedAssetMedia_assets
        }
        quantity
      }
    `,
    dataKey,
  )

  const nftAssetQuantities = assetQuantities.filter(
    aq => aq.asset.tokenStandard !== "ERC20",
  )

  const paymentAssetQuantities = assetQuantities.filter(
    aq => aq.asset.tokenStandard === "ERC20",
  )
  const paymentAssetSymbol = paymentAssetQuantities[0]?.asset.symbol ?? "WETH"
  const paymentAssetDecimals = paymentAssetQuantities[0]?.asset.decimals ?? 18
  const paymentAssetQuantity = bn(
    BigNumber.sum(...paymentAssetQuantities.map(aq => aq.quantity), 0),
    paymentAssetDecimals,
  )

  const isSingleItem = nftAssetQuantities.length === 1
  const totalItemQuantity = BigNumber.sum(
    ...nftAssetQuantities.map(aq => aq.quantity),
  )

  const itemsLabel = isSingleItem
    ? nftAssetQuantities[0].asset.displayName
    : t("transactionConfirmation.itemCountLabel", "{{itemCount}} items", {
        itemCount: display(totalItemQuantity),
      })

  return (
    <Flex className="items-center gap-4">
      <StackedAssetMedia
        assets={nftAssetQuantities.map(aq => aq.asset)}
        size="sm"
      />
      <FlexColumn className="flex-1">
        <Text weight="semibold">{itemsLabel}</Text>
        {paymentAssetQuantity.isGreaterThan(0) && (
          <Text size="tiny">
            {t(
              "transactionConfirmation.acceptDeal.paymentAssetQuantity",
              "+ {{quantity}} {{symbol}}",
              {
                quantity: display(paymentAssetQuantity),
                symbol: paymentAssetSymbol,
              },
            )}
          </Text>
        )}
      </FlexColumn>
      {direction === "send" ? (
        <Tooltip
          content={t(
            "transactionConfirmation.acceptDeal.sending",
            "These items will be sent if the deal is accepted",
          )}
        >
          <span>
            <ArrowRight />
          </span>
        </Tooltip>
      ) : (
        <Tooltip
          content={t(
            "transactionConfirmation.acceptDeal.receiving",
            "These items will be received if the deal is accepted",
          )}
        >
          <span>
            <ArrowLeft />
          </span>
        </Tooltip>
      )}
    </Flex>
  )
}
