/**
 * @generated SignedSource<<e2cf0946349dd695c5f1875163f356c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NftTransferTransactionDetails_metadata$data = {
  readonly sentAssetQuantities: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"TransactionDetailsItems_assetQuantities">;
  }>;
  readonly " $fragmentType": "NftTransferTransactionDetails_metadata";
};
export type NftTransferTransactionDetails_metadata$key = {
  readonly " $data"?: NftTransferTransactionDetails_metadata$data;
  readonly " $fragmentSpreads": FragmentRefs<"NftTransferTransactionDetails_metadata">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NftTransferTransactionDetails_metadata",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetQuantityType",
      "kind": "LinkedField",
      "name": "sentAssetQuantities",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TransactionDetailsItems_assetQuantities"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TransactionMetadataType",
  "abstractKey": null
};

(node as any).hash = "70473ae8d2056e21e727e57e5d7e20fb";

export default node;
