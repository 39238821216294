import { useTranslate } from "@opensea/next-translate"
import { FlexColumn } from "@opensea/ui-kit"
import React from "react"
import { hexToString } from "viem"
import { Text } from "@/design-system/Text"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTrackingFn } from "@/lib/analytics/useTrackingFn"
import { useGlobalModal } from "@/providers/GlobalModal"
import { useWaitForPrivyWallet } from "@/providers/Privy/useWaitForPrivyWallet"
import { ConfirmationModalContent } from "../ConfirmationModal"
import { ConfirmationModalTitle } from "../ConfirmationModal/ConfirmationModalTitle.react"
import { USER_REJECTED_REQUEST_ERROR } from "../constants"
import type { PersonalSignParams } from "../types"
import { isMfaCancelledError } from "../utils"

type PersonalSignConfirmationModalProps = {
  params: PersonalSignParams
  onSuccess: (result: unknown) => void
  onError: (error: unknown) => void
}

export function PersonalSignConfirmationModal({
  params,
  onSuccess,
  onError,
}: PersonalSignConfirmationModalProps) {
  const t = useTranslate("transactions")
  const { closeModal } = useGlobalModal()
  const waitForPrivyWallet = useWaitForPrivyWallet()

  const {
    trackOpenPersonalSignConfirmationModal,
    trackConfirmPersonalSign,
    trackRejectPersonalSign,
    trackPersonalSignSuccessful,
    trackPersonalSignFailed,
  } = useTracking()

  useMountEffect(() => {
    trackOpenPersonalSignConfirmationModal()
  })

  const onConfirm = async () => {
    trackConfirmPersonalSign()
    // Sign using Privy ethereum provider
    const privyWallet = await waitForPrivyWallet()
    const ethereumProvider = await privyWallet.getEthereumProvider()
    try {
      const result = await ethereumProvider.request({
        method: "personal_sign",
        params,
      })
      trackPersonalSignSuccessful()
      onSuccess(result)
    } catch (error) {
      if (isMfaCancelledError(error)) {
        return // Do not handle MFA cancellation as an error
      }
      trackPersonalSignFailed()
      onError(error)
    }
    closeModal()
  }

  const onReject = () => {
    trackRejectPersonalSign()
    onError(USER_REJECTED_REQUEST_ERROR)
    closeModal()
  }

  return (
    <ConfirmationModalContent onConfirm={onConfirm} onReject={onReject}>
      <ConfirmationModalTitle>
        {t("signatureConfirmation.title", "Signature request")}
      </ConfirmationModalTitle>

      <FlexColumn className="gap-6 overflow-scroll rounded-xl bg-component-gray-1 p-4">
        <Text weight="semibold">
          {t("signatureConfirmation.message.heading", "Message")}
        </Text>
        <Text className="whitespace-pre-wrap break-words" color="secondary">
          {hexToString(params[0])}
        </Text>
      </FlexColumn>
    </ConfirmationModalContent>
  )
}

const useTracking = () => {
  return {
    trackOpenPersonalSignConfirmationModal: useTrackingFn(
      "open personal sign confirmation modal",
    ),
    trackRejectPersonalSign: useTrackingFn("reject personal sign"),
    trackConfirmPersonalSign: useTrackingFn("confirm personal sign"),
    trackPersonalSignSuccessful: useTrackingFn("personal sign successful"),
    trackPersonalSignFailed: useTrackingFn("personal sign failed"),
  }
}
