import type {
  InitMfaEnrollmentWithSmsPayload,
  SubmitMfaEnrollmentWithSmsPayload,
} from "@opensea/wallet-messages"
import { useMfaEnrollment } from "@privy-io/react-auth"
import React, { useCallback } from "react"
import { getMessageFromError } from "@/lib/helpers/error"
import type { MessageHandler } from "@/providers/Vessel00/messages"
import { VesselMessageHandler } from "@/providers/Vessel00/messages"
import { useMfaEnrollmentTracking } from "./useMfaEnrollmentTracking"
import { getSubmitMfaErrorReason } from "./utils"

export function SmsMfaEnrollmentHandler() {
  const { initEnrollmentWithSms, submitEnrollmentWithSms } = useMfaEnrollment()

  const {
    trackInitMfaEnrollment,
    trackInitMfaEnrollmentError,
    trackSubmitMfaEnrollment,
    trackSubmitMfaEnrollmentError,
  } = useMfaEnrollmentTracking()

  const initEnrollmentWithSmsHandler: MessageHandler<InitMfaEnrollmentWithSmsPayload> =
    useCallback(
      async ({ phoneNumber }, reply) => {
        trackInitMfaEnrollment({ method: "sms" })
        try {
          await initEnrollmentWithSms({ phoneNumber })
          reply({ success: true })
        } catch (error) {
          trackInitMfaEnrollmentError({
            method: "sms",
            error: getMessageFromError(error),
          })
          reply({ error: true })
        }
      },
      [
        initEnrollmentWithSms,
        trackInitMfaEnrollment,
        trackInitMfaEnrollmentError,
      ],
    )

  const submitEnrollmentWithSmsHandler: MessageHandler<SubmitMfaEnrollmentWithSmsPayload> =
    useCallback(
      async ({ phoneNumber, mfaCode }, reply) => {
        trackSubmitMfaEnrollment({ method: "sms" })
        try {
          await submitEnrollmentWithSms({ phoneNumber, mfaCode })
          reply({ success: true })
        } catch (error) {
          const reason = getSubmitMfaErrorReason(error)
          trackSubmitMfaEnrollmentError({
            method: "sms",
            error: getMessageFromError(error),
            reason,
          })
          reply({
            error: {
              reason,
            },
          })
        }
      },
      [
        submitEnrollmentWithSms,
        trackSubmitMfaEnrollment,
        trackSubmitMfaEnrollmentError,
      ],
    )

  return (
    <>
      <VesselMessageHandler
        action="InitMfaEnrollmentWithSms"
        handler={initEnrollmentWithSmsHandler}
        type="action"
      />
      <VesselMessageHandler
        action="SubmitMfaEnrollmentWithSms"
        handler={submitEnrollmentWithSmsHandler}
        type="action"
      />
    </>
  )
}
