import { classNames } from "@opensea/ui-kit"
import type { Options, Modifier } from "@popperjs/core"
import { ErrorBoundary } from "@sentry/nextjs"
import type { TippyProps } from "@tippyjs/react"
import Tippy from "@tippyjs/react"
import { cva } from "class-variance-authority"
import type { ClassValue } from "clsx"
import { isBoolean, isFunction, merge, noop } from "lodash"
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { followCursor } from "tippy.js"
import type { Instance, Placement } from "tippy.js"
import "tippy.js/dist/tippy.css"
import type { Values } from "../../lib/helpers/type"

export const TOOLTIP_PLACEMENT: Record<string, Placement> = {
  AUTO: "auto",
  AUTO_START: "auto-start",
  AUTO_END: "auto-end",
  BOTTOM: "bottom",
  BOTTOM_START: "bottom-start",
  BOTTOM_END: "bottom-end",
  TOP: "top",
  TOP_START: "top-start",
  TOP_END: "top-end",
  LEFT: "left",
  LEFT_START: "left-start",
  LEFT_END: "left-end",
  RIGHT: "right",
  RIGHT_START: "right-start",
  RIGHT_END: "right-end",
} as const

export const TOOLTIP_VARIANTS = ["default", "card", "translucent"] as const

type Variant = (typeof TOOLTIP_VARIANTS)[number]

const getVariantClassName = cva<{
  variant: {
    [key in Variant]: string
  }
}>("", {
  variants: {
    variant: {
      default: "tooltip-default",
      card: "tooltip-card",
      translucent: "tooltip-translucent",
    },
  },
})

export type TooltipInstance = Instance

export type TooltipPlacement = Values<typeof TOOLTIP_PLACEMENT>

type TippyContent = TippyProps["content"]

export type TooltipContent = TippyContent | (() => TippyContent)

export type TooltipOffset = TippyProps["offset"]

export type TooltipProps = Omit<
  TippyProps,
  "theme" | "content" | "getReferenceClientRect" | "animation"
> & {
  contentPadding?: ClassValue
  animation?: "fade-vertical" | "fade" | "shift-away"
  variant?: Variant
  content: TooltipContent
  lazy?: boolean
  noContentPadding?: boolean
  // The actual getReferenceClientRect's type is too restrictive and not needed.
  getReferenceClientRect?: () => Partial<DOMRect>
  hideOnScroll?: boolean
  matchReferenceWidth?: boolean
}

const SAME_WIDTH_MODIFIER: Modifier<"sameWidth", Options> = {
  name: "sameWidth",
  enabled: true,
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`
  },
  phase: "beforeWrite",
  requires: ["computeStyles"],
}

const DEFAULT_TOOLTIP_POPPER_OPTIONS: Partial<Options> = {
  modifiers: [
    {
      name: "preventOverflow",
      options: {
        altAxis: true,
        tether: false,
      },
    },
  ],
}

export const Tooltip = forwardRef<Element, TooltipProps>(function Tooltip(
  {
    content,
    disabled,
    hideOnClick = false,
    offset = [0, 10],
    variant = "default",
    noContentPadding = false,
    visible,
    popperOptions,
    animation,
    lazy = true,
    hideOnScroll = false,
    onTrigger,
    onHidden,
    onShow,
    onShown,
    getReferenceClientRect,
    matchReferenceWidth: matchContentWidth,
    ...rest
  },
  ref,
) {
  const [tippyInstance, setTippyInstance] = useState<Instance>()
  const [isShown, setIsShown] = useState(visible)
  const isDisabled = disabled ?? !content

  const renderedContent = useMemo(() => {
    if (!lazy || visible || isShown) {
      const renderedContent = isFunction(content) ? content() : content

      return renderedContent
    }
    return undefined
  }, [content, isShown, lazy, visible])

  const close = useCallback(() => tippyInstance?.hide(), [tippyInstance])

  useEffect(() => {
    if (hideOnScroll && isShown) {
      window.addEventListener("scroll", close)
    }
    return () => {
      if (hideOnScroll && isShown) {
        window.removeEventListener("scroll", close)
      }
    }
  }, [hideOnScroll, close, isShown])
  const plugins = useMemo(() => {
    if (rest.followCursor) {
      return [followCursor]
    }
    return []
  }, [rest.followCursor])

  return (
    <ErrorBoundary>
      <Tippy
        {...rest}
        animation={animation}
        className={classNames(
          "tooltip",
          getVariantClassName({ variant }),
          noContentPadding
            ? "tooltip-no-content-padding"
            : "tooltip-content-padding",
          rest.className,
        )}
        content={renderedContent}
        disabled={isDisabled}
        getReferenceClientRect={getReferenceClientRect as () => DOMRect}
        hideOnClick={!isBoolean(visible) ? hideOnClick : undefined}
        offset={offset}
        onHidden={instance => {
          onHidden?.(instance)
          setIsShown(false)
        }}
        onShow={instance => {
          onShow?.(instance)
          setTippyInstance(instance)
        }}
        onShown={onShown ?? noop}
        onTrigger={(instance, evt) => {
          onTrigger?.(instance, evt)
          setIsShown(true)
        }}
        plugins={plugins}
        popperOptions={merge(
          {},
          DEFAULT_TOOLTIP_POPPER_OPTIONS, // apply default options
          matchContentWidth ? { modifiers: [SAME_WIDTH_MODIFIER] } : {}, // apply same with modifier
          popperOptions, // apply options from props
        )}
        ref={ref}
        visible={isBoolean(visible) ? visible : undefined}
      />
    </ErrorBoundary>
  )
})
