import { STATIC_ROOT } from "."

export enum WALLET_NAME {
  Native = "Native",
  MetaMask = "MetaMask",
  Bitski = "Bitski",
  Phantom = "Phantom",
  Solflare = "Solflare",
  Fortmatic = "Fortmatic",
  OperaTouch = "OperaTouch",
  Trust = "Trust",
  WalletConnect = "WalletConnect",
  WalletConnectV2 = "WalletConnectV2",
  LedgerConnect = "Ledger",
  CoinbaseWallet = "Coinbase Wallet",
  Kaikas = "Kaikas",
  Glow = "Glow",
  BitKeep = "BitKeep",
  Core = "Core",
  OpenSeaWallet = "OpenSea",
}

const METAMASK_LOGO = "/static/images/logos/metamask-fox.svg"
const LEDGERCONNECT_LOGO = "/static/images/logos/ledgerconnect-alternative.png"
const PHANTOM_LOGO = `/static/images/logos/phantom.svg`
const GLOW_LOGO = `/static/images/logos/glow.svg`
const BITSKI_LOGO = `${STATIC_ROOT}/logos/bitski-alternative.png`
const SOLFLARE_LOGO = "/static/images/logos/solflare.png"
const BITKEEP_LOGO = `/static/images/logos/bitkeep.jpg`
const CORE_LOGO = `/static/images/logos/core.jpg`
const WALLETCONNECT_LOGO = `${STATIC_ROOT}/logos/walletconnect-alternative.png`
const OPERA_TOUCH_LOGO = "/static/images/logos/opera-touch-alternative.svg"

const TRUST_WALLET_LOGO = `${STATIC_ROOT}/logos/trust-alternative.png`
const COINBASE_LOGO = `${STATIC_ROOT}/logos/walletlink-alternative.png`
const FORTMATIC_LOGO = `${STATIC_ROOT}/logos/fortmatic-alternative.png`

const WALLET_CONFIGURATIONS: Record<WALLET_NAME, string> = {
  [WALLET_NAME.Native]: "",
  [WALLET_NAME.MetaMask]: METAMASK_LOGO,
  [WALLET_NAME.CoinbaseWallet]: COINBASE_LOGO,
  [WALLET_NAME.WalletConnect]: WALLETCONNECT_LOGO,
  [WALLET_NAME.WalletConnectV2]: WALLETCONNECT_LOGO,
  [WALLET_NAME.LedgerConnect]: LEDGERCONNECT_LOGO,
  [WALLET_NAME.Phantom]: PHANTOM_LOGO,
  [WALLET_NAME.BitKeep]: BITKEEP_LOGO,
  [WALLET_NAME.Core]: CORE_LOGO,
  [WALLET_NAME.Glow]: GLOW_LOGO,
  [WALLET_NAME.Fortmatic]: FORTMATIC_LOGO,
  [WALLET_NAME.Kaikas]: "/static/images/logos/kaikas.png",
  [WALLET_NAME.Bitski]: BITSKI_LOGO,
  [WALLET_NAME.Solflare]: SOLFLARE_LOGO,
  [WALLET_NAME.OperaTouch]: OPERA_TOUCH_LOGO,
  [WALLET_NAME.Trust]: TRUST_WALLET_LOGO,
  [WALLET_NAME.OpenSeaWallet]: "/static/images/logos/opensea-logo.svg",
}

export const getWalletLogo = (walletName: WALLET_NAME): string => {
  return WALLET_CONFIGURATIONS[walletName]
}
