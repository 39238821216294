import { useCallback } from "react"
import type { GraphQLTaggedNode, UseMutationConfig } from "react-relay"
import { useMutation as useRelayMutation } from "react-relay"
import type { MutationParameters } from "relay-runtime"

export const useMutation = <TMutation extends MutationParameters>(
  mutation: GraphQLTaggedNode,
) => {
  const [commitFn, isInFlight] = useRelayMutation<TMutation>(mutation)

  type Response = TMutation["response"]

  const commit = useCallback(
    (config: UseMutationConfig<TMutation>) => {
      return new Promise<Response>((resolve, reject) => {
        commitFn({
          onError: reject,
          onCompleted: (response, errors) => {
            // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
            errors?.length ? reject(errors) : resolve(response)
          },
          ...config,
        })
      })
    },
    [commitFn],
  )

  return [commit, isInFlight] as const
}
