import type { MessagePayloadType } from "@opensea/vessel-0.0"
import React, { useCallback } from "react"
import { captureException } from "@sentry/nextjs"
import { useGlobalModal } from "@/providers/GlobalModal"
import { useVesselActions } from "@/providers/Vessel00/useVesselActions"
import { SignTypedDataConfirmationModal } from "../../SignTypedDataConfirmationModal/SignTypedDataConfirmationModal.react"
import type { SignTypedDataParams } from "../../types"
import type { RPCMessageHandlerFn } from "../RPCMessageHandler.react"

export const useSignTypedDataHandler = (): RPCMessageHandlerFn => {
  const { showWallet, hideWallet, setClickAway } = useVesselActions()
  const { openModal } = useGlobalModal()

  const handler: RPCMessageHandlerFn = useCallback(
    (message, reply) => {
      openModal(
        <SignTypedDataConfirmationModal
          onError={error => {
            reply({ error } as MessagePayloadType)
            captureException(error)
            void hideWallet()
            void setClickAway(true)
          }}
          onSuccess={result => {
            reply(result as MessagePayloadType)
            void hideWallet()
            void setClickAway(true)
          }}
          params={message.payload.params as SignTypedDataParams}
        />,
        {
          closeable: false,
        },
      )
      void showWallet()
      void setClickAway(false)
    },
    [hideWallet, openModal, setClickAway, showWallet],
  )
  return handler
}
