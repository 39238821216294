import React from "react"
import { graphql, useFragment } from "react-relay"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import type { TransactionDetails_metadata$key } from "@/lib/graphql/__generated__/TransactionDetails_metadata.graphql"
import type { BigNumber } from "@/lib/helpers/numberUtils"
import { AcceptDealTransactionDetails } from "./variants/AcceptDealTransactionDetails.react"
import { AcceptOfferTransactionDetails } from "./variants/AcceptOfferTransactionDetails.react"
import { ApproveCollectionTransactionDetails } from "./variants/ApproveCollectionTransactionDetails.react"
import { ApproveCurrencyTransactionDetails } from "./variants/ApproveCurrencyTransactionDetails.react"
import { CancelDealTransactionDetails } from "./variants/CancelDealTransactionDetails.react"
import { CancelListingTransactionDetails } from "./variants/CancelListingTransactionDetails.react"
import { CancelOfferTransactionDetails } from "./variants/CancelOfferTransactionDetails.react"
import { CancelTransactionDetails } from "./variants/CancelTransactionDetails.react"
import { ConfigureDropTransactionDetails } from "./variants/ConfigureDropTransactionDetails.react"
import { CreateDropTransactionDetails } from "./variants/CreateDropTransactionDetails.react"
import { MintTransactionDetails } from "./variants/MintTransactionDetails.react"
import { NftTransferTransactionDetails } from "./variants/NftTransferTransactionDetails.react"
import { PurchaseTransactionDetails } from "./variants/PurchaseTransactionDetails.react"
import { RedeemTransactionDetails } from "./variants/RedeemTransactionDetails.react"
import { UnknownTransactionDetails } from "./variants/UnknownTransactionDetails.react"
import { UnwrapTransactionDetails } from "./variants/UnwrapTransactionDetails.react"
import { WrapTransactionDetails } from "./variants/WrapTransactionDetails.react"

export type TransactionDetailsProps = {
  metadata: TransactionDetails_metadata$key
  chain: ChainIdentifier
  gasFee: BigNumber
  isLoadingGasFee: boolean
}

export function TransactionDetails({
  metadata: metadataDataKey,
  ...restProps
}: TransactionDetailsProps) {
  const metadata = useFragment(
    graphql`
      fragment TransactionDetails_metadata on TransactionMetadataType {
        type
        ...PurchaseTransactionDetails_metadata
        ...CancelListingTransactionDetails_metadata
        ...CancelOfferTransactionDetails_metadata
        ...CancelDealTransactionDetails_metadata
        ...AcceptOfferTransactionDetails_metadata
        ...ApproveCollectionTransactionDetails_metadata
        ...ApproveCurrencyTransactionDetails_metadata
        ...NftTransferTransactionDetails_metadata
        ...MintTransactionDetails_metadata
        ...AcceptDealTransactionDetails_metadata
        ...WrapTransactionDetails_metadata
        ...UnwrapTransactionDetails_metadata
        ...RedeemTransactionDetails_metadata
        ...ConfigureDropTransactionDetails_metadata
      }
    `,
    metadataDataKey,
  )

  switch (metadata.type) {
    // Purchase
    case "BUY_NOW":
    case "BULK_PURCHASE":
      return <PurchaseTransactionDetails metadata={metadata} {...restProps} />

    // Cancel
    case "CANCEL_ASK":
      return (
        <CancelListingTransactionDetails metadata={metadata} {...restProps} />
      )
    case "CANCEL_BID":
    case "CANCEL_COLLECTION_OFFFER":
      return (
        <CancelOfferTransactionDetails metadata={metadata} {...restProps} />
      )
    case "CANCEL_SWAP":
      return <CancelDealTransactionDetails metadata={metadata} {...restProps} />
    case "CANCEL_ORDERS":
    case "CANCEL_ALL_ORDERS":
      return <CancelTransactionDetails {...restProps} />

    // Approval
    case "APPROVE_COLLECTION":
      return (
        <ApproveCollectionTransactionDetails
          metadata={metadata}
          {...restProps}
        />
      )
    case "ERC20_APPROVE":
      return (
        <ApproveCurrencyTransactionDetails metadata={metadata} {...restProps} />
      )

    // NFT Transfer
    case "NFT_SENT":
    case "BULK_SENT":
      return (
        <NftTransferTransactionDetails metadata={metadata} {...restProps} />
      )

    // Mint
    case "MINT":
    case "MINT_ALLOWLIST":
      return <MintTransactionDetails metadata={metadata} {...restProps} />

    // Accept offer
    case "BULK_ACCEPT_OFFER":
    case "ACCEPT_OFFER":
      return (
        <AcceptOfferTransactionDetails metadata={metadata} {...restProps} />
      )

    // Accept deal
    case "ACCEPT_SWAP":
      return <AcceptDealTransactionDetails metadata={metadata} {...restProps} />

    // Wrap/Unwrap
    case "WRAP":
      return <WrapTransactionDetails metadata={metadata} {...restProps} />
    case "UNWRAP":
      return <UnwrapTransactionDetails metadata={metadata} {...restProps} />

    // Creator Studio
    case "CREATE_SEADROP_CONTRACT":
      return <CreateDropTransactionDetails {...restProps} />
    case "CONFIGURE_DROP":
      return (
        <ConfigureDropTransactionDetails metadata={metadata} {...restProps} />
      )

    case "REDEEM":
      return <RedeemTransactionDetails metadata={metadata} {...restProps} />

    default:
      return <UnknownTransactionDetails type={metadata.type} {...restProps} />
  }
}
