import { useTheme } from "@opensea/next-themes"
import type { PrivyClientConfig } from "@privy-io/react-auth"
import { PrivyProvider as OGPrivyProvider } from "@privy-io/react-auth"
import { sepolia, mainnet } from "@wagmi/chains"
import { merge } from "lodash"
import React, { useMemo } from "react"
import { PRIVY_APP_ID, PRIVY_CLIENT_ID } from "@/constants/privy"
import { IS_TESTNET } from "@/constants/testnet"
import { SUPPORTED_CHAINS } from "./chains"
import { useIsSocialLoginEnabled } from "./flags"
import { useRouter } from "next/router"

type PrivyProviderProps = {
  children: React.ReactNode
  disabled?: boolean
}

const PRIVY_CONFIG = {
  // @ts-expect-error headless is an internal prop (https://docs.privy.io/guide/guides/headless-react-auth)
  headless: true,
  loginMethods: ["email"],
  supportedChains: SUPPORTED_CHAINS,
  defaultChain: IS_TESTNET ? sepolia : mainnet,
  embeddedWallets: {
    noPromptOnSignature: true,
    waitForTransactionConfirmation: false,
  },
  mfa: {
    noPromptOnMfaRequired: true,
  },
  appearance: {
    showWalletLoginFirst: false,
  },
} satisfies PrivyClientConfig

export function PrivyProvider({ children }: PrivyProviderProps) {
  const { resolvedTheme } = useTheme()
  const isSocialLoginEnabled = useIsSocialLoginEnabled()
  const { query } = useRouter()
  const clientId =
    typeof query.clientId === "string" ? query.clientId : PRIVY_CLIENT_ID

  const config: PrivyClientConfig = useMemo(
    () =>
      merge(PRIVY_CONFIG, {
        loginMethods: isSocialLoginEnabled
          ? [...PRIVY_CONFIG.loginMethods, "google"]
          : PRIVY_CONFIG.loginMethods,
        appearance: {
          theme: resolvedTheme,
        },
        embeddedWallets: {
          ...PRIVY_CONFIG.embeddedWallets,
        },
      }),
    [isSocialLoginEnabled, resolvedTheme],
  )

  if (process.env.NODE_ENV === "test") {
    return <>{children}</>
  }

  return (
    <OGPrivyProvider appId={PRIVY_APP_ID} clientId={clientId} config={config}>
      {children}
    </OGPrivyProvider>
  )
}
