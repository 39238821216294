/**
 * Typed version of Object.keys().
 */
export const keys = <T extends object>(obj: T): (keyof T)[] =>
  Object.keys(obj) as (keyof T)[]

// Note: Typescript object keys can only be string | number, or their aliases.
// The below is equivalent to writing `string | number` instead of `keyof T`.
export const entries = <T extends Record<string, unknown>>(
  obj: T,
): [keyof T, T[keyof T]][] => keys(obj).map(k => [k, obj[k]])
