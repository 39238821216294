/**
 * @generated SignedSource<<da88e4d112c3c74928a66d56b009e102>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type State = "CANCELING" | "CANCELLED" | "PENDING" | "REJECTED" | "REQUESTED" | "SUCCESSFUL" | "%future added value";
export type UserTransactionState = "CANCELING" | "CANCELLED" | "PENDING" | "REJECTED" | "REQUESTED" | "SUCCESSFUL" | "%future added value";
export type useUpdateTransactionStatusPendingMutation$variables = {
  state: State;
  transactionHash?: string | null | undefined;
  userTransactionId: any;
};
export type useUpdateTransactionStatusPendingMutation$data = {
  readonly userTransaction: {
    readonly updateStatus: {
      readonly relayId: string;
      readonly state: UserTransactionState | null | undefined;
    };
  };
};
export type useUpdateTransactionStatusPendingMutation = {
  response: useUpdateTransactionStatusPendingMutation$data;
  variables: useUpdateTransactionStatusPendingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "state"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "transactionHash"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userTransactionId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "state",
    "variableName": "state"
  },
  {
    "kind": "Variable",
    "name": "transactionHash",
    "variableName": "transactionHash"
  },
  {
    "kind": "Variable",
    "name": "userTransactionId",
    "variableName": "userTransactionId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateTransactionStatusPendingMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserTransactionMutationType",
        "kind": "LinkedField",
        "name": "userTransaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "UserTransactionType",
            "kind": "LinkedField",
            "name": "updateStatus",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "useUpdateTransactionStatusPendingMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserTransactionMutationType",
        "kind": "LinkedField",
        "name": "userTransaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "UserTransactionType",
            "kind": "LinkedField",
            "name": "updateStatus",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "23702c9a6b010b28e06ed411d1196460",
    "id": null,
    "metadata": {},
    "name": "useUpdateTransactionStatusPendingMutation",
    "operationKind": "mutation",
    "text": "mutation useUpdateTransactionStatusPendingMutation(\n  $userTransactionId: UserTransactionRelayID!\n  $state: State!\n  $transactionHash: String\n) {\n  userTransaction {\n    updateStatus(state: $state, userTransactionId: $userTransactionId, transactionHash: $transactionHash) {\n      relayId\n      state\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c7969d971faa9369a26f6420f1a9afb2";

export default node;
