import { classNames } from "@opensea/ui-kit"
import React from "react"
import type { TypedDataDefinition, TypedDataParameter } from "viem"
import { Text } from "@/design-system/Text"
import { Tooltip } from "@/design-system/Tooltip"
import { truncateAddress } from "@/lib/helpers/address/format"

export function TypedDataDisplay({ data }: { data: TypedDataDefinition }) {
  return (
    <FieldDisplay
      boldName
      name={data.primaryType}
      value={data.types[data.primaryType].map(field => (
        <TypedDataFieldDisplay
          field={field}
          indent
          key={field.name}
          message={data.message}
          types={data.types}
        />
      ))}
    />
  )
}

const ARRAY_FIELD_NAME = "array"
const ARRAY_FIELD_REGEX = /\[\d*\]$/
const isArrayType = (type: string) => ARRAY_FIELD_REGEX.test(type)

type TypedDataFieldDisplayProps = {
  message: Record<string, unknown>
  field: TypedDataParameter
  types: Record<string, readonly TypedDataParameter[]>
  indent?: boolean
}

function TypedDataFieldDisplay({
  message,
  field,
  types,
  indent,
}: TypedDataFieldDisplayProps) {
  if (field.type === "address") {
    const address = message[field.name] as string
    return (
      <FieldDisplay
        indent={indent}
        inline
        name={field.name}
        value={
          <Tooltip content={address} maxWidth="250px" variant="card">
            <span>{truncateAddress(address, { after: 6 })}</span>
          </Tooltip>
        }
      />
    )
  }
  if (
    field.type.startsWith("uint") ||
    field.type.startsWith("int") ||
    field.type === "bytes32"
  ) {
    const value = message[field.name] as string
    return (
      <FieldDisplay indent={indent} inline name={field.name} value={value} />
    )
  }
  if (isArrayType(field.type)) {
    const type = field.type.replace(ARRAY_FIELD_REGEX, "")
    const value = (
      field.name === ARRAY_FIELD_NAME ? message : message[field.name]
    ) as Record<string, unknown>[]
    const content = value.map((subMessage: Record<string, unknown>, index) => {
      if (isArrayType(type)) {
        // Handle nested arrays
        return (
          <FieldDisplay
            boldName
            indent
            key={index}
            name={index.toString()}
            value={
              <TypedDataFieldDisplay
                field={{ name: ARRAY_FIELD_NAME, type }}
                indent
                key={field.name}
                message={subMessage}
                types={types}
              />
            }
          />
        )
      }

      return (
        <FieldDisplay
          boldName
          indent
          key={index}
          name={index.toString()}
          value={types[type].map(subField => (
            <TypedDataFieldDisplay
              field={subField}
              indent
              key={subField.name}
              message={subMessage}
              types={types}
            />
          ))}
        />
      )
    })

    if (field.name === ARRAY_FIELD_NAME) {
      // No field name display for nested arrays
      return <>{content}</>
    }

    return (
      <FieldDisplay
        boldName
        indent={indent}
        name={field.name}
        value={content}
      />
    )
  }
}

type FieldDisplayProps = {
  name: string
  value: React.ReactNode
  inline?: boolean
  boldName?: boolean
  indent?: boolean
}

function FieldDisplay({
  name,
  value,
  inline,
  boldName,
  indent,
}: FieldDisplayProps) {
  return (
    <div
      className={classNames("my-1 gap-2", {
        flex: inline,
        "pl-2": indent,
      })}
      key={name}
    >
      <Text size="small" weight={boldName ? "semibold" : "regular"}>
        {name}:
      </Text>
      <Text className="min-w-0 break-words" size="small">
        {value}
      </Text>
    </div>
  )
}
