/* eslint-disable @typescript-eslint/method-signature-style */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
export type Values<T extends Record<string, unknown>> = T[keyof T]

export class UnreachableCaseError extends Error {
  public constructor(val: never) {
    super(`Unreachable case: ${String(val)}`)
  }
}

declare global {
  interface Function {
    /**
     * For a given function, creates a bound function that has the same body as the original function.
     * The this object of the bound function is associated with the specified object, and has the specified initial parameters.
     * @param thisArg - The object to be used as the this object.
     */
    bind<T>(this: T, thisArg: ThisParameterType<T>): OmitThisParameter<T>
  }
}

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}
