import type { IToggle, IMutableContext } from "@unleash/nextjs/client"
import { FlagProvider as UnleashFlagProvider } from "@unleash/nextjs/client"
import React from "react"
import { UNLEASH_PROXY_CONFIG } from "@/lib/feature-flags/constants"

type Props = {
  children: React.ReactNode
  bootstrap?: IToggle[]
  context?: IMutableContext
}

export function FlagProvider({ children, bootstrap, context }: Props) {
  return (
    <UnleashFlagProvider
      config={{
        ...UNLEASH_PROXY_CONFIG,
        bootstrap,
        context,
      }}
      startClient={false}
    >
      {children}
    </UnleashFlagProvider>
  )
}
