import { classNames } from "@opensea/ui-kit"
import type { SheetProps } from "@opensea/ui-kit/client"
import { Sheet } from "@opensea/ui-kit/client"
import React from "react"
import { useContextSelector } from "use-context-selector"
import { PREVENT_CLOSE_MODAL_PROPS } from "./constants"
import { GlobalModalContext } from "./GlobalModalContextProvider.react"
import { useGlobalModal } from "./useGlobalModal"

const useGlobalModalState = () => ({
  content: useContextSelector(GlobalModalContext, ctx => ctx.content),
  props: useContextSelector(GlobalModalContext, ctx => ctx.props),
})

export type GlobalModalProps = Partial<
  Omit<SheetProps, "ref" | "content" | "open" | "onOpenChange">
> & {
  closeable?: boolean
}

/**
 * Mount this component lower down in the tree instead of as child of
 * `GlobalModalContextProvider` so that `content` has access to all AppProvider
 * contexts
 */
export function GlobalModal() {
  const { closeModal } = useGlobalModal()
  const {
    content,
    props: { closeable = true, className, ...sheetProps } = {},
  } = useGlobalModalState()

  const isOpen = Boolean(content)

  const onOpenChange = (open: boolean) => {
    if (!open) {
      closeModal()
    }
  }

  return (
    <Sheet
      className={classNames("flex h-full flex-col gap-0", className)}
      side="bottom"
      style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
      withCloseIcon={false}
      {...sheetProps}
      {...(!closeable ? PREVENT_CLOSE_MODAL_PROPS : undefined)}
      content={content}
      onOpenChange={onOpenChange}
      open={isOpen}
    />
  )
}
