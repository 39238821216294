import type {
  InitMfaEnrollmentWithTotpResponse,
  InitMfaEnrollmentWithTotpPayload,
  SubmitMfaEnrollmentWithTotpPayload,
} from "@opensea/wallet-messages"
import { useMfaEnrollment } from "@privy-io/react-auth"
import React, { useCallback } from "react"
import { getMessageFromError } from "@/lib/helpers/error"
import type { MessageHandler } from "@/providers/Vessel00/messages"
import { VesselMessageHandler } from "@/providers/Vessel00/messages"
import { useMfaEnrollmentTracking } from "./useMfaEnrollmentTracking"
import { getSubmitMfaErrorReason } from "./utils"

export function TotpMfaEnrollmentHandler() {
  const { initEnrollmentWithTotp, submitEnrollmentWithTotp } =
    useMfaEnrollment()

  const {
    trackInitMfaEnrollment,
    trackInitMfaEnrollmentError,
    trackSubmitMfaEnrollment,
    trackSubmitMfaEnrollmentError,
  } = useMfaEnrollmentTracking()

  const initEnrollmentWithTotpHandler: MessageHandler<
    InitMfaEnrollmentWithTotpPayload,
    InitMfaEnrollmentWithTotpResponse
  > = useCallback(
    async (_, reply) => {
      trackInitMfaEnrollment({ method: "totp" })
      try {
        const { secret, authUrl } = await initEnrollmentWithTotp()
        reply({ secret, authUrl })
      } catch (error) {
        trackInitMfaEnrollmentError({
          method: "totp",
          error: getMessageFromError(error),
        })
        reply({ error: true })
      }
    },
    [
      initEnrollmentWithTotp,
      trackInitMfaEnrollment,
      trackInitMfaEnrollmentError,
    ],
  )

  const submitEnrollmentWithTotpHandler: MessageHandler<SubmitMfaEnrollmentWithTotpPayload> =
    useCallback(
      async ({ mfaCode }, reply) => {
        trackSubmitMfaEnrollment({ method: "totp" })
        try {
          await submitEnrollmentWithTotp({ mfaCode })
          reply({ success: true })
        } catch (error) {
          const reason = getSubmitMfaErrorReason(error)
          trackSubmitMfaEnrollmentError({
            method: "totp",
            error: getMessageFromError(error),
            reason,
          })
          reply({
            error: {
              reason,
            },
          })
        }
      },
      [
        submitEnrollmentWithTotp,
        trackSubmitMfaEnrollment,
        trackSubmitMfaEnrollmentError,
      ],
    )

  return (
    <>
      <VesselMessageHandler
        action="InitMfaEnrollmentWithTotp"
        handler={initEnrollmentWithTotpHandler}
        type="action"
      />
      <VesselMessageHandler
        action="SubmitMfaEnrollmentWithTotp"
        handler={submitEnrollmentWithTotpHandler}
        type="action"
      />
    </>
  )
}
