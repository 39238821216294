import { useTranslate } from "@opensea/next-translate"

export type MfaError =
  | "timeout"
  | "verificationFailed"
  | "maxAttemptsReached"
  | "alreadyLinked"
  | "unknown"

export const useMfaErrorText = (errorType?: MfaError) => {
  const t = useTranslate("common")
  const MFA_ERROR_TEXT: Record<MfaError, string> = {
    timeout: t(
      "mfaRequest.error.timeout",
      "Your code has expired. Please request a new code.",
    ),
    verificationFailed: t(
      "mfaRequest.error.verificationFailed",
      "Incorrect code. Please try again.",
    ),
    maxAttemptsReached: t(
      "mfaRequest.error.maxAttemptsReached",
      "Max attempts reached. Please wait before trying again.",
    ),
    alreadyLinked: t(
      "mfaRequest.error.alreadyLinked",
      "This phone number is already linked to an account. Please use a different phone number.",
    ),
    unknown: t(
      "mfaRequest.error.unknown",
      "An unknown error occurred. Please exit this screen and try again.",
    ),
  }

  return errorType ? MFA_ERROR_TEXT[errorType] : undefined
}
