import React from "react"
import { CheckMfaEnrollmentHandler } from "./CheckMfaEnrollmentHandler.react"
import { SmsMfaEnrollmentHandler } from "./SmsMfaEnrollmentHandler.react"
import { TotpMfaEnrollmentHandler } from "./TotpMfaEnrollmentHandler.react"

export function MfaEnrollmentHandlers() {
  return (
    <>
      <SmsMfaEnrollmentHandler />
      <TotpMfaEnrollmentHandler />
      <CheckMfaEnrollmentHandler />
    </>
  )
}
